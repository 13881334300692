import React from "react";
import { Formik, Form, Field } from "formik";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import ButtonRoot from "../../Buttons/ButtonRoot";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { SIGN_IN, PROFILE } from "../../../constants/routes";
import { ForgotPasswordSchema } from "./validationForgotPassword";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "550px",
    color: "#333333",
    height: "fit-content",
  },
  headerAction: {
    display: "contents",
  },
  label: {
    color: "#333333",
    fontWeight: 700,
    fontSize: "14px",
    display: "block",
    marginBottom: "8px",
  },
  textField: {
    width: "100%",
    height: "38.62px",
  },
  formFieldWrap: {
    width: "100%",
  },
  formField: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0 8px",
    minHeight: "81px",
  },
  cardActons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 20px 20px",
  },
  back: {
    marginTop: "7px",
  },
  link: {
    color: "#594BF6",
    "&:hover": {
      color: "#594BF6",
      textDecoration: "underline",
    },
  },
  helperText: {
    margin: 0,
  },
}));

const ForgotPasswordForm = ({ sendInstruction, destinationPath }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={sendInstruction}
    >
      {({ setFieldValue, values: { email }, touched, errors }) => (
        <Card variant="outlined" className={classes.card}>
          <CardHeader
            classes={{ action: classes.headerAction }}
            title="Forgot password"
            subheader="If you`ve forgotten password enter your email address below and we will send password reset instructions"
          />
          <Form className="login-form">
            <CardContent>
              <Box className={classes.formFieldWrap}>
                <Box className={classes.formField}>
                  <InputLabel htmlFor="email" className={classes.label}>
                    E-mail Address
                  </InputLabel>
                  <Field
                    name="email"
                    id="email"
                    type="email"
                    variant="outlined"
                    size="small"
                    classes={{ root: classes.textField }}
                    value={email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    error={errors.email && touched.email ? true : false}
                    component={TextField}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    FormHelperTextProps={{
                      classes: { root: classes.helperText },
                    }}
                  />
                </Box>
              </Box>
            </CardContent>
            <CardActions classes={{ root: classes.cardActons }}>
              <ButtonRoot type="submit" text={"Send me instruction"} />
              <Box className={classes.back}>
                Back to{" "}
                {destinationPath ? (
                  <Link to={PROFILE} className={classes.link}>
                    Profile
                  </Link>
                ) : (
                  <Link to={SIGN_IN} className={classes.link}>
                    Sign in
                  </Link>
                )}
              </Box>
            </CardActions>
          </Form>
        </Card>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
