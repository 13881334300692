import gql from "graphql-tag";

export const UserFragment = gql`
  fragment User on UserType {
    id
    dateJoined
    lastName
    isSuperuser
    isAdmin
    email
    firstName
    lastName
    avatar
    workingTime
    isActive
    startTime
    status
    online
    phone
    birthday
    role
    additionalInformation
    programmingLangsAndSpecializations {
      id
      name
    }
    experience
    jobTitle {
      id
      name
    }
    team {
      id
      name
    }
    englishLevel {
      id
      name
    }
    equipments {
      id
      modelName
      type
    }
    company {
      id
      name
      companyservicesSet {
        name
        id
      }
      siteUrl
      dateCreated
      owner {
        id
      }
      industry {
        id
        name
      }
      size
      avatar
      locationName
      departments {
        id
        name
        locationName
        avatar
        address
      }
      location {
        address1
        address2
        city
        country
        id
        region
        subregion
      }
      paymentInfo {
        accountNumber
        bankInfo
        beneficiaryName
        bankName
        id
        swiftNumber
        isDefault
      }
      email
      legalNumber
      phone
    }
    files {
      id
      file
    }
    department {
      id
      name
    }
  }
`; // OK

export const DailyReportFragment = gql`
  fragment DailyReport on DailyReportType {
    id
    completed
    timeWorked
    date
    userStatistics
    startTime
    user {
      ...User
    }
  }
  ${UserFragment}
`;

export const TeamFragment = gql`
  fragment Team on TeamType {
    id
    name
    teamLead {
      id
      firstName
      lastName
    }
    projects {
      id
      name
    }
    technologies {
      id
      name
    }
    users {
      ...User
    }
  }
  ${UserFragment}
`; // OK

export const JobTitleFragment = gql`
  fragment JobTitle on JobTitleType {
    id
    name
    users {
      ...User
    }
  }
  ${UserFragment}
`; // OK

export const ProjectFragment = gql`
  fragment Project on ProjectType {
    id
    name
    avatar
    status
    team {
      ...Team
    }
    company {
      id
      name
    }
    dailyReports {
      id
      timeWorked
      date
    }
    clients {
      id
      firstName
      lastName
      phone
      skype
      email
      country
      notes
    }
  }
`; // OK

export const MonthlyCalendarDataFragment = gql`
  fragment MonthlyCalendarData on MonthlyCalendarDataType {
    id
    date
    workingDays
    weekends
  }
`;

export const IndustryFragment = gql`
  fragment IndustryFragment on IndustryType {
    id
    name
  }
`; // OK

export const CompanyInfoFragment = gql`
  fragment userCompanyInfo on CompanyType {
    id
    size
    name
    siteUrl
    industry {
      ...IndustryFragment
    }
  }
  ${IndustryFragment}
`; // OK

export const LabelFragment = gql`
  fragment Label on LabelType {
    id
    name
    color
  }
`; // OK

export const LeadFragment = gql`
  fragment Lead on LeadType {
    id
    organization
    value
    currency
    sourceType {
      id
      value
    }
    label {
      ...Label
    }
    owner {
      ...User
    }
    item {
      createdAt
      id
      list {
        id
        title
      }
      order
    }
    keyNeed
    archived
    contactPersons {
      firstName
      lastName
      id
      phone
      email
      facebook
      linkedin
      skype
    }
    website
    projectType {
      id
      value
    }
    primaryContact {
      id
      lastName
      firstName
      facebook
      email
      phone
      skype
      linkedin
    }
    activity
    industryType {
      id
      value
    }
  }
  ${UserFragment}
  ${LabelFragment}
`; // OK

export const ItemLeadTypeFragment = gql`
  fragment ItemLead on ItemLeadType {
    id
    lead {
      ...Lead
    }
    order
    createdAt
  }
  ${LeadFragment}
`; // OK
