import React, { useState } from 'react';
import FooterWithoutInstall from '../../components/Footer/FooterWithoutInstall';
import HeaderWithoutInstall from '../../components/NavBar/HeaderWithoutInstall';
import { withRouter } from 'react-router';
import { flowRight as compose } from 'lodash';
import { register } from '../../api/mutations';
import { graphql, Query } from 'react-apollo';
import { Formik, Form, Field } from 'formik';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioRoot from '../../components/Buttons/RadioRoot';
import ButtonRoot from '../../components/Buttons/ButtonRoot';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import PasswordField from '../../components/Fields/PasswordFiels';
import Checkbox from '@material-ui/core/Checkbox';
import * as path from '../../constants/routes';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { RegisterValidation } from './validation';
import SelectLight from '../../components/Select/SelectLight';
import { allIndustries } from '../../api/queries/index';
import { getNames } from 'country-list';
import SelectAutocomplete from '../../components/Select/SelectAutocomplete';

const valiantSubscription = [
    { id: '1', name: 'Starter', price: '25' },
    { id: '2', name: 'Growth', price: '50' },
    { id: '3', name: 'Scale', price: '100' },
];

const companySizeList = [
    { value: '1-10', text: '1-10' },
    { value: '11-20', text: '11-20' },
    { value: '21-50', text: '21-50' },
    { value: '51-100', text: '51-100' },
    { value: '100+', text: '100+' },
];

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        height: '100%',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
    },
    card: {
        width: '730px',
        color: '#333333',
        margin: '20px 0',
    },
    headerAction: {
        display: 'contents',
    },
    features: {
        border: '1px solid #E0E0E0',
        borderRadius: '10px',
        color: '#4F4F4F',
        padding: '7px 10px',
        width: '115px',
    },
    price: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#333333',
        '& > span': {
            color: '#bbb9b9',
        },
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    item: {
        flex: '1 1 100px',
    },
    label: {
        color: '#333333',
        fontWeight: 500,
        fontSize: '16px',
        display: 'block',
        marginRight: '30px',
        minWidth: '120px',
        textAlign: 'end',
    },
    textField: {
        width: '330px',
    },
    textFieldShort: {
        width: '165px',
        '&:first-child': {
            paddingRight: '10px',
        },
        '&:last-child': {
            paddingLeft: '10px',
        },
    },
    formFieldWrap: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        marginBottom: '7px',
        padding: '0 70px',
        height: '64px',
    },
    formField: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    fieldError: {
        width: '330px',
        fontSize: '11px',
    },
    acceptServise: {
        minWidth: '325px',
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#594BF6',
        },
    },
    cardActons: {
        justifyContent: 'center',
        padding: '0 20px 20px',
    },
}));

const SignUp = props => {
    const [acceptServise, setAcceptServise] = useState(false);

    const handleAcceptServise = e => {
        setAcceptServise(e.target.checked);
    };

    const createNewAccount = (values, setErrors) => {
        const {
            company_name,
            firstName,
            lastName,
            email,
            password1,
            password2,
            companySite,
            industry,
            companySize,
        } = values;
        const dateJoined = moment().utc();
        props
            .register({
                variables: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    password1: password1,
                    password2: password2,
                    isAdmin: true,
                    dateJoined: dateJoined,
                    companyName: company_name,
                    companySize: companySize,
                    industry: industry,
                    companySite: companySite,
                },
            })
            .then(response => {
                console.log('response', response);
                if (response.data.register.success) {
                    props.history.push(path.SIGN_IN);
                } else {
                    let errors = {};
                    response.data.register.error.validationErrors.map(error => {
                        errors[error.field] = error['messages'];
                        return null;
                    });
                    setErrors.setErrors(errors);
                }
            });
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <HeaderWithoutInstall register />
            <div className={classes.content}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        period: 'month',
                        subscriptionValiant: 'Starter',
                        email: '',
                        teamName: '',
                        firstName: '',
                        lastName: '',
                        password1: '',
                        password2: '',
                        company_name: '',
                        companySite: '',
                        industry: '',
                        companySize: 1,
                        cardholderName: '',
                        card: '',
                        address: '',
                        address2: '',
                        city: '',
                        country: '',
                        state: '',
                        postalCode: '',
                    }}
                    validationSchema={RegisterValidation}
                    onSubmit={createNewAccount}
                >
                    {({
                        setFieldValue,
                        values: {
                            period,
                            subscriptionValiant,
                            email,
                            company_name,
                            firstName,
                            lastName,
                            password1,
                            password2,
                            cardholderName,
                            card,
                            address,
                            address2,
                            city,
                            country,
                            state,
                            postalCode,
                            teamName,
                            companySite,
                            industry,
                            companySize,
                        },
                        touched,
                        errors,
                    }) => (
                        <Form>
                            <Card variant='outlined' className={classes.card}>
                                <CardHeader
                                    classes={{ action: classes.headerAction }}
                                    action={
                                        <ButtonGroup disableElevation color='primary'>
                                            <Button
                                                variant={
                                                    period === 'month'
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                                onClick={() => {
                                                    setFieldValue('period', 'month');
                                                }}
                                            >
                                                Monthly
                                            </Button>
                                            <Button
                                                variant={
                                                    period !== 'month'
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                                onClick={() => {
                                                    setFieldValue('period', 'year');
                                                }}
                                            >
                                                Yearly
                                            </Button>
                                        </ButtonGroup>
                                    }
                                    title='Subscription'
                                    subheader='All subscription plan prices are excluding applicable VAT.'
                                />
                                <List>
                                    <RadioGroup
                                        aria-label='subscriptionValiant'
                                        name='subscriptionValiant'
                                        value={subscriptionValiant}
                                        onChange={e => {
                                            setFieldValue(
                                                'subscriptionValiant',
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {valiantSubscription.map(item => {
                                            return (
                                                <div key={item.id}>
                                                    <Divider />
                                                    <ListItem
                                                        className={classes.listItem}
                                                    >
                                                        <Box className={classes.item}>
                                                            <FormControlLabel
                                                                value={item.name}
                                                                control={<RadioRoot />}
                                                                label={item.name}
                                                            />
                                                        </Box>
                                                        <Box className={classes.item}>
                                                            <Box
                                                                className={
                                                                    classes.features
                                                                }
                                                            >
                                                                <StarOutlineIcon />
                                                                Features
                                                            </Box>
                                                        </Box>
                                                        <Box className={classes.item}>
                                                            {' '}
                                                            <Typography
                                                                className={classes.price}
                                                            >
                                                                {' '}
                                                                ${item.price} /{' '}
                                                                <span>Monthly</span>
                                                            </Typography>
                                                        </Box>
                                                    </ListItem>
                                                </div>
                                            );
                                        })}
                                    </RadioGroup>
                                </List>
                            </Card>
                            <Card variant='outlined' className={classes.card}>
                                <CardHeader title='Profile' />
                                <CardContent>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='teamName'
                                                className={classes.label}
                                            >
                                                Team name
                                            </InputLabel>
                                            <Field
                                                name='teamName'
                                                id='teamName'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={teamName}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'teamName',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.teamName && touched.teamName
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.teamName && touched.teamName ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.teamName}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='firstName'
                                                className={classes.label}
                                            >
                                                First name
                                            </InputLabel>
                                            <Field
                                                name='firstName'
                                                id='firstName'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={firstName}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'firstName',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.firstName && touched.firstName
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.firstName && touched.firstName ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.firstName}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='lastName'
                                                className={classes.label}
                                            >
                                                Last name
                                            </InputLabel>
                                            <Field
                                                name='lastName'
                                                id='lastName'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={lastName}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'lastName',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.lastName && touched.lastName
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.lastName && touched.lastName ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.lastName}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='email'
                                                className={classes.label}
                                            >
                                                E-mail Address
                                            </InputLabel>
                                            <Field
                                                name='email'
                                                id='email'
                                                type='email'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={email}
                                                onChange={e =>
                                                    setFieldValue('email', e.target.value)
                                                }
                                                error={
                                                    errors.email && touched.email
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.email && touched.email ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.email}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='password1'
                                                className={classes.label}
                                            >
                                                Password
                                            </InputLabel>
                                            <Field
                                                name='password1'
                                                id='password1'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={password1}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'password1',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.password1 && touched.password1
                                                        ? true
                                                        : false
                                                }
                                                component={PasswordField}
                                                autoComplete={'off'}
                                            />
                                        </Box>
                                        {errors.password1 && touched.password1 ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.password1}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='password2'
                                                className={classes.label}
                                            >
                                                Confirm password
                                            </InputLabel>
                                            <Field
                                                name='password2'
                                                id='password2'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={password2}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'password2',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.password2 && touched.password2
                                                        ? true
                                                        : false
                                                }
                                                component={PasswordField}
                                                autoComplete={'off'}
                                            />
                                        </Box>
                                        {errors.password2 && touched.password2 ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.password2}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card variant='outlined' className={classes.card}>
                                <CardHeader title='Company info' />
                                <CardContent>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='company_name'
                                                className={classes.label}
                                            >
                                                Company name
                                            </InputLabel>
                                            <Field
                                                name='company_name'
                                                id='company_name'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={company_name}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'company_name',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.company_name &&
                                                    touched.company_name
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.company_name && touched.company_name ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.company_name}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='companySite'
                                                className={classes.label}
                                            >
                                                Company web-site
                                            </InputLabel>
                                            <Field
                                                name='companySite'
                                                id='companySite'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={companySite}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'companySite',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.companySite &&
                                                    touched.companySite
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.companySite && touched.companySite ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.companySite}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='industry'
                                                className={classes.label}
                                            >
                                                Industry
                                            </InputLabel>
                                            <Box className={classes.textField}>
                                                <Query query={allIndustries}>
                                                    {({ loading, error, data }) => {
                                                        if (loading) {
                                                            return null;
                                                        }
                                                        if (error)
                                                            return `Error! ${error.message}`;
                                                        let optionsIndustries =
                                                            data.allIndustries.map(
                                                                indastry => ({
                                                                    value: indastry.id,
                                                                    text: indastry.name,
                                                                })
                                                            );
                                                        return (
                                                            <SelectLight
                                                                id={'industry'}
                                                                error={
                                                                    errors.industry &&
                                                                    touched.industry
                                                                }
                                                                value={industry}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'industry',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                options={[
                                                                    {
                                                                        value: '',
                                                                        text: '',
                                                                    },
                                                                    ...optionsIndustries,
                                                                ]}
                                                            />
                                                        );
                                                    }}
                                                </Query>
                                            </Box>
                                        </Box>
                                        {errors.industry && touched.industry ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.industry}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='companySize'
                                                className={classes.label}
                                            >
                                                Company size
                                            </InputLabel>
                                            <Box className={classes.textField}>
                                                <SelectLight
                                                    id={'companySize'}
                                                    value={companySize}
                                                    options={companySizeList}
                                                    onChange={e =>
                                                        setFieldValue(
                                                            'companySize',
                                                            e.target.value
                                                        )
                                                    }
                                                    error={
                                                        errors.companySize &&
                                                        touched.companySize
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                        {errors.companySize && touched.companySize ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.companySize}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card variant='outlined' className={classes.card}>
                                <CardHeader title='Billing information' />
                                <CardContent>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='cardholderName'
                                                className={classes.label}
                                            >
                                                Cardholder's Name
                                            </InputLabel>
                                            <Field
                                                name='cardholderName'
                                                id='cardholderName'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={cardholderName}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'cardholderName',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.cardholderName &&
                                                    touched.cardholderName
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.cardholderName &&
                                        touched.cardholderName ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.cardholderName}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='card'
                                                className={classes.label}
                                            >
                                                Card
                                            </InputLabel>
                                            <Field
                                                name='card'
                                                id='card'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={card}
                                                onChange={e =>
                                                    setFieldValue('card', e.target.value)
                                                }
                                                error={
                                                    errors.card && touched.card
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.card && touched.card ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.card}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='address'
                                                className={classes.label}
                                            >
                                                Address
                                            </InputLabel>
                                            <Field
                                                name='address'
                                                id='address'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={address}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'address',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.address && touched.address
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.address && touched.address ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.address}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='address2'
                                                className={classes.label}
                                            >
                                                Address Line 2
                                            </InputLabel>
                                            <Field
                                                name='address2'
                                                id='address2'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={address2}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'address2',
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    errors.address2 && touched.address2
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.address2 && touched.address2 ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.address2}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='city'
                                                className={classes.label}
                                            >
                                                City
                                            </InputLabel>
                                            <Field
                                                name='city'
                                                id='city'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textField }}
                                                value={city}
                                                onChange={e =>
                                                    setFieldValue('city', e.target.value)
                                                }
                                                error={
                                                    errors.city && touched.city
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                            />
                                        </Box>
                                        {errors.city && touched.city ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.city}
                                            </Typography>
                                        ) : null}
                                    </Box>

                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='country-postalCode'
                                                className={classes.label}
                                            >
                                                {'State & ZIP / Postal Code'}
                                            </InputLabel>
                                            <Field
                                                name='state'
                                                id='state'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textFieldShort }}
                                                value={state}
                                                onChange={e =>
                                                    setFieldValue('state', e.target.value)
                                                }
                                                error={
                                                    errors.state &&
                                                    touched.state &&
                                                    errors.postalCode &&
                                                    touched.postalCode
                                                        ? true
                                                        : false
                                                }
                                                component={TextField}
                                                placeholder={'State'}
                                            />
                                            <Field
                                                name='postalCode'
                                                id='postalCode'
                                                type='text'
                                                variant='outlined'
                                                size='small'
                                                classes={{ root: classes.textFieldShort }}
                                                value={postalCode}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'postalCode',
                                                        e.target.value
                                                    )
                                                }
                                                error={errors.postalCode ? true : false}
                                                component={TextField}
                                                placeholder={'Postal Code'}
                                            />
                                        </Box>
                                        {errors.state &&
                                        touched.state &&
                                        errors.postalCode &&
                                        touched.postalCode ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.state} {errors.postalCode}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <Box className={classes.formField}>
                                            <InputLabel
                                                htmlFor='country'
                                                className={classes.label}
                                            >
                                                Country
                                            </InputLabel>
                                            <Box className={classes.textField}>
                                                <SelectAutocomplete
                                                    id={'country'}
                                                    error={
                                                        errors.country && touched.country
                                                    }
                                                    value={country}
                                                    setValue={value => {
                                                        setFieldValue('country', value);
                                                    }}
                                                    placeholder={'Select country'}
                                                    options={getNames()}
                                                />
                                            </Box>
                                        </Box>
                                        {errors.country && touched.country ? (
                                            <Typography
                                                color='error'
                                                className={classes.fieldError}
                                            >
                                                {errors.country}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box className={classes.formFieldWrap}>
                                        <FormControlLabel
                                            className={classes.acceptServise}
                                            control={
                                                <Checkbox
                                                    checked={acceptServise}
                                                    onChange={handleAcceptServise}
                                                    name='checkedB'
                                                    color='primary'
                                                />
                                            }
                                            label='I Accept The Terms Of Service'
                                        />
                                    </Box>
                                </CardContent>
                                <CardActions classes={{ root: classes.cardActons }}>
                                    <ButtonRoot type='submit' text={'Register'} />
                                </CardActions>
                            </Card>
                        </Form>
                    )}
                </Formik>
            </div>
            <FooterWithoutInstall />
        </div>
    );
};

export default compose(graphql(register, { name: 'register' }))(withRouter(SignUp));
