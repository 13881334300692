import * as Yup from "yup";
import { MIN_PASSWORD_LENGTH } from "../../../constants";

export const InvetationSignUpValidation = Yup.object().shape({
  firstName: Yup.string().required("First name is required!"),
  lastName: Yup.string().required("Last name is required!"),
  password: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`
    )
    .required("Password is required!"),
  password2: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Confirm password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`
    )
    .required("Confirm password is required!")
    .oneOf([Yup.ref("password")], "Confirm password does not match password"),
});
