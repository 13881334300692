import React from "react";
import { Formik, Form, Field } from "formik";
import PasswordField from "../../Fields/PasswordFiels";
import { ResetPasswordSchema } from "./validation";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import ButtonRoot from "../../Buttons/ButtonRoot";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "730px",
    color: "#333333",
    height: "fit-content",
  },
  headerAction: {
    display: "contents",
  },
  label: {
    color: "#333333",
    fontWeight: 500,
    fontSize: "16px",
    display: "block",
    marginRight: "30px",
    minWidth: "120px",
    textAlign: "end",
  },
  textField: {
    minWidth: "372px",
  },
  formFieldWrap: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    marginBottom: "7px",
    padding: "0 70px",
    height: "64px",
  },
  formField: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  fieldError: {
    width: "372px",
  },
  cardActons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 20px 20px",
    "& button": {
      minWidth: "125px",
    },
  },
  cardActonsSpasing: {
    "& > :not(:first-child)": {
      marginLeft: 0,
      marginTop: "7px",
    },
  },
  back: {
    marginTop: "7px",
  },
  link: {
    color: "#594BF6",
    "&:hover": {
      color: "#594BF6",
      textDecoration: "underline",
    },
  },
  btnResend: {
    color: "#594BF6",
    "&:disabled": {
      color: "#BDBDBD",
    },
  },
}));

export const ResetPasswordForm = ({ sendPassword }) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        newPassword1: "",
        newPassword2: "",
      }}
      validationSchema={ResetPasswordSchema}
      onSubmit={sendPassword}
    >
      {({
        setFieldValue,
        values: { newPassword1, newPassword2 },
        touched,
        errors,
      }) => (
        <Card variant="outlined" className={classes.card}>
          <CardHeader
            classes={{ action: classes.headerAction }}
            title="CHANGE PASSWORD"
            subheader={
              <>
                <p>
                  In order to protecr your accaunt, make sure you new password
                  has at least:
                </p>
                <div>
                  <ul>
                    <li>An uppercase character</li>
                    <li>A lowercase character</li>
                    <li>A digit/number</li>
                    <li>A special character/punctuation</li>
                  </ul>
                </div>
              </>
            }
          />
          <Form className="login-form">
            <CardContent>
              <Box className={classes.formFieldWrap}>
                <Box className={classes.formField}>
                  <InputLabel htmlFor="code" className={classes.label}>
                    New password
                  </InputLabel>
                  <Field
                    name="newPassword1"
                    id="newPassword1"
                    variant="outlined"
                    size="small"
                    classes={{ root: classes.textField }}
                    value={newPassword1}
                    onChange={(e) =>
                      setFieldValue("newPassword1", e.target.value)
                    }
                    error={
                      errors.newPassword1 && touched.newPassword1 ? true : false
                    }
                    component={PasswordField}
                    autoComplete={"off"}
                  />
                </Box>
                {errors.newPassword1 && touched.newPassword1 ? (
                  <Typography color="error" className={classes.fieldError}>
                    {errors.newPassword1}
                  </Typography>
                ) : null}
              </Box>
              <Box className={classes.formFieldWrap}>
                <Box className={classes.formField}>
                  <InputLabel htmlFor="code" className={classes.label}>
                    Repeat new password
                  </InputLabel>
                  <Field
                    name="newPassword2"
                    id="newPassword2"
                    variant="outlined"
                    size="small"
                    classes={{ root: classes.textField }}
                    value={newPassword2}
                    onChange={(e) =>
                      setFieldValue("newPassword2", e.target.value)
                    }
                    error={
                      errors.newPassword2 && touched.newPassword2 ? true : false
                    }
                    component={PasswordField}
                    autoComplete={"off"}
                  />
                </Box>
                {errors.newPassword2 && touched.newPassword2 ? (
                  <Typography color="error" className={classes.fieldError}>
                    {errors.newPassword2}
                  </Typography>
                ) : null}
              </Box>
            </CardContent>
            <CardActions
              classes={{
                root: classes.cardActons,
                spacing: classes.cardActonsSpasing,
              }}
            >
              <ButtonRoot type="submit" text={"Change password"} />
            </CardActions>
          </Form>
        </Card>
      )}
    </Formik>
  );
};
