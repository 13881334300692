import React from "react";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ButtonRoot from "../../Buttons/ButtonRoot";
import Box from "@material-ui/core/Box";

import { LoginSchema } from "./validation";
import { FORGOT_PASSWORD } from "../../../constants/routes";
import { Link } from "react-router-dom";
import PasswordField from "../../Fields/PasswordFiels";

const useStyles = makeStyles(() => ({
  card: {
    width: "730px",
    padding: "20px",
    color: "#333333",
  },
  cardTitle: {
    fontSize: "30px",
    fontWeight: 900,
    marginBottom: "36px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 110px",
    overflow: "hidden",
  },
  label: {
    color: "#333333",
    fontWeight: 500,
    fontSize: "16px",
    display: "block",
    marginRight: "30px",
    width: "120px",
    textAlign: "end",
    marginTop: "11px",
  },
  textField: {
    minWidth: "330px",
  },
  formFieldWrap: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    marginBottom: "5px",
  },
  formField: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    height: "59px",
  },
  rememberMe: {
    width: "100%",
    paddingLeft: "140px",
    marginBottom: "10px",
    "& .MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#594BF6",
    },
  },
  manageForm: {
    width: "100%",
    paddingLeft: "140px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btn: {
    backgroundColor: "#594BF6",
  },
  forgotPassword: {
    color: "#594BF6",
    fontSize: "14px",
    fontWeight: 400,
    "&:hover": {
      textDecoration: "underline",
      color: "#594BF6",
    },
  },
  helperText: {
    margin: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export const LoginForm = ({
  login,
  rememberMe,
  changeRememberMe,
  isProcessing,
}) => {
  const classes = useStyles();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={login}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Card variant="outlined" className={classes.card}>
          <Typography component="h3" className={classes.cardTitle}>
            Login
          </Typography>
          <Form className={classes.form}>
            <Box className={classes.formFieldWrap}>
              <Box className={classes.formField}>
                <InputLabel htmlFor="username" className={classes.label}>
                  E-mail
                </InputLabel>
                <Field
                  name="username"
                  id="username"
                  type="email"
                  variant="outlined"
                  size="small"
                  classes={{ root: classes.textField }}
                  value={values.username}
                  onChange={(e) => setFieldValue("username", e.target.value)}
                  error={errors.username && touched.username ? true : false}
                  component={TextField}
                  helperText={
                    errors.username && touched.username ? errors.username : ""
                  }
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                />
              </Box>
            </Box>
            <Box className={classes.formFieldWrap}>
              <Box className={classes.formField}>
                <InputLabel htmlFor="password" className={classes.label}>
                  Password
                </InputLabel>
                <Field
                  name="password"
                  id="password"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  classes={{ root: classes.textField }}
                  value={values.password}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                  error={errors.password && touched.password ? true : false}
                  component={PasswordField}
                  helperText={
                    errors.password && touched.password ? errors.password : ""
                  }
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                />
              </Box>
            </Box>
            <FormControlLabel
              className={classes.rememberMe}
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={changeRememberMe}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Remember me"
            />
            <Box className={classes.manageForm}>
              <ButtonRoot
                text={isProcessing ? "Loading..." : "Login"}
                disabled={isProcessing}
                type={"submit"}
              />
              <Link to={FORGOT_PASSWORD} className={classes.forgotPassword}>
                Forgot password
              </Link>
            </Box>
          </Form>
        </Card>
      )}
    </Formik>
  );
};
