import React from "react";
import { Field, Form, Formik } from "formik";
import {
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SelectAutocomplete from "../../Select/SelectAutocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { Query } from "react-apollo";
import { getUsers } from "api/queries";
import { formatName } from "utils";
import Select from "react-select";
import Grid from "@material-ui/core/Grid";
import { BACKEND_URL } from "constants/index";

const useStyles = makeStyles(() => ({
  fields: {
    display: "flex",
    flexDirection: "column",
    marginTop: "25px",
    width: "524px",
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: "524px",
    marginBottom: "14px",
  },
  label: {
    width: "155px",
    color: "#333",
    fontSize: "19px",
    alignItems: "center",
    fontFamily: "Lato",
  },
  buttons: {
    width: "524px",
    display: "flex",
    justifyContent: "end",
  },
  cancel: {
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginRight: "10px",
    background: "#6c757d",
    cursor: "pointer",
    borderRadius: 4,
    height: "100%",
    "&:hover": {
      background: "#646b71",
    },
  },
  save: {
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginRight: "10px",
    background: "#594bf6",
    cursor: "pointer",
    borderRadius: 4,
    height: "100%",
    "&:hover": {
      background: "#3f51b5",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    fontSize: "22px",
    maxWidth: "650px",
    marginTop: "15px",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  spacer: {
    minWidth: "110px",
    display: "flex",
    justifyContent: "end",
    marginRight: "25px",
  },
  image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const EditSpaceForm = ({
  space,
  setEditState,
  handleSubmitForm,
  editorAccess,
  handleChangeModalImage,
}) => {
  const classes = useStyles();
  const options = [
    {
      value: false,
      text: "Active",
    },
    {
      value: true,
      text: "Archive",
    },
  ];
  return (
    <Box>
      <Formik
        initialValues={{
          name: space?.name || "",
          description: space?.description || "",
          isArchived: space?.isArchived ? options[1] : options[0],
          typePrivate: space?.accessType === "A_1",
          typeAccessSettings: space?.accessType === "A_2",
          accessUsers: space?.accessUsers,
        }}
        onSubmit={(values) => {
          handleSubmitForm(values);
          setEditState(false);
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Form>
            <Grid className={classes.fields}>
              <Grid xs={12} className={classes.field}>
                <InputLabel className={classes.label}>Space Logo</InputLabel>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Box style={{ border: "#E0E0E0 1px solid", width: "100px" }}>
                    <img
                      className={classes.image}
                      style={{ height: "100%", width: "100%", padding: "10px" }}
                      src={`${BACKEND_URL}/media/${space?.logo}`}
                      alt="Space Logo"
                    />
                  </Box>
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      color: "#0C66E3",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                    onClick={handleChangeModalImage}
                  >
                    Change
                  </span>
                </Box>
              </Grid>
              <Grid xs={12} className={classes.field}>
                <InputLabel id="name" className={classes.label}>
                  Name
                </InputLabel>
                <Field
                  name="name"
                  id="name"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  value={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                  component={TextField}
                  disabled={editorAccess}
                />
              </Grid>
              <Grid xs={12} className={classes.field}>
                <InputLabel id="description" className={classes.label}>
                  Description
                </InputLabel>
                <Field
                  multiline
                  rows={5}
                  maxRows={5}
                  name="description"
                  id="description"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  value={values.description}
                  onChange={(e) => setFieldValue("description", e.target.value)}
                  component={TextField}
                  disabled={editorAccess}
                />
              </Grid>
              <Grid xs={12} className={classes.field}>
                <InputLabel id="name" className={classes.label}>
                  Status
                </InputLabel>
                <SelectAutocomplete
                  id="isArchived"
                  name="isArchived"
                  value={values?.isArchived}
                  style={{ width: "100%" }}
                  setValue={(e) => {
                    setFieldValue("isArchived", e);
                  }}
                  options={options}
                  disabled={editorAccess}
                />
              </Grid>
              <Grid className={classes.formFieldPermission}>
                <InputLabel style={{ color: "black" }}>Permission</InputLabel>
                <FormControlLabel
                  checked={values.typePrivate}
                  onChange={(e) => {
                    setFieldValue("typePrivate", e.target.checked);
                    setFieldValue("typeAccessSettings", false);
                  }}
                  control={<Radio color="primary" />}
                  label="Private"
                  disabled={editorAccess}
                />
                <FormControlLabel
                  checked={values.typeAccessSettings}
                  onChange={(e) => {
                    setFieldValue("typeAccessSettings", e.target.checked);
                    setFieldValue("typePrivate", false);
                  }}
                  control={<Radio color="primary" />}
                  label="Access settings"
                  disabled={editorAccess}
                />
                {values.typeAccessSettings && (
                  <Query query={getUsers}>
                    {({ loading, error, data }) => {
                      if (loading) return <div>Loading</div>;
                      if (error) return `Error! ${error.message}`;
                      const users = data.users.map((user) => {
                        return {
                          label: formatName(user),
                          value: user.id,
                        };
                      });
                      return (
                        <Grid
                          className={classes.field}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            style={{ width: "145px" }}
                            className={classes.label}
                          >
                            Access for:
                          </Typography>
                          <Box style={{ width: "100%" }}>
                            <Select
                              isDisabled={editorAccess}
                              closeMenuOnSelect={true}
                              defaultValue={
                                values.accessUsers?.length > 0
                                  ? values.accessUsers?.map((el) => ({
                                      value: el.id,
                                      label:
                                        el?.firstName && el?.lastName
                                          ? formatName(el)
                                          : el.label,
                                    }))
                                  : values.accessUsers
                              }
                              onChange={(e) => {
                                setFieldValue("accessUsers", e);
                              }}
                              isMulti
                              options={users}
                              size={"s"}
                              menuPlacement={"top"}
                            />
                          </Box>
                        </Grid>
                      );
                    }}
                  </Query>
                )}
              </Grid>
            </Grid>
            {editorAccess ? null : (
              <Box className={classes.buttons}>
                <Button
                  onClick={() => setEditState(false)}
                  className={classes.cancel}
                >
                  Cancel
                </Button>
                <Button onClick={submitForm} className={classes.save}>
                  Update
                </Button>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditSpaceForm;
