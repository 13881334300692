import React, { useState, memo, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import countryList from "react-select-country-list";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles((theme) => ({
  error: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #EB5757 !important",
    },
  },
}));

const CountrySelect = ({ value, setValue, placeholder, ...props }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangeInput = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onChangeInput}
      getOptionLabel={(option) => option.label || option}
      getOptionSelected={(option) => option.id || option}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          className={props.error ? classes.error : ""}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: value && (
              <>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    width: "39px",
                    borderRight: "1px solid #cacaca",
                    backgroundColor: "rgb(245,245,245)",
                    margin: "-6px 5px -6px -6px",
                  }}
                >
                  <ReactCountryFlag
                    countryCode={value.value}
                    svg
                    style={{
                      width: "16px",
                      height: "11px",
                    }}
                  />
                </Box>
              </>
            ),
          }}
        />
      )}
      renderOption={(option) => (
        <React.Fragment>
          <ReactCountryFlag
            countryCode={option.value}
            svg
            style={{
              marginRight: "5px",
              width: "16px",
              height: "11px",
              marginBottom: "-2px",
            }}
          />

          {option.label}
        </React.Fragment>
      )}
      renderTags={(value) => console.log(value)}
      style={{ minWidth: 150, ...props.style }}
      {...props}
    />
  );
};

export default memo(CountrySelect);
