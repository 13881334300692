import React, { useState, useCallback } from "react";
import * as path from "../../constants/routes";
import { withRouter } from "react-router";
import { withApollo, graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import "./stylesForgotPassword.css";
import ForgotPasswordForm from "../../components/Forms/ForgotPasswordForm/ForgotPasswordForm";
import HeaderWithoutInstall from "../../components/NavBar/HeaderWithoutInstall";
import SuccessSendInstructionForm from "../../components/Forms/ForgotPasswordForm/SuccessSendInstructionForm";
import {
  sendActivationCode,
  checkActivationCode,
  confirmEmail,
} from "../../api/mutations";
import FooterWithoutInstall from "../../components/Footer/FooterWithoutInstall.js";
import { makeStyles } from "@material-ui/core/styles";
import { useQueryParams } from "utils";

const useStyles = makeStyles(() => ({
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    height: "100%",
  },
  mainContent: {
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 116px)",
  },
  footer: {
    flex: "0 0 auto",
  },
}));

const ForgotPassword = (props) => {
  const [sendSuccess, setSendSuccess] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [disabled, setDisable] = useState(false);
  const classes = useStyles();
  const destinationPath = useQueryParams().get("destination");

  const sendInstruction = useCallback(
    (values, { setErrors }) => {
      const { email } = values;
      props
        .sendActivationCode({
          variables: {
            email: email,
          },
        })
        .then((response) => {
          if (response.data.sendActivationCode.success) {
            setUserEmail(email);
            setSendSuccess(true);
          } else {
            let errors = {};
            response.data.sendActivationCode.error.validationErrors.map(
              (error) => {
                if (error["field"] === "__all__") {
                  errors["email"] = error["messages"].join(" ");
                } else {
                  errors["email"] = error["messages"];
                }
                return null;
              }
            );
            setErrors(errors);
          }
        })
        .catch((err) => {
          if (err.graphQLErrors) {
            let errors = {};
            err.graphQLErrors.forEach((err) => {
              let errorParse = JSON.parse(JSON.stringify(err.message));
              let error = JSON.parse(errorParse.replace(/'/g, '"'));
              errors["email"] = error.message;
            });
            setErrors(errors);
          }
        });
    },
    [props]
  );

  const checkCode = useCallback(
    (values, { setErrors }) => {
      const { code } = values;
      props
        .checkActivationCode({
          variables: {
            email: userEmail,
            code: code,
          },
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.checkActivationCode.ok) {
            props.history.push(path.RESET_PASSWORD, {
              code: code,
              userId: response.data.checkActivationCode.user.id,
            });
          } else {
            setErrors({ code: "Incorrect code" });
          }
        })
        .catch((err) => {
          if (err.graphQLErrors) {
            let errors = {};
            err.graphQLErrors.forEach((err) => {
              let errorParse = JSON.parse(JSON.stringify(err.message));
              let error = JSON.parse(errorParse.replace(/'/g, '"'));
              errors["code"] = error.message;
            });
            setErrors(errors);
          }
        });
    },
    [props, userEmail]
  );

  const debounce = (callback, delay) => {
    return (...args) => {
      callback(...args);
      setTimeout(() => {
        setDisable(false);
      }, delay);
    };
  };

  const resendEmail = debounce(() => {
    setDisable(true);
    props
      .sendActivationCode({
        variables: {
          email: userEmail,
        },
      })
      .then((response) => {
        if (response.data.sendActivationCode.success) {
          setUserEmail(userEmail);
          setSendSuccess(true);
        } else {
          let errors = {};
          response.data.sendActivationCode.error.validationErrors.map(
            (error) => {
              if (error["field"] === "__all__") {
                errors["email"] = error["messages"].join(" ");
              } else {
                errors["email"] = error["messages"];
              }
              return null;
            }
          );
        }
      })
      .catch((err) => {
        if (err.graphQLErrors) {
          let errors = {};
          err.graphQLErrors.forEach((err) => {
            let errorParse = JSON.parse(JSON.stringify(err.message));
            let error = JSON.parse(errorParse.replace(/'/g, '"'));
            errors["email"] = error.message;
          });
        }
      });
  }, 3000);

  return (
    <div className={classes.loginWrapper}>
      <HeaderWithoutInstall destinationPath={destinationPath} />
      <div className={classes.mainContent}>
        {sendSuccess ? (
          <SuccessSendInstructionForm
            resendEmail={resendEmail}
            disabled={disabled}
            checkCode={checkCode}
          />
        ) : (
          <ForgotPasswordForm
            sendInstruction={sendInstruction}
            destinationPath={destinationPath}
          />
        )}
      </div>
      <div className={classes.footer}>
        <FooterWithoutInstall />
      </div>
    </div>
  );
};

export default compose(
  graphql(sendActivationCode, { name: "sendActivationCode" }),
  graphql(checkActivationCode, { name: "checkActivationCode" }),
  graphql(confirmEmail, { name: "confirmEmail" })
)(withApollo(withRouter(ForgotPassword)));
