import React, { useState } from "react";
import {
  AttachFileOutlined,
  DescriptionOutlined,
  EmailOutlined,
  EventAvailableOutlined,
} from "@material-ui/icons";

import { useApolloClient } from "react-apollo";
import {
  addLeadFiles,
  createLeadActivity,
  createLeadNote,
  deleteActivityLead,
  deleteLeadFile,
  deleteNoteLead,
  sendLeadEmail,
  updateActivityLead,
  updateNoteLead,
} from "api/mutations";
import {
  getLeadActivities,
  getLeadFiles,
  getLeadHistoryEmail,
  getLeadHistoryItem,
  getLeadNotes,
  getLeadOwnershipHistory,
} from "api/queries";
import ActionsTabs from "components/ActionsTabs";
import Email from "components/ActionsTabs/Emails";
import Activity from "components/ActionsTabs/Activity";
import Files from "components/ActionsTabs/Files";
import Notes from "components/ActionsTabs/Notes";
import swal from "sweetalert";
import { convertToRaw } from "draft-js";
const ActionsTab = ({ lead, leadId, owners, owner, maxHeight }) => {
  const apolloClient = useApolloClient();

  const [tabValue, setTabValue] = useState(0);
  const [notes, setNotes] = useState([]);
  const [files, setFiles] = useState([]);
  const [activity, setActivity] = useState([]);
  const [missedActivities, setMissedActivities] = useState(null);
  const [history, setHistory] = useState([]);
  const [emails, setEmails] = useState([]);
  const [isActivityLoading, setActivityLoading] = useState(false);
  const [ownership, setOwnership] = useState([]);

  const fetchDataActivities = async () => {
    setActivityLoading(true);

    const response = await apolloClient.query({
      query: getLeadActivities,
      fetchPolicy: "no-cache",
      variables: { leadId },
    });

    const data = response.data.leadActivities;
    if (!data) return data;

    const newMissedActivities = data.filter((activity) => !activity.done);
    setMissedActivities(newMissedActivities);

    const newActivities = data.filter(
      (activity) => !newMissedActivities.includes(activity),
    );
    setActivityLoading(false);

    return newActivities;
  };

  const fetchDataNotes = async () => {
    const response = await apolloClient.query({
      query: getLeadNotes,
      fetchPolicy: "no-cache",
      variables: {
        leadId,
      },
    });
    return response.data.leadNotes;
  };

  const fetchDataHistory = async () => {
    const response = await apolloClient.query({
      query: getLeadHistoryItem,
      fetchPolicy: "no-cache",
      variables: {
        leadId,
      },
    });
    return response.data.leadHistoryItem;
  };

  const fetchDataFiles = async () => {
    const response = await apolloClient.query({
      query: getLeadFiles,
      fetchPolicy: "no-cache",
      variables: {
        leadId,
      },
    });
    return response.data.leadFiles;
  };

  const fetchDataEmails = async () => {
    const response = await apolloClient.query({
      query: getLeadHistoryEmail,
      fetchPolicy: "no-cache",
      variables: {
        leadId,
      },
    });
    return response.data.leadHistoryEmail;
  };

  // onSubmit
  const onSubmitEmail = async (variables) => {
    const response = await apolloClient.mutate({
      mutation: sendLeadEmail,
      variables: {
        leadId,
        content: JSON.stringify(
          convertToRaw(variables.letter.getCurrentContent()),
        ),
        emails: variables.emails.map((email) => email.value),
        title: variables.subject,
      },
    });
  };

  const onSubmitNote = async (variables) => {
    const response = await apolloClient.mutate({
      mutation: createLeadNote,
      variables: {
        leadId,
        note: variables.note,
      },
    });
    fetchDataNotes().then((data) => setNotes(data));
    return response.data.createLeadNote;
  };

  const onSubmitActivity = async (variables) => {
    const response = await apolloClient.mutate({
      mutation: createLeadActivity,
      variables: {
        ...variables,
        startTime: variables?.startTime?.value || "00:00:00",
        endTime: variables?.endTime?.value || "00:00:00",
        leadId,
        managerId: +variables.manager.value,
      },
    });
    if (!response?.data?.createLeadActivity?.success) {
      await swal(
        "Error occurred!",
        response.data.createLeadActivity.errors[0].toString(),
        "error",
      );
    } else {
      fetchDataActivities().then((data) => setActivity(data));
    }
  };

  const markAsDoneUpdate = async (value) => {
    const response = await apolloClient.mutate({
      mutation: updateActivityLead,
      variables: {
        activityId: value.id,
        done: value.done,
      },
    });
    fetchDataActivities().then((data) => setActivity(data));
  };

  const uploadFiles = async (variables) => {
    const response = await apolloClient.mutate({
      mutation: addLeadFiles,
      variables: {
        files: variables,
        leadId,
      },
    });
    return response.data;
  };

  const deleteFile = async (variables) => {
    const response = await apolloClient.mutate({
      mutation: deleteLeadFile,
      variables: {
        fileId: variables,
      },
    });
    return response.data;
  };
  const fetchDataLeadOwnershipHistory = async () => {
    const response = await apolloClient.query({
      query: getLeadOwnershipHistory,
      fetchPolicy: "no-cache",
      variables: {
        leadId,
      },
    });
    return response.data.leadOwnershipHistory;
  };
  const fetchNotesData = () => {
    fetchDataActivities().then((data) => setActivity(data));
    fetchDataNotes().then((data) => setNotes(data));
    fetchDataFiles().then((data) => setFiles(data));
    fetchDataHistory().then((data) => setHistory(data));
    fetchDataEmails().then((data) => setEmails(data));
    fetchDataLeadOwnershipHistory().then((data) => setOwnership(data));
  };

  const deleteNoteHandler = async (variables) => {
    const response = await apolloClient.mutate({
      mutation: deleteNoteLead,
      variables: {
        noteId: variables,
      },
    });
    fetchDataNotes().then((data) => setNotes(data));
  };

  const deleteActivityHandler = async (variables) => {
    const response = await apolloClient.mutate({
      mutation: deleteActivityLead,
      variables: {
        activityId: variables,
      },
    });
    fetchDataActivities().then((data) => setActivity(data));
  };
  const updateNoteHandler = async (variables) => {
    const response = await apolloClient.mutate({
      mutation: updateNoteLead,
      variables: {
        noteId: variables.noteId,
        note: variables.note,
      },
    });
    fetchDataNotes().then((data) => setNotes(data));
  };
  const updateActivityHandler = async (variables) => {
    const response = await apolloClient.mutate({
      mutation: updateActivityLead,
      variables: {
        ...variables,
        startTime: variables?.startTime?.value || "00:00:00",
        endTime: variables?.endTime?.value || "00:00:00",
        leadId: leadId,
        ownerId: +variables.manager.value,
      },
    });
    fetchDataActivities().then((data) => setActivity(data));
  };

  const sheduleAnActivity = () => setTabValue(1);

  return (
    <ActionsTabs
      tabValue={tabValue}
      setTabValue={setTabValue}
      tabs={[
        {
          icon: <DescriptionOutlined />,
          label: "Notes",
          displayValue: 0,
          component: (
            <Notes
              sheduleAnActivity={sheduleAnActivity}
              isActivityLoading={isActivityLoading}
              maxHeight={maxHeight}
              markAsDoneUpdate={markAsDoneUpdate}
              notes={notes}
              files={files}
              activity={activity}
              missedActivities={missedActivities}
              history={history}
              emails={emails}
              ownership={ownership}
              fetchData={fetchNotesData}
              onSubmit={onSubmitNote}
              typenames={{
                note: "NoteLeadType",
                activity: "ActivityLeadType",
                history: "ItemLeadHistoryType",
                email: "HistoryLeadEmailType",
                file: "LeadFilesType",
                ownership: "LeadHistoryType",
              }}
              deleteActivityHandler={deleteActivityHandler}
              deleteNoteHandler={deleteNoteHandler}
              updateNoteHandler={updateNoteHandler}
              updateActivityHandler={updateActivityHandler}
              type={"lead"}
              managers={owners}
              manager={owner}
              createActivityHandler={onSubmitActivity}
            />
          ),
          unmount: true,
        },
        {
          icon: <EventAvailableOutlined />,
          label: "Activity",
          displayValue: 1,
          component: (
            <Activity
              managers={owners}
              onSubmit={onSubmitActivity}
              manager={owner}
              activities={[...activity, ...(missedActivities || [])]}
              maxHeight={maxHeight}
              fetchActivities={fetchDataActivities}
              setTabValue={setTabValue}
            />
          ),
          unmount: true,
        },
        {
          icon: <EmailOutlined />,
          label: "Email",
          displayValue: 2,
          component: (
            <Email
              emails={[
                ...new Set(
                  (lead?.contactPersons || [])
                    .filter((contact) => contact?.email)
                    .map((contact) => contact.email),
                ),
              ].map((email) => ({ value: email, title: email }))}
              type={"lead"}
              onSubmit={onSubmitEmail}
            />
          ),
        },
        {
          icon: <AttachFileOutlined />,
          label: "Files",
          displayValue: 3,
          component: (
            <Files
              uploadFiles={uploadFiles}
              deleteFiles={deleteFile}
              fileCreaterTitle={(item) =>
                item.lead.owner?.firstName
                  ? `${item.lead.owner.firstName} ${item.lead.owner.lastName}`
                  : item.lead.owner.email
              }
            />
          ),
        },
      ]}
    />
  );
};

export default ActionsTab;
