import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { Box } from "@material-ui/core";
import DragAndDropFile from "components/DragAndDropFile/DragAndDropFile";
import { getBase64 } from "utils";
import {
  AttachFile,
  PersonOutline,
  InsertDriveFile,
  DeleteOutline,
} from "@material-ui/icons";
import { BACKEND_URL } from "constants/index";
import { Formik } from "formik";

const useStyles = makeStyles(() => ({
  spanData: {
    marginRight: "15px",
    color: "#979797",
  },
  icon: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  divIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  file: {
    borderRadius: "5px",
    padding: "5px 15px",
    color: "#000000",
    backgroundColor: "white",
    display: "flex",
    overflow: "auto",
    margin: "15px",
    flexDirection: "column",
    boxShadow: "0 2px 5px 0 rgb(0 0 0 / 10%)",
  },
  wrapFile: {
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 2px 5px 0 rgb(0 0 0 / 10%)",
  },
  wrapUploadFile: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "25px 100px",
  },
  dot: {
    height: "50px",
    width: "50px",
    backgroundColor: "white",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  filesList: {
    padding: "5px 100px",
    display: "flex",
    borderTop: "1px solid #D9D9D9",
    alignItems: "center",
  },
  fileItem: {
    display: "flex",
  },
}));

const Files = ({ uploadFiles, deleteFiles, fileCreaterTitle }) => {
  const [size, setSize] = useState([]);

  const handledDragAndDropFiles =
    (values, setFieldValue) =>
    async (prevState, name, setFieldValue, newFiles) => {
      const result = [];
      for (let i = 0; i < newFiles?.length; i++) {
        setSize([...size, newFiles[i].size]);
        const fileToBase64 = await getBase64(newFiles[i]);
        result.push([fileToBase64, newFiles[i].name]);
      }

      const data = await uploadFiles(result);
      if (data?.addFiles) {
        setFieldValue("files", [...values.files, ...data.addFiles.files]);
      } else if (data?.addLeadFiles) {
        setFieldValue("files", [...values.files, ...data.addLeadFiles.files]);
      } else {
        setFieldValue("files", [...values.files, ...data.addClientFiles.files]);
      }
    };

  const getSize = (value) => {
    if (value < 1024) {
      return value + " B";
    } else if (value < 1024 * 1024) {
      return (value / 1024).toFixed(2) + " КB";
    } else {
      return (value / (1024 * 1024)).toFixed(2) + " MB";
    }
  };

  const removeAttachedFile = (fileId, setFieldValue, values) => async () => {
    await deleteFiles(fileId);
    setFieldValue(
      "files",
      values.files.filter((item) => item.id !== fileId),
    );
  };

  const getDateCreated = (value) => {
    const date = new Date(value);
    const today = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth()
    ) {
      return `Today at ${date.getHours()}:${date.getMinutes()} ${
        date.getHours() >= 12 ? "PM" : "AM"
      } `;
    }
    return `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  };

  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        files: [],
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          <Box className={"row"} style={{ marginTop: "15px" }}>
            {values.files.length > 0 ? (
              <>
                {values.files.map((item) => (
                  <>
                    <div
                      className={`col-1 ${classes.divIcon}`}
                      style={{ paddingRight: "0" }}
                    >
                      <span className={classes.dot}>
                        <AttachFile
                          className={classes.icon}
                          style={{ height: "100%" }}
                        />
                      </span>
                    </div>
                    <div
                      className="col-11"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <Box className={classes.file}>
                        <a
                          href={`${BACKEND_URL}/media/${item.file}`}
                          style={{ color: "#2F80ED" }}
                        >
                          {" "}
                          {item.title}{" "}
                        </a>
                        <div>
                          <span
                            className={classes.spanData}
                            style={{ color: "#747474" }}
                          >
                            {getDateCreated(item.createdAt)}{" "}
                          </span>
                          <span className={classes.spanData}>
                            {fileCreaterTitle(item)}
                          </span>
                          <span className={classes.spanData}>
                            {" "}
                            {getSize(item?.size)}
                          </span>
                          <span className={classes.spanData}>
                            <PersonOutline
                              style={{ verticalAlign: "middle" }}
                            />{" "}
                            {fileCreaterTitle(item)}
                          </span>
                        </div>
                      </Box>
                    </div>
                  </>
                ))}
              </>
            ) : null}
          </Box>{" "}
          <div>
            <Box className={classes.wrapFile}>
              <Box className={classes.wrapUploadFile}>
                <DragAndDropFile
                  name="files"
                  setFieldValue={setFieldValue}
                  value={values.files}
                  handler={handledDragAndDropFiles(values, setFieldValue)}
                />
              </Box>
              {values.files.length ? (
                <Box style={{ paddingBottom: "50px" }}>
                  {values.files.map((item, index) => (
                    <>
                      {" "}
                      <Box
                        className={classes.filesList}
                        key={item.id}
                        style={{
                          borderBottom:
                            index === values.files?.length - 1
                              ? "1px solid #D9D9D9"
                              : "none",
                        }}
                      >
                        <Box
                          className={classes.divIcon}
                          style={{ paddingRight: "5px" }}
                        >
                          <InsertDriveFile style={{ color: "#2F80ED" }} />
                        </Box>
                        <Box className={classes.fileName}>{item.title}</Box>
                        <Box
                          className={classes.fileName}
                          style={{
                            marginRight: "15px",
                            marginLeft: "auto",
                            color: "#747474",
                          }}
                        >
                          {getSize(item.size)}
                        </Box>
                        <Box className={classes.divIcon}>
                          <DeleteOutline
                            style={{ color: "#747474", cursor: "pointer" }}
                            onClick={removeAttachedFile(
                              item.id,
                              setFieldValue,
                              values,
                            )}
                          />
                        </Box>
                      </Box>{" "}
                    </>
                  ))}
                </Box>
              ) : null}
            </Box>
          </div>
        </>
      )}
    </Formik>
  );
};

export default Files;
