import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { BACKEND_URL } from "constants/index";
import { convertFromHTML, EditorState } from "draft-js";
import ContentState from "draft-js/lib/ContentState";
import { updateContentFileSpace } from "../../../api/mutations";
import { stateToHTML } from "draft-js-export-html";
import { useApolloClient } from "react-apollo";
import { Editor } from "react-draft-wysiwyg";
import { convertHtmlToDocxString } from "../../../api/queries";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflowX: "auto",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerButtons: {
    display: "flex",
  },
}));

const Overview = ({ space, refetch }) => {
  const classes = useStyles();
  const [editStateOverview, setEditStateOverview] = useState(false);
  const [fileOverview, setFileOverview] = useState("");
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(true);

  const fileId = space?.overviewFile?.id;
  const fetchFileAsBlob = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BACKEND_URL}/media/${space?.overviewFile?.file}`,
      );
      const blobFile = await response.blob();
      const fileTextContent = await blobFile.text();
      if (editStateOverview) {
        handleEditorState(fileTextContent);
      } else {
        setFileOverview(fileTextContent);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (space?.overviewFile) {
      fetchFileAsBlob();
    }
  }, [editStateOverview, space]);
  const handleEditorState = (fileTextContent) => {
    const contentBlocks = convertFromHTML(fileTextContent);
    const contentState = ContentState.createFromBlockArray(
      contentBlocks.contentBlocks,
      contentBlocks.entityMap,
    );
    const editorState = EditorState.createWithContent(contentState);
    setFileOverview(editorState);
  };
  const handleSaveChanges = async () => {
    const response = await apolloClient
      .mutate({
        mutation: updateContentFileSpace,
        variables: {
          fileId: fileId,
          fileName: space?.overviewFile?.fileName,
          html: stateToHTML(fileOverview.getCurrentContent()),
        },
      })
      .then((r) => {
        setEditStateOverview(false);
        refetch();
      });
  };
  const handleChangeStarred = async (isStarred) => {
    const response = await apolloClient.mutate({
      mutation: updateContentFileSpace,
      variables: {
        fileId: fileId,
        isStarred: isStarred,
      },
    });
    refetch();
  };
  const handleDownload = async () => {
    if (editStateOverview) {
      setEditStateOverview(false);
    }
    const link = document.createElement("a");
    const response = await apolloClient
      .query({
        query: convertHtmlToDocxString,
        variables: {
          html: fileOverview,
        },
      })
      .then((r) => {
        link.href = `${r?.data?.convertHtmlToDocx}`;
        link.download = "file.docx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };
  return (
    <>
      {" "}
      <Box style={{ width: "100%", minHeight: "100vh" }}>
        <Box className={classes.header}>
          <Box display={"flex"} style={{ fontSize: "26px" }}>
            <Box style={{ marginRight: "10px", fontWeight: "bold" }}>
              {space?.name}
            </Box>
          </Box>
          <Box className={classes.headerButtons}>
            {!editStateOverview && (
              <>
                <Button>
                  <CreateIcon
                    onClick={() => {
                      handleEditorState(fileOverview);
                      setEditStateOverview(true);
                    }}
                    style={{
                      color: "#828282",
                    }}
                  />
                </Button>

                <Button>
                  {space?.overviewFile?.isStarred ? (
                    <StarIcon
                      style={{ color: "#E56910" }}
                      onClick={() => {
                        handleChangeStarred(false);
                      }}
                    />
                  ) : (
                    <StarBorderIcon
                      onClick={() => {
                        handleChangeStarred(true);
                      }}
                    />
                  )}
                </Button>
              </>
            )}
            {editStateOverview ? (
              <>
                <Button
                  onClick={() => {
                    handleSaveChanges();
                  }}
                >
                  Save changes
                </Button>
                <Button
                  onClick={() => {
                    setEditStateOverview(false);
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : null}
            {!editStateOverview && (
              <Button
                onClick={() => {
                  handleDownload();
                }}
              >
                Download
              </Button>
            )}
          </Box>
        </Box>
        {loading ? (
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            Loading...
          </p>
        ) : (
          <Box
            style={{
              marginRight: "150px",
              marginLeft: "150px",
              marginTop: "50px",
            }}
          >
            {editStateOverview ? (
              <Editor
                editorState={fileOverview}
                onEditorStateChange={(newEditor) => {
                  setFileOverview(newEditor);
                }}
              />
            ) : (
              <Box dangerouslySetInnerHTML={{ __html: fileOverview }} />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Overview;
