import React, { useState, useEffect } from "react";
import NavBar from "components/NavBar";
import SideBar from "components/SideBar";
import Footer from "components/Footer";
import { withAuth } from "hocs/PrivateRoute";
import "index.css";
import UserContext from "../../userContext";

const Dashboard = (props) => {
  const { handleLanguageChange, children, user, language } = props;
  const [fullMenu, setFullMenu] = useState(true);

  useEffect(() => {
    const isFullMenu = localStorage.getItem("fullMenu");
    isFullMenu === null
      ? setFullMenu(true)
      : isFullMenu === "false"
      ? setFullMenu(false)
      : setFullMenu(true);
  }, []);

  const userData = {
    user: {
      ...user.me,
    },
  };
  return (
    <UserContext.Provider value={userData.user}>
      <div className="flexible-content">
        <SideBar fullMenu={fullMenu} setFullMenu={setFullMenu} />
        <div className="main-container">
          <NavBar
            handleLanguageChange={handleLanguageChange}
            language={language}
            fullMenu={fullMenu}
            {...props}
          />
          <div className="main-wrap-content">
            <main
              id="content"
              style={{ marginLeft: fullMenu ? "221px" : "91px" }}>
              {children(userData)}
            </main>
          </div>
          <Footer />
        </div>
      </div>
    </UserContext.Provider>
  );
};

export default withAuth(Dashboard);
