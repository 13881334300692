import { Field, Form, Formik } from "formik";
import { Box, Button, InputLabel, TextField } from "@material-ui/core";
import { BACKEND_URL } from "constants/index";
import React, { useEffect, useState } from "react";
import { useApolloClient } from "react-apollo";
import { getSpaceImages } from "../../../api/queries";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  fields: {
    display: "flex",
    marginTop: "25px",
    flexDirection: "column",
    margin: "25px",
  },
  field: {
    width: "358px",
    marginBottom: "14px",
  },
  label: {
    width: "155px",
    color: "#333",
    marginRight: "15px",
  },
  buttons: {
    width: "358px",
    display: "flex",
    justifyContent: "end",
  },
  cancel: {
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginRight: "10px",
    background: "#6c757d",
    cursor: "pointer",
    borderRadius: 4,
    height: "100%",
    "&:hover": {
      background: "#646b71",
    },
  },
  save: {
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginRight: "10px",
    background: "#594bf6",
    cursor: "pointer",
    borderRadius: 4,
    height: "100%",
    "&:hover": {
      background: "#3f51b5",
    },
  },
  uploadIMG: {
    marginTop: "20px",
    marginBottom: "15px",
    borderRadius: "10px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    background: "#cecece",
    cursor: "pointer",
    height: "100%",
    "&:hover": {
      background: "#c2c1c1",
    },
  },
}));
const UpdateSpaceForm = ({ onClose, handleSubmit, space }) => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const [spaceImages, setSpaceImages] = useState([]);
  const [selectImage, setSelectedImage] = useState();
  const fetchImages = async () => {
    const response = await apolloClient.query({
      query: getSpaceImages,
    });
    setSpaceImages(response?.data?.spaceImages);
  };
  useEffect(() => {
    setSelectedImage({ logo: `${BACKEND_URL}/media/${space?.logo}` });
    fetchImages();
  }, []);

  const handleUpload = async (setFieldValue) => {
    try {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = async (event) => {
        const file = event.target.files[0];
        let fileUrl = null;
        if (file) {
          fileUrl = URL.createObjectURL(file);
          setFieldValue("logo", {
            logo: fileUrl,
          });
          setSelectedImage({
            logo: fileUrl,
          });
        }
      };

      input.click();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <Formik
      initialValues={{
        name: space?.name || "",
        logo: selectImage,
      }}
      onSubmit={(values) => {
        handleSubmit(values);
        onClose();
      }}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Form>
          <Box className={classes.fields}>
            <Box className={classes.field}>
              <InputLabel id="name" className={classes.label}>
                Space name
              </InputLabel>
              <Field
                name="name"
                id="name"
                type="text"
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={values.name}
                onChange={(e) => setFieldValue("name", e.target.value)}
                component={TextField}
              />
            </Box>

            <Box className={classes.field}>
              <Box>
                Space image :
                <img
                  style={{
                    height: "40px",
                    width: "40px",
                    marginLeft: "20px",
                  }}
                  src={
                    selectImage?.id
                      ? `${BACKEND_URL}/media/${selectImage?.logo}`
                      : selectImage?.logo
                  }
                  alt={""}
                />
              </Box>
              <label>Upload new image or choose exist</label>
              <div
                style={{
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={classes.uploadIMG}
                onClick={() => handleUpload(setFieldValue)}
              >
                UPLOAD IMAGE
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: " repeat(5, 1fr)",
                  border: "2px solid gray",
                  borderRadius: "5px",
                }}
              >
                {spaceImages?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px 0px 5px 0px",
                    }}
                  >
                    <img
                      style={{
                        height: "40px",
                        width: "40px",

                        cursor: "pointer",
                      }}
                      src={`${BACKEND_URL}/media/${item?.logo}`}
                      alt={item.alt}
                      onClick={() => {
                        setSelectedImage(item);
                        setFieldValue("logo", item);
                      }}
                    />
                  </div>
                ))}
              </div>
            </Box>
            <Box className={classes.buttons}>
              <Button
                onClick={() => {
                  setSelectedImage({
                    logo: `${BACKEND_URL}/media/${space?.logo}`,
                  });
                  onClose();
                }}
                className={classes.cancel}
              >
                Cancel
              </Button>
              <Button onClick={submitForm} className={classes.save}>
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateSpaceForm;
