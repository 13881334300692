import React from "react";
import Modal from "./Modal";
import AddOrEditEmailTemplateForm from "../Forms/AddOrEditEmailTemplateForm";

const ModalAddOrEditEmailTemplate = ({
  isOpen,
  onClose,
  onSubmit,
  editMode,
  templateData,
  fetchTemplates,
  type,
}) => {
  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      title={editMode ? "Edit email template" : "Add new email template"}
    >
      <AddOrEditEmailTemplateForm
        editMode={editMode}
        fetchTemplates={fetchTemplates}
        templateData={templateData}
        submitForm={onSubmit}
        closeModal={onClose}
        isOpen={isOpen}
        type={type}
      />
    </Modal>
  );
};

export default ModalAddOrEditEmailTemplate;
