import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "constants/index";
import CreateIcon from "@material-ui/icons/Create";
import { Editor } from "react-draft-wysiwyg";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";
import { EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import ContentState from "draft-js/lib/ContentState";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useApolloClient } from "react-apollo";
import { updateContentFileSpace, updateWhiteBoard } from "api/mutations";
import Button from "@material-ui/core/Button";
import { convertHtmlToDocxString } from "api/queries";
import Viewer from "bpmn-js/lib/Viewer";
import Modeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-font/dist/css/bpmn-embedded.css";
const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerButtons: {
    display: "flex",
  },
}));
const Content = ({
  contentData,
  width,
  space,
  refetch,
  setContentData,
  editState,
  setEditState,
}) => {
  const [fileText, setFileText] = useState("");
  const classes = useStyles();
  const apolloClient = useApolloClient();

  const [model, setModel] = useState(null);
  const createCanvas = async (model) => {
    try {
      await model.importXML(contentData?.body);
      model.get("canvas").zoom("fit-viewport");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (contentData?.statusDraft === true || contentData?.editable === true) {
      fetchFileAsBlob();
    }
    if (contentData?.__typename === "WhiteboardType" && !editState) {
      const newViewer = new Viewer({
        container: "#bpmnview",
        keyboard: {
          document: window,
        },
      });
      setModel(newViewer);
      return () => newViewer.destroy();
    }
  }, [contentData, editState]);

  useEffect(() => {
    if (model) {
      createCanvas(model);
    }
  }, [model]);

  const fetchFileAsBlob = async () => {
    const response = await fetch(`${BACKEND_URL}/media/${contentData?.file}`);
    const blobFile = await response.blob();
    const fileTextContent = await blobFile.text();
    if (editState) {
      handleEditorState(fileTextContent);
    } else {
      setFileText(fileTextContent);
    }
  };

  const handleEditorState = (fileTextContent) => {
    const contentBlocks = convertFromHTML(fileTextContent);
    const contentState = ContentState.createFromBlockArray(
      contentBlocks.contentBlocks,
      contentBlocks.entityMap,
    );
    const editorState = EditorState.createWithContent(contentState);
    setFileText(editorState);
  };
  const handlePublicFile = async (publish) => {
    const fileId = contentData?.id;
    const response = await apolloClient.mutate({
      mutation: updateContentFileSpace,
      variables: {
        fileId: fileId,
        publish: publish,
        fileName: contentData?.fileName,
        html: stateToHTML(fileText.getCurrentContent()),
      },
    });
    refetch().then((res) => {
      const item = res?.data?.spaceInfo?.files?.find(
        (item) => item.id === fileId,
      );
      fetchFileAsBlob();
      setEditState(false);
      setContentData(item);
    });
  };
  const handleDownload = async () => {
    if (editState) {
      setEditState(false);
    }
    if (contentData?.file?.split(".")[1] === "html") {
      const link = document.createElement("a");
      const response = await apolloClient
        .query({
          query: convertHtmlToDocxString,
          variables: {
            html: fileText,
          },
        })
        .then((r) => {
          link.href = `${r?.data?.convertHtmlToDocx}`;
          link.download = "file.docx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    } else {
      const r = await fetch(BACKEND_URL + "/media/" + contentData?.file);
      const data = r.blob();
      const url = window.URL.createObjectURL(await data);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", contentData?.filename);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    }
  };
  const handleSaveChanges = async () => {
    let fileId;
    if (contentData?.__typename === "WhiteboardType") {
      fileId = contentData?.id;
      const result = await model.saveXML({ format: true });
      const response = await apolloClient.mutate({
        mutation: updateWhiteBoard,
        variables: {
          whiteboardId: fileId,
          name: contentData?.name,
          body: result?.xml,
        },
      });
    } else {
      fileId = contentData?.id;
      const response = await apolloClient.mutate({
        mutation: updateContentFileSpace,
        variables: {
          fileId: fileId,
          fileName: contentData?.fileName,
          html: stateToHTML(fileText.getCurrentContent()),
        },
      });
    }
    refetch().then((res) => {
      let item;
      setEditState(false);
      if (contentData.__typename === "WhiteboardType") {
        model.destroy();
        item = res?.data?.spaceInfo?.whiteboards?.find(
          (item) => item.id === fileId,
        );
      } else {
        item = res?.data?.spaceInfo?.files?.find((item) => item.id === fileId);
        fetchFileAsBlob();
      }
      setContentData(item);
    });
  };

  const handleEditClick = () => {
    setEditState(true);
    if (contentData?.__typename === "WhiteboardType") {
      model.destroy();
      const newModeler = new Modeler({
        container: "#bpmnview",
        keyboard: {
          document: window,
        },
      });
      setModel(newModeler);
      return () => newModeler.destroy();
    } else {
      handleEditorState(fileText);
    }
  };
  const handleChangeStarred = async (fileId, isStarred) => {
    if (contentData?.__typename === "WhiteboardType") {
      const response = await apolloClient.mutate({
        mutation: updateWhiteBoard,
        variables: {
          whiteboardId: fileId,
          isStarred: isStarred,
        },
      });
      refetch().then((res) => {
        const item = res?.data?.spaceInfo?.whiteboards?.find(
          (item) => item.id === fileId,
        );
        setContentData(item);
      });
    } else {
      const response = await apolloClient.mutate({
        mutation: updateContentFileSpace,
        variables: {
          fileId: fileId,
          isStarred: isStarred,
        },
      });
      refetch().then((res) => {
        const item = res?.data?.spaceInfo?.files?.find(
          (item) => item.id === fileId,
        );
        setContentData(item);
      });
    }
  };
  return (
    <Box style={{ width: "100%", minHeight: "100vh" }}>
      <Box className={classes.header}>
        <Box display={"flex"} style={{ fontSize: "26px" }}>
          <Box style={{ marginRight: "10px", fontWeight: "bold" }}>
            {" "}
            {space?.name}
          </Box>
          /
          <Box style={{ marginLeft: "10px" }}>
            {" "}
            {contentData?.filename || contentData?.name}{" "}
          </Box>
        </Box>
        <Box className={classes.headerButtons}>
          {!editState && (
            <Button>
              <CreateIcon
                onClick={
                  contentData?.editable ||
                  contentData?.__typename === "WhiteboardType"
                    ? handleEditClick
                    : undefined
                }
                style={{
                  color:
                    contentData?.editable ||
                    contentData?.__typename === "WhiteboardType"
                      ? "inherit"
                      : "#828282",
                }}
              />
            </Button>
          )}
          <Button>
            {contentData?.isStarred ? (
              <StarIcon
                style={{ color: "#E56910" }}
                onClick={() => {
                  handleChangeStarred(contentData?.id, false);
                }}
              />
            ) : (
              <StarBorderIcon
                onClick={() => {
                  handleChangeStarred(contentData?.id, true);
                }}
              />
            )}
          </Button>
          {editState ? (
            <>
              {contentData?.statusDraft ? (
                <Button
                  onClick={() => {
                    handlePublicFile(!contentData?.statusDraft);
                  }}
                >
                  Publish
                </Button>
              ) : null}
              <>
                <Button
                  onClick={() => {
                    handleSaveChanges();
                  }}
                >
                  Save changes
                </Button>
                <Button
                  onClick={() => {
                    if (model) {
                      model.destroy();
                    }
                    setEditState(false);
                  }}
                >
                  Cancel
                </Button>
              </>
            </>
          ) : null}
          {contentData?.__typename === "WhiteboardType" && !editState ? null : (
            <Button
              onClick={() => {
                handleDownload();
              }}
            >
              Download
            </Button>
          )}
        </Box>
      </Box>
      <Box
        width={`calc(100% - ${width}px)`}
        style={{
          marginRight: "150px",
          marginLeft: "150px",
          marginTop: "50px",
        }}
      >
        {editState ? (
          contentData?.__typename === "WhiteboardType" ? (
            <div
              id="bpmnview"
              style={{
                border: "1px solid #000000",
                height: "550px",
                width: "100%",
                margin: " 0 auto",
              }}
            ></div>
          ) : (
            <Editor
              editorState={fileText}
              onEditorStateChange={(newEditor) => {
                setFileText(newEditor);
              }}
            />
          )
        ) : contentData?.file?.split(".")[1] === "html" ? (
          <Box dangerouslySetInnerHTML={{ __html: fileText }}></Box>
        ) : contentData?.__typename === "WhiteboardType" ? (
          <>
            <div
              id="bpmnview"
              style={{
                border: "1px solid #000000",
                height: "550px",
                width: "100%",
                margin: " 0 auto",
              }}
            ></div>
          </>
        ) : (
          <DocViewer
            style={{ width: "100%", minHeight: "100vh" }}
            documents={[{ uri: `${BACKEND_URL}/media/${contentData?.file}` }]}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false,
              },

              csvDelimiter: ",",
              pdfZoom: {
                defaultZoom: 1.1,
                zoomJump: 0.2,
              },
              pdfVerticalScrollByDefault: true,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Content;
