import Modal from "./Modal";
import React from "react";
import CreateNoteForm from "../Forms/CreateNoteForm";
import { Formik } from "formik";
import ModalActions from "./ModalActions";
import Box from "@material-ui/core/Box";

const EditNoteModal = ({ isOpen, onClose, type, onSubmit, note }) => {
  return (
    <Modal open={isOpen} handleClose={onClose} title={`Edit ${type} note.`}>
      <Formik
        initialValues={{
          note: note?.note || "",
          noteId: note?.id,
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          onClose();
          onSubmit(values);
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Box
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "20px",
              minWidth: "355px",
            }}
          >
            <CreateNoteForm
              values={values}
              setFieldValue={setFieldValue}
              edit={true}
            />{" "}
            <ModalActions onClose={onClose} handleSubmit={submitForm} />
          </Box>
        )}
      </Formik>
    </Modal>
  );
};

export default EditNoteModal;
