import React from "react";
import Modal from "./Modal";
import CreateOrConvertLeadForm from "components/Forms/CreateOrConvertLeadForm";

const ModalCreateOrConvertLead = ({
  isOpen,
  createLead,
  onClose,
  onSubmit,
  values,
  stages,
  label,
}) => {
  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      title={
        createLead
          ? "Create new lead"
          : label
          ? label
          : "Convert Lead to client"
      }
    >
      <CreateOrConvertLeadForm
        submitForm={onSubmit}
        closeModal={onClose}
        createLead={createLead}
        isOpen={isOpen}
        values={values}
        stages={stages}
      />
    </Modal>
  );
};

export default ModalCreateOrConvertLead;
