import { Box, Tab, Tabs, createTheme } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import React, { useState } from "react";

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: "10px 0 0 10px",
  },
  tabs: {
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    backgroundColor: "white",
    "& div": {
      height: "60px",
      "& button": {
        padding: 0,
        height: "60px",
        "& span": {
          flexDirection: "row",
          fontSize: "18px",
          textTransform: "none",
          "& svg": {
            marginRight: "8px",
          },
        },
      },
    },
  },
}));

const theme = createTheme(theme, {
  palette: {
    primary: {
      main: "#2F80ED",
    },
  },
});

const ActionsTabs = ({ tabs, tabValue, setTabValue }) => {
  const classes = useStyles();

  const onTabValueChange = (e, value) => {
    setTabValue(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.wrapper}>
        <Tabs
          value={tabValue}
          onChange={onTabValueChange}
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary">
          {tabs.map((el, index) => (
            <Tab
              key={index}
              icon={el?.icon}
              iconPosition="start"
              label={el?.label}
              style={el?.style}
            />
          ))}
        </Tabs>

        {tabs.map((el, index) => (
          <div
            key={index}
            style={{
              display: tabValue === el.displayValue ? "block" : "none",
            }}>
            {(el.unmount && tabValue !== el.displayValue) || el.component}
          </div>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default ActionsTabs;
