import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Field, Form, Formik } from "formik";
import ModalContent from "components/Modals/ModalContent";
import { Box, InputLabel, TextField, Typography } from "@material-ui/core";
import { Query, useApolloClient, useQuery } from "react-apollo";
import "react-phone-input-2/lib/style.css";
import {
  getContactPersons,
  getIndustryType,
  getLeadCurrency,
  getLeadLabels,
  getLeadsEmailTypes,
  getLeadSource,
  getLeadsProject,
} from "api/queries";
import Loading from "components/Loader";
import SelectAutocomplete from "components/Select/SelectAutocomplete";
import Stepper from "components/Buttons/Stepper";
import PhoneInput from "react-phone-input-2";
import ModalActions from "components/Modals/ModalActions";
import CountrySelect from "components/Select/CountrySelect";
import { createContactPerson, createLabelLead } from "api/mutations";
import * as Yup from "yup";
import { createFilterOptions } from "@material-ui/lab";

export const CreateLeadSchema = Yup.object().shape({
  contactPersons: Yup.object().required("Choose contact person!").nullable(),
});

const CreateOrConvertLeadForm = (props) => {
  const useStyles = makeStyles(() => ({
    root: {
      padding: "0 135px",
      overflow: "hidden",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      "&:nth-child(1)": {
        marginRight: "108px",
      },
    },
    fields: {
      display: "flex",
    },
    field: { width: "358px", marginBottom: "14px" },
    label: {
      color: "#333",
    },
    error: {
      padding: "4px 0 0 14px",
      color: "#f44336",
      fontSize: "0.75rem",
    },

    wrapDocemens: {
      width: "492px",
    },
    wrapDocuments: {
      minHeight: "130px",
      width: "824px",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "15px",
      marginBottom: "24px",
    },
    filesList: {
      width: "100%",
      border: "1px dashed #BDBDBD",
      borderRadius: "5px",
      padding: "10px",
      height: "100%",
    },
    wrapItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    item: {
      backgroundColor: "#F2F2F2",
      borderRadius: "3px",
      flex: "2 1 600px",
      marginRight: "10px",
      height: "16px",
    },
    icon: {
      color: "#828282",
      cursor: "pointer",
    },
    addEmail: {
      color: "#333",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "17px",
      cursor: "pointer",
      marginTop: "-4px",
    },
    emailField: {
      marginRight: "10px",
    },
    noOptionsText: { cursor: "pointer", textDecoration: "underline" },
  }));

  const classes = useStyles();
  const apolloClient = useApolloClient();

  const [newInputValue, setNewInputValue] = useState("");
  const [labelInputValue, setLabelInputValue] = useState("");

  const {
    loading: labelsLoading,
    data: labelsData,
    refetch: labelsDataRefetch,
  } = useQuery(getLeadLabels);

  const createContactHandle = async (inputValue) => {
    const name = inputValue.split(" ");

    const response = await apolloClient.mutate({
      mutation: createContactPerson,
      variables: {
        firstName: name[0] || "",
        lastName: name[1] || "",
      },
    });
    return response.data?.createContactPerson?.contact;
  };
  const filter = createFilterOptions();
  const createLabel = async (value) => {
    const response = await apolloClient.mutate({
      mutation: createLabelLead,
      variables: {
        name: value,
      },
    });
    const label = response.data.createLabelLead.label;
    return { value: label.id, text: label.name };
  };

  return (
    <Formik
      validationSchema={props.createLead && CreateLeadSchema}
      initialValues={
        props?.createLead
          ? {
              contactPersons: null,
              facebook: "",
              linkedin: "",
              skype: "",
              organization: "",
              value: 0,
              currency: { value: "UAH", text: "UAH" },
              label: null,
              website: "",
              keyNeed: "",
              source: null,
              industry: null,
              phone: null,
              email: "",
              emailType: null,
              listId: 1,
            }
          : {
              contactPersons:
                props.values?.contactPersons?.length > 0
                  ? {
                      value: props.values?.contactPersons[0]?.id,
                      text: `${props.values?.contactPersons[0]?.firstName} ${props.values?.contactPersons[0]?.lastName}`,
                    }
                  : null,
              facebook: props.values?.contactPersons[0]?.facebook,
              linkedin: props.values?.contactPersons[0]?.linkedin,
              skype: props.values?.contactPersons[0]?.skype,
              organization: props.values?.organization,
              value: props.values?.value | 0,
              currency: props.values?.currency
                ? {
                    value: props.values?.currency,
                    text: props.values?.currency,
                  }
                : null,
              label: props.values?.label
                ? {
                    value: props.values?.label?.id,
                    text: props.values?.label?.name,
                  }
                : null,
              website: props.values?.website,
              keyNeed: props.values?.keyNeed,
              source: props.values?.sourceType
                ? {
                    value: props.values?.sourceType?.id,
                    text: props.values?.sourceType?.value,
                  }
                : null,
              industry: props.values?.industryType
                ? {
                    value: props.values?.industryType?.id,
                    text: props.values?.industryType?.value,
                  }
                : null,
              phone: props.values?.contactPersons[0]?.phone,
              email: props.values?.contactPersons[0]?.email,
              listId: 1,
              project: null,
              country: null,
            }
      }
      onSubmit={(variables, form) => {
        props.closeModal();
        props.submitForm(variables);
      }}
    >
      {({
        values: {
          organization,
          contactPersons,
          label,
          keyNeed,
          value,
          currency,
          phone,
          email,
          listId,
          country,
          emailType,
          linkedin,
          facebook,
          source,
          skype,
          website,
          industry,
          project,
        },
        setFieldValue,
        errors,
        touched,
        submitForm,
      }) => (
        <>
          <Form>
            <ModalContent>
              <Box className={classes.root}>
                <Box className={classes.fields}>
                  <Box className={classes.column}>
                    <Box className={classes.field}>
                      <Query query={getContactPersons}>
                        {({ loading, error, data, refetch }) => {
                          if (loading)
                            return (
                              <div className="wrap-loading">
                                <Loading size={20} />
                              </div>
                            );
                          if (error) return `Error! ${error.message}`;
                          const persons = data.contactPersons.map((el) => ({
                            value: el.id,
                            text: `${el.firstName} ${el.lastName}`,
                          }));
                          return (
                            <>
                              <InputLabel
                                id="contactPersonsLabel"
                                className={classes.label}
                              >
                                Contact persons
                              </InputLabel>
                              <SelectAutocomplete
                                id="contactPersonsSelect"
                                name="contactPersons"
                                error={
                                  errors.contactPersons &&
                                  touched.contactPersons
                                }
                                value={contactPersons}
                                setValue={(e) => {
                                  setFieldValue("contactPersons", e);
                                }}
                                options={persons}
                                inputValue={newInputValue}
                                onInputChange={(event, newInputValue) => {
                                  const regex = /^Add "(.*)"$/;
                                  const matches = newInputValue.match(regex);
                                  if (matches && matches[1]) {
                                    setNewInputValue(matches[1]);
                                  } else {
                                    setNewInputValue(newInputValue);
                                  }
                                }}
                                onChange={(event, newValue) => {
                                  if (newValue && newValue.inputValue) {
                                    const create = async (value) => {
                                      const createdContact =
                                        await createContactHandle(value);
                                      if (createdContact) {
                                        await refetch();
                                        setFieldValue("contactPersons", {
                                          value: createdContact.id,
                                          text: `${createdContact.firstName} ${createdContact.lastName}`,
                                        });
                                      }
                                    };
                                    create(newValue.inputValue);
                                  } else {
                                    setFieldValue("contactPersons", newValue);
                                  }
                                }}
                                noOptionsText={
                                  <Typography>
                                    <span
                                      onMouseDown={async () => {
                                        const createdContact =
                                          await createContactHandle(
                                            newInputValue,
                                          );
                                        if (createdContact) {
                                          await refetch();
                                          setFieldValue("contactPersons", {
                                            value: createdContact.id,
                                            text: `${createdContact.firstName} ${createdContact.lastName}`,
                                          });
                                        }
                                      }}
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Add contact "{newInputValue}"
                                    </span>
                                  </Typography>
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (params.inputValue !== "") {
                                    filtered.push({
                                      inputValue: params.inputValue,
                                      text: `Add "${params.inputValue}"`,
                                    });
                                  }
                                  return filtered;
                                }}
                              />
                              {errors.contactPersons &&
                              touched.contactPersons ? (
                                <Typography className={classes.error}>
                                  {errors.contactPersons}
                                </Typography>
                              ) : null}
                            </>
                          );
                        }}
                      </Query>
                    </Box>
                    <Box className={classes.field}>
                      <InputLabel id="organization" className={classes.label}>
                        Organization
                      </InputLabel>
                      <Field
                        name="Organization"
                        id="Organization"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        value={organization}
                        onChange={(e) =>
                          setFieldValue("organization", e.target.value)
                        }
                        error={errors.organization && touched.organization}
                        component={TextField}
                        helperText={
                          errors.organization && touched.organization
                            ? errors.organization
                            : ""
                        }
                      />
                    </Box>
                    <Box className={classes.field}>
                      <InputLabel id="value" className={classes.label}>
                        Value
                      </InputLabel>
                      <Box className="d-flex justify-content-between">
                        <Box style={{ width: "186px" }}>
                          <Field
                            name="value"
                            id="value"
                            type="text"
                            variant="outlined"
                            size="small"
                            classes={{ root: classes.textField }}
                            value={value}
                            onChange={(e) =>
                              setFieldValue("value", e.target.value)
                            }
                            error={errors.value && touched.value}
                            component={TextField}
                            helperText={
                              errors.value && touched.value ? errors.value : ""
                            }
                          />
                        </Box>
                        <Query query={getLeadCurrency} fetchPolicy="no-cache">
                          {({ loading, error, data }) => {
                            if (loading)
                              return (
                                <div className="wrap-loading">
                                  <Loading size={20} />
                                </div>
                              );
                            if (error) return `Error! ${error.message}`;
                            const currencies = data.leadSelector.currency.map(
                              (el) => ({
                                value: el.id,
                                text: el.id,
                              }),
                            );
                            return (
                              <Box style={{ width: "162px" }}>
                                <SelectAutocomplete
                                  id="currency"
                                  name="currency"
                                  error={errors.currency && touched.currency}
                                  value={currency}
                                  setValue={(e) => {
                                    setFieldValue("currency", e);
                                  }}
                                  options={currencies}
                                />
                                {errors.currency && touched.currency ? (
                                  <Typography className={classes.error}>
                                    {errors.currency}
                                  </Typography>
                                ) : null}
                              </Box>
                            );
                          }}
                        </Query>
                      </Box>
                    </Box>{" "}
                    <Box className={classes.field}>
                      <Query query={getLeadSource} fetchPolicy="no-cache">
                        {({ loading, error, data }) => {
                          if (loading)
                            return (
                              <div className="wrap-loading">
                                <Loading size={20} />
                              </div>
                            );
                          if (error) return `Error! ${error.message}`;
                          const sources = data.leadSelector.sourceType.map(
                            (el) => ({
                              value: el.id,
                              text: el.value,
                            }),
                          );
                          return (
                            <>
                              <InputLabel id="source" className={classes.label}>
                                Source
                              </InputLabel>
                              <SelectAutocomplete
                                id="source"
                                name="source"
                                placeholder="Choose source"
                                error={errors.source && touched.source}
                                value={source}
                                setValue={(e) => {
                                  setFieldValue("source", e);
                                }}
                                options={sources}
                              />
                              {errors.source && touched.source ? (
                                <Typography className={classes.error}>
                                  {errors.source}
                                </Typography>
                              ) : null}
                            </>
                          );
                        }}
                      </Query>
                    </Box>
                    <Box className={classes.field}>
                      {labelsLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <InputLabel id="label" className={classes.label}>
                            Label
                          </InputLabel>
                          <SelectAutocomplete
                            id="label"
                            name="label"
                            placeholder="Choose label"
                            error={errors.label && touched.label}
                            value={label}
                            setValue={(e) => {
                              setFieldValue("label", e);
                            }}
                            options={labelsData?.leadSelector?.labels?.map(
                              (a) => ({
                                value: a.id,
                                text: a.value,
                              }),
                            )}
                            noOptionsText={
                              <div
                                onMouseDown={async () => {
                                  const label =
                                    await createLabel(labelInputValue);
                                  setFieldValue("label", label);
                                  labelsDataRefetch();
                                }}
                                className={classes.noOptionsText}
                              >
                                {`Add ${labelInputValue}`}
                              </div>
                            }
                            inputValue={labelInputValue}
                            onInputChange={(event, newInputValue) => {
                              setLabelInputValue(newInputValue);
                            }}
                          />
                          {errors.label && touched.label ? (
                            <Typography className={classes.error}>
                              {errors.label}
                            </Typography>
                          ) : null}
                        </>
                      )}
                    </Box>
                    <Box className={classes.field}>
                      <InputLabel id="keyNeed" className={classes.label}>
                        Key need
                      </InputLabel>
                      <Field
                        name="keyNeed"
                        id="keyNeed"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        value={keyNeed}
                        onChange={(e) =>
                          setFieldValue("keyNeed", e.target.value)
                        }
                        component={TextField}
                      />
                    </Box>
                    <Box className={classes.field}>
                      <Query query={getIndustryType} fetchPolicy="no-cache">
                        {({ loading, error, data }) => {
                          if (loading)
                            return (
                              <div className="wrap-loading">
                                <Loading size={20} />
                              </div>
                            );
                          if (error) return `Error! ${error.message}`;
                          const industries = data.leadSelector.industryType.map(
                            (el) => ({
                              value: el.id,
                              text: el.value,
                            }),
                          );
                          return (
                            <>
                              <InputLabel
                                id="industry"
                                className={classes.label}
                              >
                                Industry
                              </InputLabel>
                              <SelectAutocomplete
                                id="industry"
                                name="industry"
                                placeholder="Choose  industry"
                                error={errors.industry && touched.industry}
                                value={industry}
                                setValue={(e) => {
                                  setFieldValue("industry", e);
                                }}
                                options={industries}
                              />
                              {errors.industry && touched.industry ? (
                                <Typography className={classes.error}>
                                  {errors.industry}
                                </Typography>
                              ) : null}
                            </>
                          );
                        }}
                      </Query>
                    </Box>
                    {props.createLead ? (
                      <Box className={classes.field}>
                        <InputLabel id="listId" className={classes.label}>
                          Stage
                        </InputLabel>
                        <Stepper
                          stages={props?.stages}
                          stage={listId}
                          schema={[["20%"], ["20%"], ["20%"], ["20%"], ["20%"]]}
                          changeStage={(i) => setFieldValue("listId", i)}
                        />
                      </Box>
                    ) : (
                      <Box className={classes.field}>
                        <Query query={getLeadsProject} fetchPolicy="no-cache">
                          {({ loading, error, data }) => {
                            if (loading)
                              return (
                                <div className="wrap-loading">
                                  <Loading size={20} />
                                </div>
                              );
                            if (error) return `Error! ${error.message}`;
                            const projects = data.leadSelector.projectType.map(
                              (el) => ({
                                value: el.id,
                                text: el.value,
                              }),
                            );
                            return (
                              <>
                                <InputLabel
                                  id="project"
                                  className={classes.label}
                                >
                                  Project
                                </InputLabel>
                                <SelectAutocomplete
                                  id="project"
                                  name="project"
                                  placeholder="Choose project"
                                  error={errors.project && touched.project}
                                  value={project}
                                  setValue={(e) => {
                                    setFieldValue("project", e);
                                  }}
                                  options={projects}
                                />
                                {errors.project && touched.project ? (
                                  <Typography className={classes.error}>
                                    {errors.project}
                                  </Typography>
                                ) : null}
                              </>
                            );
                          }}
                        </Query>{" "}
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.column}>
                    <Box className={classes.field}>
                      <InputLabel id="phone" className={classes.label}>
                        Phone
                      </InputLabel>
                      <Box>
                        <PhoneInput
                          style={{ width: "100%" }}
                          country="ua"
                          value={phone}
                          onChange={(e) => setFieldValue("phone", e)}
                          className={
                            errors.phone && touched.phone
                              ? "validation-error"
                              : ""
                          }
                        />
                        {errors.phone && touched.phone ? (
                          <Typography className={classes.error}>
                            {errors.phone}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    {props.createLead ? (
                      <Box className={classes.field}>
                        <InputLabel id="email" className={classes.label}>
                          Email
                        </InputLabel>

                        <Query
                          query={getLeadsEmailTypes}
                          fetchPolicy="no-cache"
                        >
                          {({ loading, error, data }) => {
                            if (loading)
                              return (
                                <div className="wrap-loading">
                                  <Loading size={20} />
                                </div>
                              );
                            if (error) return `Error! ${error.message}`;
                            const emailTypes = data.leadSelector.emailType.map(
                              (el) => ({
                                value: el.id,
                                text: el.value,
                              }),
                            );
                            return (
                              <Box className="d-flex justify-content-between">
                                <Field
                                  className={classes.emailField}
                                  name="email"
                                  id="email"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  value={email}
                                  onChange={(e) =>
                                    setFieldValue("email", e.target.value)
                                  }
                                  style={{ width: "100%" }}
                                  error={errors.email && touched.email}
                                  component={TextField}
                                  helperText={
                                    errors.email && touched.email
                                      ? errors.email
                                      : ""
                                  }
                                />

                                <Box style={{ width: "200px" }}>
                                  <SelectAutocomplete
                                    id="emailType"
                                    name="emailType"
                                    value={emailType}
                                    setValue={(e) => {
                                      setFieldValue("emailType", e);
                                    }}
                                    error={
                                      errors.emailType && touched.emailType
                                    }
                                    options={emailTypes}
                                  />
                                  {errors.emailType && touched.emailType ? (
                                    <Typography className={classes.error}>
                                      {errors.emailType}
                                    </Typography>
                                  ) : null}
                                </Box>
                              </Box>
                            );
                          }}
                        </Query>
                      </Box>
                    ) : (
                      <Box className={classes.field}>
                        <InputLabel id="email" className={classes.label}>
                          Email
                        </InputLabel>{" "}
                        <Field
                          className={classes.emailField}
                          name="email"
                          id="email"
                          type="text"
                          variant="outlined"
                          size="small"
                          value={email}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                          style={{ width: "100%" }}
                          error={errors.email && touched.email}
                          component={TextField}
                          helperText={
                            errors.email && touched.email ? errors.email : ""
                          }
                        />
                      </Box>
                    )}

                    <Box className={classes.field}>
                      <InputLabel id="website" className={classes.label}>
                        Website
                      </InputLabel>
                      <Field
                        name="website"
                        id="website"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        value={website}
                        onChange={(e) =>
                          setFieldValue("website", e.target.value)
                        }
                        component={TextField}
                      />
                    </Box>

                    <Box className={classes.field}>
                      <InputLabel id="linkedin" className={classes.label}>
                        Link to LinkedIn
                      </InputLabel>
                      <Field
                        name="linkedin"
                        id="linkedin"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        value={linkedin}
                        onChange={(e) =>
                          setFieldValue("linkedin", e.target.value)
                        }
                        component={TextField}
                      />
                    </Box>
                    <Box className={classes.field}>
                      <InputLabel id="facebook" className={classes.label}>
                        Link to Facebook
                      </InputLabel>
                      <Field
                        name="facebook"
                        id="facebook"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        value={facebook}
                        onChange={(e) =>
                          setFieldValue("facebook", e.target.value)
                        }
                        component={TextField}
                      />
                    </Box>
                    <Box className={classes.field}>
                      <InputLabel id="skype" className={classes.label}>
                        Skype
                      </InputLabel>
                      <Field
                        name="skype"
                        id="skype"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        value={skype}
                        onChange={(e) => setFieldValue("skype", e.target.value)}
                        component={TextField}
                      />
                    </Box>
                    {props.createLead ? null : (
                      <Box className={classes.column}>
                        <Box className={classes.field}>
                          <InputLabel className={classes.label}>
                            Country
                          </InputLabel>
                          <CountrySelect
                            id={"country"}
                            placeholder={"Country"}
                            error={errors.country && touched.country}
                            value={country}
                            setValue={(e) => {
                              setFieldValue("country", e);
                            }}
                            style={{
                              minWidth: "100%",
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </ModalContent>{" "}
            <ModalActions
              isOpen={props.modalCreate}
              onClose={props.closeModal}
              handleSubmit={submitForm}
            />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CreateOrConvertLeadForm;
