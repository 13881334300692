import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';

const defaultButton = props => <button {...props}>{props.children}</button>;

const BootstrapInput = withStyles(theme => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#212529',
        },
    },
}))(InputBase);

const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
};

const getVisiblePages = (page, total) => {
    if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
        if (page % 5 >= 0 && page > 4 && page + 2 < total) {
            return [1, page - 1, page, page + 1, total];
        } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
            return [1, total - 3, total - 2, total - 1, total];
        } else {
            return [1, 2, 3, 4, 5, total];
        }
    }
};

const Pagination = props => {
    const [visiblePages, setVisiblePages] = useState(getVisiblePages(null, props.pages));

    useEffect(() => {
        setVisiblePages(getVisiblePages(null, props.pages));
    }, [props.pages]);

    const changePage = page => {
        const activePage = props.page + 1;
        if (page === activePage) {
            return;
        }
        const visiblePages = getVisiblePages(page, props.pages);
        setVisiblePages(filterPages(visiblePages, props.pages));
        props.onPageChange(page - 1);
    };

    const { PageButtonComponent = defaultButton } = props;
    const activePage = props.page + 1;
    const hiddenPagination = visiblePages.length <= 1;
    const showing = !hiddenPagination && (
        <>
            Showing {activePage} to {props.pages} of {props.data.length} entries. Show:
        </>
    );

    return (
        <>
            <div className={'Table__page_size'}>
                {showing}
                <FormControl className={'Table__page_size_select'}>
                    <Select
                        labelId='demo-customized-select-label'
                        id='demo-customized-select'
                        value={props.pageSize}
                        onChange={e => props.onPageSizeChange(e.target.value)}
                        input={<BootstrapInput />}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </FormControl>
                entries
            </div>
            {!hiddenPagination && (
                <div className='Table__pagination'>
                    <div className='Table__prevPageWrapper'>
                        <PageButtonComponent
                            className='Table__pageButton'
                            onClick={() => {
                                if (activePage === 1) return;
                                changePage(activePage - 1);
                            }}
                            disabled={activePage === 1}
                        >
                            {props.previousText}
                        </PageButtonComponent>
                    </div>
                    <div className='Table__visiblePagesWrapper'>
                        {visiblePages.map((page, index, array) => {
                            return (
                                <PageButtonComponent
                                    key={page}
                                    className={
                                        activePage === page
                                            ? 'Table__pageButton Table__pageButton--active'
                                            : 'Table__pageButton'
                                    }
                                    onClick={() => changePage(page)}
                                >
                                    {array[index - 1] + 2 < page ? `...${page}` : page}
                                </PageButtonComponent>
                            );
                        })}
                    </div>
                    <div className='Table__nextPageWrapper'>
                        <PageButtonComponent
                            className='Table__pageButton'
                            onClick={() => {
                                if (activePage === props.pages) return;
                                changePage(activePage + 1);
                            }}
                            disabled={activePage === props.pages}
                        >
                            {props.nextText}
                        </PageButtonComponent>
                    </div>
                </div>
            )}
        </>
    );
};

export default Pagination;
