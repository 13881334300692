import * as Yup from "yup";
import { MIN_PASSWORD_LENGTH } from "../../constants/index";

export const RegisterValidation = Yup.object().shape({
  company_name: Yup.string().required("Company name is required!"),
  firstName: Yup.string().required("First name is required!"),
  lastName: Yup.string().required("Last name is required!"),
  email: Yup.string()
    .email("Email is required!")
    .required("Email is required!"),
  password1: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`
    )
    .required("Password is required!"),
  password2: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Confirm password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`
    )
    .oneOf([Yup.ref("password1")], "Confirm password does not match password")
    .required("Confirm password is required!"),
  companySite: Yup.string().required("Company web-site is required!"),
  industry: Yup.string().required("Industry is required!"),
});
