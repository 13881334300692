import {
  AccessTimeOutlined,
  PeopleOutline,
  PhoneOutlined,
} from "@material-ui/icons";
import { ACTIVITY_TYPES } from "constants/index";
import React from "react";

const ActivityIcon = (props) => {
  let iconComponent;

  const activityType =
    typeof props.activityType === "number"
      ? ACTIVITY_TYPES[props.activityType - 1]
      : props.activityType;

  switch (activityType) {
    case "A_1":
      iconComponent = <PhoneOutlined {...props} />;
      break;
    case "A_2":
      iconComponent = <PeopleOutline {...props} />;
      break;
    case "A_3":
      iconComponent = <AccessTimeOutlined {...props} />;
      break;
    default:
      iconComponent = <PhoneOutlined {...props} />;
  }

  return iconComponent;
};

export default ActivityIcon;
