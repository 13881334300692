import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
const useStyles = makeStyles({
  search: {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
  },
});

const SearchBox = ({ search, handleSearch }) => {
  const classes = useStyles();
  return (
    <Box className={classes.search}>
      <TextField
        id="search-project"
        type="text"
        variant="outlined"
        placeholder="Search"
        size="small"
        value={search}
        onChange={(e) => handleSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SearchIcon
                style={{
                  fontSize: "16px",
                  color: "#A2A2A2",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default React.memo(SearchBox);
