import React, { useState, useEffect } from "react";
import DayPicker from "react-day-picker";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import "./stylesDatePicker.css";
import moment from "moment";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  picker: {
    justifyContent: "start",
    color: "#BDBDBD",
    height: 40,
    borderColor: "#c4c4c4",
    width: "100%",
    "&:hover": {
      border: "1px solid #212529 !important",
      backgroundColor: "transparent",
    },
  },
  selected: {
    color: "#333333 !important",
  },
  empty: {
    textTransform: "initial",
    fontSize: "16px",
    color: "#a6a6a6",
  },
  error: {
    borderColor: "#EB5757 !important",
  },
  disabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
    opacity: 0.6,
  },
  closeIcon: {
    fontSize: "18px",
    "@supports ( -moz-appearance:none )": { marginTop: "1px" },
  },
}));

const DatePicker = ({
  selectDate,
  setSelectDate,
  position,
  error,
  styles,
  disabled,
  allDays,
  placeholder,
  showCloseButton,
  startDate,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  });

  useEffect(() => {
    if (position === "start") {
      setAnchorPosition({
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (position === "center") {
      setAnchorPosition({
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    } else if (position === "end") {
      setAnchorPosition({
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startDate > new Date(selectDate)) setSelectDate(startDate);
  }, [startDate]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setSelectDate(day);
    handleClose();
  };

  const handleClearDate = (e) => {
    e.stopPropagation();
    setSelectDate("");
  };

  const classes = useStyles();
  const today = new Date();

  return (
    <>
      <Button
        variant="outlined"
        aria-describedby={"dayPicker"}
        onClick={handleOpen}
        disabled={props.disabled}
        style={styles}
        classes={{
          outlined: clsx(
            classes.picker,
            selectDate === "" ? classes.empty : classes.selected,
            disabled ? classes.disabled : ""
          ),
        }}
        className={error ? classes.error : ""}>
        {selectDate === ""
          ? placeholder ?? "YYYY-MM-DD"
          : moment(selectDate).format("YYYY-MM-DD")}
        {selectDate && showCloseButton && (
          <Button
            onClick={handleClearDate}
            style={{ padding: "0px", minWidth: "0px", marginLeft: "3px" }}>
            <CloseIcon className={classes.closeIcon} />
          </Button>
        )}
      </Button>
      <Popover
        id={"dayPicker"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorPosition.anchorOrigin}
        transformOrigin={anchorPosition.transformOrigin}>
        <DayPicker
          onDayClick={handleDayClick}
          modifiers={{
            disabled: allDays
              ? ""
              : {
                  after: startDate ? null : today,
                  before: startDate,
                },
            selected: selectDate === "" ? null : selectDate,
          }}
          showWeekNumbers
          showOutsideDays
          firstDayOfWeek={1}
          {...props}
        />
      </Popover>
    </>
  );
};

export default DatePicker;
