import React from "react";
import Modal from "./Modal";
import TransferOwnershipForm from "../Forms/TransferOwnershipForm";

const ModalTransferOwnership = ({
  isOpen,
  onClose,
  onSubmit,
  manager,
  managers,
}) => {
  return (
    <Modal open={isOpen} handleClose={onClose} title="Transfer ownership">
      <TransferOwnershipForm
        submitForm={onSubmit}
        closeModal={onClose}
        isOpen={isOpen}
        managers={managers}
        manager={manager}
      />
    </Modal>
  );
};

export default ModalTransferOwnership;
