import React, { useState } from "react";
import { withApollo, useQuery } from "react-apollo";
import { withRouter } from "react-router";
import { User } from "api/queries";
import { BACKEND_URL } from "constants/index";
import userImg from "assets/user.png";
import { NavLink } from "react-router-dom";
import * as path from "constants/routes";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import ViewListIcon from "@material-ui/icons/ViewList";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DescriptionIcon from "@material-ui/icons/Description";
import NotesIcon from "@material-ui/icons/Notes";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EventIcon from "@material-ui/icons/Event";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import i18n from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import { userRoleChoice } from "constants/index";
import SvgIcon from "@material-ui/core/SvgIcon";
import { EMAIL_SYNC } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  rootIcon: {
    minWidth: "35px",
    color: "inherit",
  },
  listItemButton: {
    "&:hover": {
      borderRadius: "4px",
    },
  },
  divider: {
    width: "100%",
    margin: 0,
    color: "grey",
  },
  listGroup: {
    padding: "18px 24px 18px",
    color: "#828282",
  },
  wrap: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

function CampaignIcon(props) {
  return (
    <SvgIcon {...props} style={{ marginLeft: "3px" }}>
      <path d="M 18 11 v 2 h 4 v -2 h -4 Z m -2 6.61 c 0.96 0.71 2.21 1.65 3.2 2.39 c 0.4 -0.53 0.8 -1.07 1.2 -1.6 c -0.99 -0.74 -2.24 -1.68 -3.2 -2.4 c -0.4 0.54 -0.8 1.08 -1.2 1.61 Z M 20.4 5.6 c -0.4 -0.53 -0.8 -1.07 -1.2 -1.6 c -0.99 0.74 -2.24 1.68 -3.2 2.4 c 0.4 0.53 0.8 1.07 1.2 1.6 c 0.96 -0.72 2.21 -1.65 3.2 -2.4 Z M 4 9 c -1.1 0 -2 0.9 -2 2 v 2 c 0 1.1 0.9 2 2 2 h 1 v 4 h 2 v -4 h 1 l 5 3 V 6 L 8 9 H 4 Z m 11.5 3 c 0 -1.33 -0.58 -2.53 -1.5 -3.35 v 6.69 c 0.92 -0.81 1.5 -2.01 1.5 -3.34 Z" />
    </SvgIcon>
  );
}

const SideBarLinks = ({ click }) => {
  const [open, setOpen] = useState(false);
  const [financeIsCollapse, setFinanceIsCollapse] = useState(false);
  const [resourcesIsCollapse, setResourcesIsCollapse] = useState(false);
  const [businessIsCollapse, setBusinessIsCollapse] = useState(false);
  const [calendarIsCollapse, setCalendarIsCollapse] = useState(false);
  const [reportsIsCollapse, setReportsIsCollapse] = useState(false);
  const [communicationIsCollapse, setCommunicationIsCollapse] = useState(false);

  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  };

  const resourcesIsOpen = () => {
    setResourcesIsCollapse(!resourcesIsCollapse);
  };

  const businessIsOpen = () => {
    setBusinessIsCollapse(!businessIsCollapse);
  };

  const calendarIsOpen = () => {
    setCalendarIsCollapse(!calendarIsCollapse);
  };

  const reportsIsOpen = () => {
    setReportsIsCollapse(!reportsIsCollapse);
  };

  const communicationIsOpen = () => {
    setCommunicationIsCollapse(!communicationIsCollapse);
  };

  const { loading, error, data } = useQuery(User);

  if (loading) return null;
  if (error) return null;

  const { avatar, firstName, lastName, role, isOwner } = data.me;

  const showAvatar = !avatar
    ? userImg
    : avatar && `${BACKEND_URL}/media/${avatar}`;

  const activeStyle = {
    backgroundColor: "#594BF6",
    display: "flex",
    color: "#FFFFFF",
    borderRadius: "4px",
  };

  return (
    <div className={classes.wrap}>
      <List>
        <ListItem style={{ paddingLeft: "35px" }}>
          <ListItemAvatar>
            <Avatar alt={`${firstName} ${lastName}`} src={showAvatar} />
          </ListItemAvatar>
          <ListItemText primary={`${firstName} ${lastName}`} />
        </ListItem>
      </List>
      <hr className={classes.divider} />
      <div className={classes.listGroup}>
        <List component="nav">
          {userRoleChoice[role] === "Employee" && (
            <NavLink
              exact={true}
              to={path.WEEKLY_WORK}
              activeClassName="activeClass"
              activeStyle={activeStyle}
              onClick={click}
            >
              <ListItem button classes={{ button: classes.listItemButton }}>
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <EqualizerIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Weekly work")} />
              </ListItem>
            </NavLink>
          )}
          {userRoleChoice[role] !== "Employee" ? (
            <NavLink
              exact={true}
              to={path.DAILY_WORK}
              activeClassName="activeClass"
              activeStyle={activeStyle}
              onClick={click}
            >
              <ListItem button classes={{ button: classes.listItemButton }}>
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <EqualizerIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Daily work")} />
              </ListItem>
            </NavLink>
          ) : null}
          <NavLink
            exact={true}
            to={path.TIMESHEET}
            activeClassName="activeClass"
            activeStyle={activeStyle}
            onClick={click}
          >
            <ListItem button classes={{ button: classes.listItemButton }}>
              <ListItemIcon classes={{ root: classes.rootIcon }}>
                <AccessAlarmsIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("Timesheet")} />
            </ListItem>
          </NavLink>
          {userRoleChoice[role] !== "Employee" ? (
            <List component="nav">
              <ListItem button onClick={resourcesIsOpen}>
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Resources")} />
                {resourcesIsCollapse ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={resourcesIsCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink
                    exact={true}
                    to={path.EMPLOYEES}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                    onClick={click}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.rootIcon,
                        }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Employees")} />
                    </ListItem>
                  </NavLink>

                  <NavLink
                    exact={true}
                    to={path.TEAMS}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                    onClick={click}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.rootIcon,
                        }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Teams")} />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to={path.EQUIPMENTS}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                    onClick={click}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.rootIcon,
                        }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Equipments")} />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to={path.CANDIDATES}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                    onClick={click}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.rootIcon,
                        }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Candidates")} />
                    </ListItem>
                  </NavLink>
                </List>
              </Collapse>
            </List>
          ) : null}
          <List component="nav">
            <ListItem button onClick={calendarIsOpen}>
              <ListItemIcon classes={{ root: classes.rootIcon }}>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("Calendar")} />
              {calendarIsCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={calendarIsCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NavLink
                  exact={true}
                  to={path.CALENDAR}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{ root: classes.rootIcon }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Calendar")} />
                  </ListItem>
                </NavLink>
                <NavLink
                  exact={true}
                  to={path.CALENDAR_OVERVIEW}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{ root: classes.rootIcon }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Overview")} />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>
          </List>

          <List component="nav">
            <ListItem button onClick={businessIsOpen}>
              <ListItemIcon classes={{ root: classes.rootIcon }}>
                <ViewListIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("Business")} />
              {businessIsCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={businessIsCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NavLink
                  exact={true}
                  to={path.LEADS}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{
                        root: classes.rootIcon,
                      }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Leads")} />
                  </ListItem>
                </NavLink>

                <NavLink
                  exact={true}
                  to={path.CLIENTS_AND_CONTACTS}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{
                        root: classes.rootIcon,
                      }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Clients And Contacts")} />
                  </ListItem>
                </NavLink>
                <NavLink
                  exact={true}
                  to={path.PROJECTS}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{ root: classes.rootIcon }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Projects")} />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>
          </List>
          <NavLink
            exact={true}
            to={path.DOCUMENTS}
            activeClassName="activeClass"
            activeStyle={activeStyle}
            onClick={click}
          >
            <ListItem button classes={{ button: classes.listItemButton }}>
              <ListItemIcon classes={{ root: classes.rootIcon }}>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("Documents")} />
            </ListItem>
          </NavLink>
          <List component="nav">
            <ListItem button onClick={reportsIsOpen}>
              <ListItemIcon classes={{ root: classes.rootIcon }}>
                <NotesIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("Reports")} />
              {reportsIsCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={reportsIsCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NavLink
                  exact={true}
                  to={path.REPORTS_OVERVIEW}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{ root: classes.rootIcon }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Overview")} />
                  </ListItem>
                </NavLink>
                <NavLink
                  exact={true}
                  to={path.REPORTS_DAILY}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{ root: classes.rootIcon }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Daily")} />
                  </ListItem>
                </NavLink>{" "}
                <NavLink
                  exact={true}
                  to={path.APPROVED_REPORTS}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{ root: classes.rootIcon }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Approved")} />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>
          </List>
          {isOwner && (
            <List component="nav">
              <ListItem
                button
                onClick={() => setFinanceIsCollapse((prev) => !prev)}
              >
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Finance")} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={financeIsCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink
                    exact={true}
                    to={path.FINANCE_OVERVIEW}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                  >
                    <ListItem
                      button
                      classes={{
                        button: classes.listItemButton,
                      }}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.rootIcon,
                        }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Overview")} />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to={path.FINANCE_INCOME_AND_EXPENSES}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                  >
                    <ListItem
                      button
                      classes={{
                        button: classes.listItemButton,
                      }}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.rootIcon,
                        }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Income and Expenses")} />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to={path.FINANCE_SALARY}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                  >
                    <ListItem
                      button
                      classes={{
                        button: classes.listItemButton,
                      }}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.rootIcon,
                        }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Salaries")} />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to={path.INVOICES}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                    onClick={click}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemIcon
                        classes={{ root: classes.rootIcon }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Invoices")} />
                    </ListItem>
                  </NavLink>
                </List>
              </Collapse>
            </List>
          )}
          <List component="nav">
            <ListItem button onClick={communicationIsOpen}>
              <ListItemIcon classes={{ root: classes.rootIcon }}>
                <CampaignIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("Communication")} />
              {communicationIsCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={communicationIsCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NavLink
                  exact={true}
                  to={path.NOTIFICATIONS}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{
                        root: classes.rootIcon,
                      }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Notifications")} />
                  </ListItem>
                </NavLink>

                <NavLink
                  exact={true}
                  to={path.POLLS}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{
                        root: classes.rootIcon,
                      }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Polls")} />
                  </ListItem>
                </NavLink>
                <NavLink
                  exact={true}
                  to={path.REVIEWS}
                  activeClassName="activeClass"
                  activeStyle={activeStyle}
                  onClick={click}
                >
                  <ListItem button classes={{ button: classes.listItemButton }}>
                    <ListItemIcon
                      classes={{ root: classes.rootIcon }}
                    ></ListItemIcon>
                    <ListItemText primary={i18n.t("Reviews")} />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>
          </List>
        </List>
      </div>
      <hr className={classes.divider} />
      <div className={classes.listGroup}>
        <List component="nav">
          <ListItem button onClick={handleClick}>
            <ListItemIcon classes={{ root: classes.rootIcon }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("Settings")} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink
                exact={true}
                to={path.PROFILE}
                activeClassName="activeClass"
                activeStyle={activeStyle}
                onClick={click}
              >
                <ListItem button classes={{ button: classes.listItemButton }}>
                  <ListItemIcon
                    classes={{ root: classes.rootIcon }}
                  ></ListItemIcon>
                  <ListItemText primary={i18n.t("Account settings")} />
                </ListItem>
              </NavLink>
              {isOwner && (
                <>
                  <NavLink
                    exact={true}
                    to={path.Billing}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                    onClick={click}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemIcon
                        classes={{ root: classes.rootIcon }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Billing")} />
                    </ListItem>
                  </NavLink>

                  <NavLink
                    exact={true}
                    to={path.COMPANY}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                    onClick={click}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemIcon
                        classes={{ root: classes.rootIcon }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Company")} />
                    </ListItem>
                  </NavLink>
                </>
              )}
              <NavLink
                exact={true}
                to={path.EMAIL_SYNC}
                activeClassName="activeClass"
                activeStyle={activeStyle}
                onClick={click}
              >
                <ListItem button classes={{ button: classes.listItemButton }}>
                  <ListItemIcon
                    classes={{ root: classes.rootIcon }}
                  ></ListItemIcon>
                  <ListItemText primary={i18n.t("Email Sync")} />
                </ListItem>
              </NavLink>
            </List>
          </Collapse>
        </List>
      </div>
    </div>
  );
};

export default withApollo(withRouter(SideBarLinks));
