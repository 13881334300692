import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import * as path from "../../constants/routes";
import clsx from "clsx";
import TUC_logo from "../../assets/TUC_logo.svg";

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: "#BDBDBD",
    backgroundColor: "#FFFFFF",
    minHeight: "60px",
    fontSize: "16px",
    fontWeight: 500,
    position: "sticky",
    top: 0,
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pick: {
    color: "#594BF6",
  },
  gap: {
    marginRight: "20px",
  },
}));
export default function HeaderWithoutInstall(props) {
  const classes = useStyles();

  return (
    <AppBar position="static" classes={{ root: classes.appBar }}>
      <Container maxWidth="lg" className={classes.container}>
        <div>
          <img src={TUC_logo} alt={"Logo"} />
        </div>
        {!props.destinationPath && (
          <div>
            <Link
              to={path.SIGN_IN}
              className={clsx(props.login ? classes.pick : "", classes.gap)}
            >
              Login
            </Link>
            <Link
              to={path.SIGN_UP}
              className={props.register ? classes.pick : ""}
            >
              Register
            </Link>
          </div>
        )}
      </Container>
    </AppBar>
  );
}
