import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import DeleteSpaceModal from "components/Modals/DeleteSpaceModal";
const DeleteSpaceTab = ({ space, submitDeleteSpace, submitArchiveSpace }) => {
  const [isOpenModal, setOpenModal] = useState(false);

  const handleChangeModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  return (
    <Box>
      <Box
        style={{
          marginTop: "35px",
        }}
      >
        <Box
          style={{
            backgroundColor: "#FFECEB",
            fontSize: "22px",
            padding: "15px",
            display: "flex",
          }}
        >
          <Box style={{ marginRight: "10px" }}>
            <ReportProblemOutlinedIcon
              style={{
                color: "#C9372C",
              }}
            />
          </Box>
          <Box flex={1}>
            When you delete a space, all its content will be permanently
            deleted. You will not be able to restore data that was in space.
            <br />
            <br />
            If you want to be able to restore the partition later, archive it
            instead of deleting it.
          </Box>
        </Box>
        <Button
          style={{
            marginTop: "25px",
            color: "white",
            backgroundColor: "#C9372C",
            fontWeight: "600",
          }}
          onClick={handleChangeModal}
        >
          Delete this space
        </Button>
        <DeleteSpaceModal
          submitDelete={submitDeleteSpace}
          onClose={handleChangeModal}
          name={space?.name}
          submitArchive={submitArchiveSpace}
          isOpen={isOpenModal}
        />
      </Box>
    </Box>
  );
};

export default DeleteSpaceTab;
