import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { ResetPasswordForm } from "../../components/Forms/ResetPasswordForm";
import { SIGN_IN } from "../../constants/routes";
import { resetPassword } from "../../api/mutations/index.js";
import FooterWithoutInstall from "../../components/Footer/FooterWithoutInstall.js";
import HeaderWithoutInstall from "../../components/NavBar/HeaderWithoutInstall";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    height: "100%",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

const ResetPassword = (props) => {
  const classes = useStyles();
  const sendPassword = useCallback(
    (values, { setErrors }) => {
      const { newPassword1, newPassword2 } = values;
      const { userId, code } = props.location.state;
      props
        .resetPassword({
          variables: {
            newPassword1,
            newPassword2,
            activationCode: parseInt(code),
            userId: userId,
          },
        })
        .then((response) => {
          if (response.data.resetPassword.success) {
            props.history.push(SIGN_IN);
          } else {
            let errors = {};
            response.data.resetPassword.error.validationErrors.map((error) => {
              errors[error] = error["messages"];
              return null;
            });
            setErrors({ errors });
          }
        });
    },
    [props]
  );

  return (
    <div className={classes.root}>
      <HeaderWithoutInstall />
      <div className={classes.content}>
        <ResetPasswordForm sendPassword={sendPassword} />
      </div>
      <FooterWithoutInstall />
    </div>
  );
};

export default compose(graphql(resetPassword, { name: "resetPassword" }))(
  withRouter(ResetPassword)
);
