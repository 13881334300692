import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    bottom: 0,
    backgroundColor: "#594bf6",
    position: "sticky",
    width: "100%",
    textAlign: "center",
  },
  copyrightInstall: {
    color: "#a7a6ab",
    padding: "16px",
  },
}));

const FooterWithoutInstall = () => {
  const classes = useStyles();
  return (
    <Box component="footer" className={classes.footer}>
      <Box className={classes.copyrightInstall}>
        <Typography>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <span style={{ color: "#ffffff" }}>tuc24.com</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterWithoutInstall;
