import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";
import {
  AccessTimeOutlined,
  AttachFile,
  PeopleOutline,
  PhoneOutlined,
  FiberManualRecord,
  CheckCircle,
  FiberManualRecordOutlined,
} from "@material-ui/icons";
import { BACKEND_URL } from "constants/index";
import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import moment from "moment";
import { formatName } from "utils";
import EditActivityModal from "components/Modals/EditActivityModal";
import CreateNoteForm from "components/Forms/CreateNoteForm";
import Loading from "components/Loader";
import EditNoteModal from "components/Modals/EditNoteModal";
import CreateActivityModal from "components/Modals/CreateActivityModal";

const useStyles = makeStyles(() => ({
  button: {
    border: "none",
    backgroundColor: "transparent",
    color: "inherit",
    cursor: "pointer",
    fontSize: "inherit",
    borderRadius: "5px",
  },
  notes: {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "5px 15px",
    color: "#000000",
    backgroundColor: "white",
    display: "flex",
    overflow: "auto",
    margin: "15px",
    flexDirection: "column",
  },
  active: {
    backgroundColor: "#d9e4fc",
    color: "#2F80ED",
  },
  spanData: {
    marginRight: "15px",
    color: "#979797",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  divIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noEvalibleMessage: {
    marginLeft: "16px",
    marginTop: "10px",
  },
  itemWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "92px",
    overflow: "hidden",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    paddingLeft: "30px",
    height: "100%",
  },
  activityIcon: {
    backgroundColor: "white",
    padding: "6px",
    borderRadius: "50%",
  },
  verticalDeshedLine: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    height: "100%",
    position: "absolute",
    "& > div": { height: "96%", borderLeft: "2px dashed #979797" },
  },
  dot: {
    height: "30px",
    width: "30px",
    backgroundColor: "white",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  focusLoading: {
    paddingLeft: "10px",
    display: "flex",
    justifyContent: "center",
  },
  focusCollapseButton: {
    fontSize: "18px",
    fontWeight: "600",
    paddingLeft: "10px",
    cursor: "pointer",
    userSelect: "none",
  },
  emptyFocusButton: {
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.5s ease",
    padding: "0px 10px",
    cursor: "pointer",
    color: "#1E72C8",
  },
}));

const Notes = ({
  notes,
  files,
  activity,
  history,
  emails,
  fetchData,
  onSubmit,
  typenames,
  markAsDoneUpdate,
  maxHeight,
  deleteNoteHandler,
  updateNoteHandler,
  updateActivityHandler,
  managers,
  manager,
  type,
  deleteActivityHandler,
  ownership,
  createActivityHandler,
  isActivityLoading,
  sheduleAnActivity,
  missedActivities,
}) => {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState("All");
  const [anchorElA, setAnchorElA] = useState(null);
  const openA = Boolean(anchorElA);
  const [anchorElN, setAnchorElN] = useState(null);
  const openN = Boolean(anchorElN);
  const [data, setData] = useState(null);
  const [editModalActivity, setEditModalActivity] = useState(false);
  const [editModalNote, setEditModalNote] = useState(false);
  const [createModalActivity, setCreateModalActivity] = useState(false);
  const [isEmptyFocusHovered, setIsEmptyFocusHovered] = useState(false);

  const handleEmptyFocusMouseEnter = () => {
    setIsEmptyFocusHovered(true);
  };

  const handleEmptyFocusMouseLeave = () => {
    setIsEmptyFocusHovered(false);
  };

  const sheduleAnActivityClickHandler = () => (e) => {
    sheduleAnActivity();
    e.stopPropagation();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickNote = (event) => {
    setAnchorElN(event.currentTarget);
  };
  const handleClickActivity = (event) => {
    setAnchorElA(event.currentTarget);
  };

  const handleCloseNote = (e) => {
    setAnchorElN(null);
  };
  const handleCloseActivity = (e) => {
    setAnchorElA(null);
  };
  const getSize = (value) => {
    if (value < 1024) {
      return value + " B";
    } else if (value < 1024 * 1024) {
      return (value / 1024).toFixed(2) + " КB";
    } else {
      return (value / (1024 * 1024)).toFixed(2) + " MB";
    }
  };

  const getDateCreated = (value) => {
    const startTime = moment(value.startTime, "HH:mm");
    const startDate = moment(value.startDate);
    const combinedDateTime = startDate.add({
      hours: startTime.hours(),
      minutes: startTime.minutes(),
    });

    const today = moment();
    const date = combinedDateTime.toDate();

    if (today.isSame(date, "day")) {
      return `Today at ${combinedDateTime.format("h:mm A")}`;
    } else if (today.clone().subtract(1, "day").isSame(date, "day")) {
      return `Yesterday at ${combinedDateTime.format("h:mm A")}`;
    } else if (today.clone().add(1, "day").isSame(date, "day")) {
      return `Tomorrow at ${combinedDateTime.format("h:mm A")}`;
    } else {
      return combinedDateTime.format("MMMM D, YYYY");
    }
  };
  const buttons = [
    { label: "All", type: "All" },
    { label: "Notes", type: "Notes", count: notes.length },
    { label: "Activities", type: "Activities", count: activity.length },
    { label: "Email", type: "Email", count: emails.length },
    { label: "Files", type: "Files", count: files.length },
  ];
  const handleCloseEditModal = () => {
    setEditModalActivity(false);
  };
  const handleCloseCreateModal = () => {
    setCreateModalActivity((prevState) => !prevState);
  };
  const handleCloseEditModalNote = () => {
    setEditModalNote(false);
  };
  function renderFileItem(item, index, isLast) {
    return (
      <div className={classes.itemWrapper} key={index}>
        <div className={classes.iconWrapper}>
          <div
            className={classes.verticalDeshedLine}
            style={
              isLast ? { alignItems: "flex-start", paddingTop: "2px" } : {}
            }
          >
            <div style={isLast ? { height: "48%" } : {}} />
          </div>
          <div
            className={`col-1 ${classes.divIcon}`}
            style={{ paddingRight: "0" }}
          >
            {" "}
            <span className={classes.dot}>
              <AttachFile
                fontSize="large"
                className={classes.icon}
                style={{ height: "70%" }}
              />
            </span>
          </div>
        </div>
        <div className="col-11" style={{ paddingLeft: "0" }}>
          <Box className={classes.notes}>
            <div>
              File:{" "}
              <a href={`${BACKEND_URL}/media/${item.file}`}> {item.title} </a>{" "}
            </div>
            <div>
              <span className={classes.spanData} style={{ color: "#747474" }}>
                {getDateCreated(item.createdAt)}{" "}
              </span>
              <span className={classes.spanData}>
                {formatName(item?.manager)}
              </span>
              <span className={classes.spanData}> {getSize(item?.size)}</span>
            </div>
          </Box>
        </div>
      </div>
    );
  }

  const getColor = (entry) => {
    const currentDate = moment();
    const currentTime = moment().format("HH:mm");
    if (entry.done) {
      return "rgb(116, 116, 116)";
    } else if (currentDate.isSame(entry.endDate, "day")) {
      if (currentTime > entry.endTime) {
        return "#cc3637";
      } else {
        return "#077838";
      }
    } else if (currentDate.isBefore(entry.endDate)) {
      return "rgb(116, 116, 116)";
    } else {
      return "#cc3637";
    }
  };

  function renderActivityItem(item, index, isLast) {
    return (
      <div className={classes.itemWrapper} key={index}>
        <div className={classes.iconWrapper}>
          <div
            className={classes.verticalDeshedLine}
            style={
              isLast ? { alignItems: "flex-start", paddingTop: "2px" } : {}
            }
          >
            <div style={isLast ? { height: "48%" } : {}} />
          </div>
          <div
            className={`col-1 ${classes.divIcon}`}
            style={{ paddingRight: "0" }}
          >
            {item.activityType === "A_1" ? (
              <PhoneOutlined
                className={classes.activityIcon}
                fontSize="large"
              />
            ) : item.activityType === "A_2" ? (
              <PeopleOutline
                className={classes.activityIcon}
                fontSize="large"
              />
            ) : (
              <AccessTimeOutlined
                className={classes.activityIcon}
                fontSize="large"
              />
            )}
          </div>
        </div>
        <div className="col-11" style={{ paddingLeft: "0" }}>
          <Box
            className={classes.notes}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              setEditModalActivity(true);
              setData(item);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {item.done ? (
                  <Tooltip title="Unmark as done" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: "5px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        markAsDoneUpdate({ id: item.id, done: !item.done });
                      }}
                    >
                      <CheckCircle style={{ color: "green" }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Mark as done" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: "5px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        markAsDoneUpdate({ id: item.id, done: !item.done });
                        if (missedActivities?.length == 1) {
                          setCreateModalActivity(true);
                        }
                      }}
                    >
                      <FiberManualRecordOutlined
                        style={{
                          color: "#d7d7d7",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {item.activityType === "A_1" ? (
                  <> {item.title}</>
                ) : item.activityType === "A_2" ? (
                  <> {item.title}</>
                ) : item.activityType === "A_3" ? (
                  <> {item.title}</>
                ) : null}
              </Box>
              <MoreHorizIcon
                variant="contained"
                onClick={(event) => {
                  event.stopPropagation();
                  setData(item);
                  handleClickActivity(event);
                }}
                style={{
                  cursor: "pointer",
                }}
              />{" "}
              <Popover
                open={openA}
                anchorEl={anchorElA}
                onClose={handleCloseActivity}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    cursor: "pointer",
                    color: "#495057",
                    borderRadius: "4px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <Typography
                  className={classes.table}
                  style={{
                    padding: "6px 10px 2px",
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCloseEditModal();
                    setEditModalActivity(true);
                    handleCloseActivity();
                  }}
                >
                  Edit
                </Typography>
                <Typography
                  className={classes.table}
                  style={{ padding: "2px 10px 6px", cursor: "pointer" }}
                  sx={{ p: 2 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteActivityHandler(data.id);
                    handleCloseActivity();
                  }}
                >
                  Delete
                </Typography>
              </Popover>
            </div>
            <div>
              <span
                className={classes.spanData}
                style={{ color: getColor(item) }}
              >
                {getDateCreated(item)}{" "}
              </span>
              <span className={classes.spanData}>
                {formatName(item?.createdBy)}
              </span>
            </div>
          </Box>
        </div>
      </div>
    );
  }

  function renderEmailItem(item, index, isLast) {
    return (
      <div className={classes.itemWrapper} key={index}>
        <div className={classes.iconWrapper}>
          <div
            className={classes.verticalDeshedLine}
            style={
              isLast ? { alignItems: "flex-start", paddingTop: "2px" } : {}
            }
          >
            <div style={isLast ? { height: "48%" } : {}} />
          </div>
          <div
            className={`col-1 ${classes.divIcon}`}
            style={{ paddingRight: "0" }}
          >
            <FiberManualRecord
              fontSize="large"
              className={classes.icon}
              style={{ color: "white", height: "100%" }}
            />
          </div>
        </div>
        <div className="col-11" style={{ paddingLeft: "0" }}>
          <Box className={classes.notes}>
            <div>Email</div>
            <div>
              <span className={classes.spanData} style={{ color: "#747474" }}>
                {getDateCreated(item.sentAt)}{" "}
              </span>
              <span className={classes.spanData}>
                {formatName(item?.createdBy)}
              </span>
            </div>
          </Box>
        </div>
      </div>
    );
  }

  function renderOwnershipItem(item, isLast) {
    return (
      <div className={classes.itemWrapper}>
        <div className={classes.iconWrapper}>
          <div
            className={classes.verticalDeshedLine}
            style={
              isLast ? { alignItems: "flex-start", paddingTop: "2px" } : {}
            }
          >
            <div style={isLast ? { height: "48%" } : {}} />
          </div>
          <div
            className={`col-1 ${classes.divIcon}`}
            style={{ paddingRight: "0" }}
          >
            <FiberManualRecord
              fontSize="large"
              className={classes.icon}
              style={{ color: "white", height: "100%" }}
            />
          </div>
        </div>
        <div className="col-11" style={{ paddingLeft: "0" }}>
          <Box className={classes.notes}>
            <div>Ownership: Changed to {item.managerNameChanged} </div>
            <div>
              <span className={classes.spanData} style={{ color: "#747474" }}>
                {getDateCreated(item.createdAt)}{" "}
              </span>
              <span className={classes.spanData}>{item.managerName}</span>
            </div>
          </Box>
        </div>
      </div>
    );
  }
  function renderHistoryItem(item, isLast) {
    let stageText = item.nameItem;
    const index = history.findIndex(
      (historyItem) => historyItem.historyId === item.historyId,
    );

    if (index > 0) {
      const prevItem = history[index - 1];
      stageText = prevItem.nameItem + " > " + stageText;
    }
    return (
      <div className={classes.itemWrapper} key={index}>
        <div className={classes.iconWrapper}>
          <div
            className={classes.verticalDeshedLine}
            style={
              isLast ? { alignItems: "flex-start", paddingTop: "2px" } : {}
            }
          >
            <div style={isLast ? { height: "48%" } : {}} />
          </div>
          <div
            className={`col-1 ${classes.divIcon}`}
            style={{ paddingRight: "0" }}
          >
            <FiberManualRecord
              fontSize="large"
              className={classes.icon}
              style={{ color: "white", height: "100%" }}
            />
          </div>
        </div>
        <div className="col-11" style={{ paddingLeft: "0" }}>
          <Box className={classes.notes}>
            <div>Stage: {stageText}</div>
            <div>
              <span className={classes.spanData} style={{ color: "#747474" }}>
                {getDateCreated(item.createdAt)}{" "}
              </span>
              <span className={classes.spanData}>{item.managerName}</span>
            </div>
          </Box>
        </div>
      </div>
    );
  }

  function renderNoteItem(item, index, isLast) {
    return (
      <div className={classes.itemWrapper} key={index}>
        <div className={classes.iconWrapper}>
          <div
            className={classes.verticalDeshedLine}
            style={
              isLast ? { alignItems: "flex-start", paddingTop: "2px" } : {}
            }
          >
            <div style={isLast ? { height: "48%" } : {}} />
          </div>
          <div
            className={`col-1 ${classes.divIcon}`}
            style={{ paddingRight: "0" }}
          >
            <FiberManualRecord
              fontSize="large"
              className={classes.icon}
              style={{ color: "white", height: "100%" }}
            />
          </div>
        </div>
        <div className="col-11" style={{ paddingLeft: "0" }}>
          <Box className={classes.notes}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              Note: {item.note}{" "}
              <MoreHorizIcon
                variant="contained"
                onClick={(event) => {
                  setData(item);
                  handleClickNote(event);
                }}
                style={{
                  cursor: "pointer",
                }}
              />{" "}
              <Popover
                open={openN}
                anchorEl={anchorElN}
                onClose={handleCloseNote}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    cursor: "pointer",
                    color: "#495057",
                    borderRadius: "4px",
                    padding: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <Typography
                  className={classes.table}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditModalNote(true);
                  }}
                >
                  Edit
                </Typography>
                <Typography
                  className={classes.table}
                  style={{
                    cursor: "pointer",
                  }}
                  sx={{ p: 2 }}
                  onClick={(event) => {
                    deleteNoteHandler(data.id);
                    handleCloseNote(event);
                  }}
                >
                  Delete
                </Typography>
              </Popover>
            </div>
            <div>
              <span className={classes.spanData} style={{ color: "#747474" }}>
                {getDateCreated(item.createdAt)}{" "}
              </span>
              <span className={classes.spanData}>
                {item.createdBy.firstName} {item.createdBy.lastName}
              </span>
            </div>
          </Box>
        </div>
      </div>
    );
  }

  const renderData = () => {
    if (activeButton === "All") {
      const newHistory = history.map((item) => ({
        ...item,
        createdAt: item.historyDate,
        date: item.createdAt,
      }));
      const newEmails = emails.map((item) => ({
        ...item,
        createdAt: item.sentAt,
      }));
      const newOwnership = ownership.map((item) => ({
        ...item,
        createdAt: item.historyDate,
        date: item.createdAt,
      }));
      let array = [
        ...notes,
        ...newOwnership,
        ...activity,
        ...files,
        ...newHistory,
        ...newEmails,
      ];
      array = array.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      return (
        <>
          {array.map((item, index) => (
            <>
              {item.__typename === typenames.note && renderNoteItem(item)}
              {item.__typename === typenames.activity &&
                renderActivityItem(item)}
              {item.__typename === typenames.history &&
                renderHistoryItem(item, !(array.length - (index + 1)))}
              {item.__typename === typenames.email && renderEmailItem(item)}
              {item.__typename === typenames.file && renderFileItem(item)}
              {item.__typename === typenames.ownership &&
                renderOwnershipItem(item)}
            </>
          ))}
        </>
      );
    }
    if (activeButton === "Notes") {
      return (
        <>
          {notes.length > 0 ? (
            notes.map((item, index) =>
              renderNoteItem(item, index, !(notes.length - (index + 1))),
            )
          ) : (
            <p className={classes.noEvalibleMessage}>No notes available.</p>
          )}
        </>
      );
    }
    if (activeButton === "Activities") {
      return (
        <>
          {activity.length > 0 ? (
            activity.map((item, index) =>
              renderActivityItem(item, index, !(activity.length - (index + 1))),
            )
          ) : (
            <p className={classes.noEvalibleMessage}>
              No activities available.
            </p>
          )}
        </>
      );
    }
    if (activeButton === "Email") {
      return (
        <>
          {emails.length > 0 ? (
            emails.map((item, index) =>
              renderEmailItem(item, index, !(emails.length - (index + 1))),
            )
          ) : (
            <p className={classes.noEvalibleMessage}>No emails available.</p>
          )}
        </>
      );
    }
    if (activeButton === "Files") {
      return (
        <>
          {files.length > 0 ? (
            files.map((item, index) =>
              renderFileItem(item, index, !(files.length - (index + 1))),
            )
          ) : (
            <p className={classes.noEvalibleMessage}>No files available.</p>
          )}
        </>
      );
    }
  };

  const submitHandler = (resetForm) => async (values) => {
    if (!values.note) return;
    await onSubmit(values);
    resetForm();
  };

  return (
    <Formik
      initialValues={{
        note: "",
      }}
    >
      {({ values, setFieldValue, resetForm }) => (
        <>
          <CreateNoteForm
            values={values}
            setFieldValue={setFieldValue}
            onSubmit={submitHandler(resetForm)}
          />
          <Box style={{ maxHeight: `${maxHeight - 128}px`, overflowY: "auto" }}>
            <Box style={{ paddingTop: "25px" }}>
              <span className={classes.focusCollapseButton}>Planned</span>
              <div>
                {isActivityLoading && missedActivities === null ? (
                  <div className={classes.focusLoading}>
                    <Loading />
                  </div>
                ) : missedActivities?.length > 0 ? (
                  missedActivities?.map((item) => renderActivityItem(item))
                ) : (
                  <div
                    className="d-flex flex-column align-items-center justify-content-between"
                    style={{
                      width: "100%",
                      height: "116px",
                    }}
                  >
                    <div>No focus items yet</div>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#979797",
                      }}
                    >
                      Scheduled activities, pinned notes, emails drafts
                      <br />
                      and scheduled emails will appear here.
                    </div>

                    <div
                      className={classes.emptyFocusButton}
                      onMouseEnter={handleEmptyFocusMouseEnter}
                      onMouseLeave={handleEmptyFocusMouseLeave}
                      style={{
                        background: isEmptyFocusHovered
                          ? "rgba(218, 218, 219, 1)"
                          : "rgba(0, 0, 0, 0)",
                        borderRadius: "5px",
                      }}
                      onClick={sheduleAnActivityClickHandler()}
                    >
                      <div
                        style={{
                          fontSize: "20px",
                          marginRight: "8px",
                        }}
                      >
                        +
                      </div>
                      <div>Schedule an activity</div>
                    </div>
                  </div>
                )}
              </div>
            </Box>

            <Box style={{ marginTop: "10px" }}>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  paddingLeft: "10px",
                }}
              >
                History
              </span>
              <Box
                style={{
                  paddingTop: "15px",
                  paddingLeft: "10px",
                }}
              >
                {buttons.map((button, index) => (
                  <button
                    key={index}
                    className={
                      activeButton === button.type
                        ? `${classes.button} ${classes.active}`
                        : classes.button
                    }
                    onClick={() => setActiveButton(button.type)}
                  >
                    {button.label === "All" ? (
                      <>{button.label}</>
                    ) : (
                      <>
                        {button.label} ({button.count})
                      </>
                    )}
                  </button>
                ))}
              </Box>
              <Box>{renderData()}</Box>
            </Box>
          </Box>
          <CreateActivityModal
            isOpen={createModalActivity}
            onClose={handleCloseCreateModal}
            onSubmit={createActivityHandler}
            managers={managers}
            type={type}
            manager={manager}
          />
          <EditActivityModal
            isOpen={editModalActivity}
            onClose={handleCloseEditModal}
            onSubmit={updateActivityHandler}
            managers={managers}
            manager={manager}
            activity={data}
            type={type}
          />
          <EditNoteModal
            isOpen={editModalNote}
            onClose={handleCloseEditModalNote}
            onSubmit={updateNoteHandler}
            note={data}
            type={type}
          />
        </>
      )}
    </Formik>
  );
};

export default Notes;
