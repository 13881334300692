import React from "react";
import { Formik } from "formik";
import { Box } from "@material-ui/core";
import CreateActivityForm from "components/Forms/CreateActivityForm";
import * as Yup from "yup";
import { formatName } from "utils";

const EditActivityForm = ({
  managers,
  submitForm,
  closeModal,
  activity,
  ModalButtons,
}) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Task name is required"),
    manager: Yup.object().required("Manager is required").nullable(),
  });
  const choices = {
    A_1: 1,
    A_2: 2,
    A_3: 3,
  };
  return (
    <Formik
      initialValues={{
        activityId: activity?.id,
        title: activity?.title,
        activityType: choices[activity?.activityType],
        startDate: activity?.startDate,
        startTime: { value: activity?.startTime, text: activity?.startTime },
        endDate: activity?.endDate,
        endTime: { value: activity?.endTime, text: activity?.endTime },
        note: activity?.note,
        manager: {
          text: formatName(activity?.manager),
          value: activity?.manager?.id,
        },
        done: activity?.done,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        closeModal();
        submitForm(values);
      }}
      enableReinitialize={true}>
      {({ values, submitForm, setFieldValue, errors, touched }) => (
        <Box
          style={{
            display: "flex",
          }}>
          <Box
            style={{
              width: "100%",
              minWidth: "655px",
              margin: "10px",
              marginBottom: 0,
            }}>
            <CreateActivityForm
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              managers={managers}
              resetForm={closeModal}
              border={"none"}
              activity={activity}
              ModalButtons={ModalButtons}
              submitForm={submitForm}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default EditActivityForm;
