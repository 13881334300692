import { withStyles } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";

const ModalContent = withStyles((theme) => ({
  root: {
    padding: "20px 8px 16px",
    minWidth: "325px",
  },
}))(MuiDialogContent);

export default ModalContent;
