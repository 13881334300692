import React from "react";
import { Box, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Field, Form } from "formik";

const useStyles = makeStyles(() => ({
  formRow: {
    color: "#747474",
    fontSize: "18px",
    overflow: "auto",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  formField: {
    width: "100%",
  },
}));

const CreateNoteForm = ({ values, onSubmit, setFieldValue, edit }) => {
  const classes = useStyles();

  const submitHandler = (e) => {
    e.preventDefault();
    onSubmit(values);
  };

  return (
    <Form
      style={{ height: "100%", overflowY: "hidden" }}
      onSubmit={submitHandler}
    >
      <Box className={classes.formRow}>
        {edit ? (
          <Field
            variant="outlined"
            value={values.note}
            placeholder="Take a note,@name..."
            className={classes.formField}
            multiline
            rows={6}
            component={TextField}
            onChange={(e) => setFieldValue("note", e.target.value)}
          />
        ) : (
          <Field
            variant="outlined"
            value={values.note}
            placeholder="Take a note,@name..."
            className={classes.formField}
            component={TextField}
            onChange={(e) => setFieldValue("note", e.target.value)}
          />
        )}
      </Box>
    </Form>
  );
};

export default CreateNoteForm;
