import React from "react";
import { Button } from "@material-ui/core";

const Home = () => {
  return (
    <div>
      <h1>Landing</h1>
      <Button
        onClick={() => {
          window.location.replace("/login");
        }}
      >
        login
      </Button>
    </div>
  );
};

export default Home;
