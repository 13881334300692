import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles(theme => ({
    error: {
        '& .MuiNativeSelect-select': {
            border: '1px solid #EB5757 !important',
        },
    },
}));

const BootstrapInput = withStyles(theme => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'transparent',
        textTransform: 'uppercase',
        fontSize: '14px',
        lineHeight: '14px',
        fontWeight: 500,
        minWidth: '80px',
        width: '100%',
        padding: '10px 26px 10px 12px',
        border: '1px solid #c4c4c4',
        '&:focus': {
            borderRadius: 4,
            backgroundColor: 'transparent',
        },
        '&:hover': {
            borderColor: '#212529',
        },
        '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'none',
            opacity: 0.6,
        },
        '& option': {
            color: '#212529',
        },
    },
    root: {
        height: 40,
        width: '100%',
    },
}))(InputBase);

const SelectLight = ({ id, value, onChange, options, disabled, error }) => {
    const classes = useStyles();
    return (
        <NativeSelect
            id={id}
            value={value}
            onChange={onChange}
            input={<BootstrapInput className={error ? classes.error : ''} />}
            disabled={disabled}
            style={value ? { color: '#333333' } : { color: '#BDBDBD' }}
        >
            {options.map(option => {
                return (
                    <option value={option.value} key={option.value}>
                        {option.text === undefined ? option : option.text}{' '}
                    </option>
                );
            })}
        </NativeSelect>
    );
};

export default SelectLight;
