import React, { useRef, useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import SideBarLinks from "./Links/sidebarLinks";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Avatar from "@material-ui/core/Avatar";

import Links from "./Links";
import * as path from "constants/routes";
import { BACKEND_URL } from "constants/index";
import { useClickOutside } from "utils/index";
import i18n from "i18n";

const BootstrapInput = withStyles(() => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "inherit",
    border: "none",
    fontSize: 16,
    padding: "9px 26px 9px 12px",
    "&:focus": {
      backgroundColor: "inherit",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: "#BDBDBD",
    backgroundColor: "#FFFFFF",
    minHeight: "61px",
    position: "sticky",
    top: 0,
    boxShadow: "0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)",
    [theme.breakpoints.down(1200)]: {
      paddingLeft: 0,
    },
  },
  wrap: {
    height: "61px",
    padding: "7px 20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnLogout: {
    color: "#594BF6",
    marginLeft: "10px",
  },
  title: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    cursor: "default",
  },
  drawer: {
    width: "271px",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "271px",
    marginTop: "62px",
  },
  userName: {
    fontSize: 16,
    paddingRight: 10,
    color: "#212529",
  },
  avatarStyles: {
    maxHeight: 24,
    alignItems: "center",
    display: "flex",
    marginLeft: 15,
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  avatar: {
    width: 40,
    height: 40,
    background: "#f5f5f5",
    borderRadius: "100%",
    boxSizing: "border-box",
    position: "relative",
    objectFit: "cover",
  },
  secondarySelect: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 37,
    background: "#ffffff",
    boxShadow:
      "0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%)",
    minWidth: 168,
    maxHeight: 255,
    zIndex: "100",
    cursor: "auto",
    color: "#828282",
    border: "1px solid #d4d4d5",
    borderRadius: 5,
  },
  coreAvatar: {
    margin: "auto",
  },
}));

const NavBar = (props) => {
  const classes = useStyles();
  const innerRef = useRef(null);
  useClickOutside(innerRef, () => toggleSelect(false));

  const [width, setWidth] = useState(window.innerWidth);
  const [driverRight, setDriverRight] = useState(false);
  const [driverLeft, setDriverLeft] = useState(false);
  const [select, toggleSelect] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    if (window.innerWidth >= 1200) {
      setDriverRight(false);
      setDriverLeft(false);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawerRight = () => {
    setDriverRight(!driverRight);
  };

  const handleDrawerLeft = () => {
    setDriverLeft(!driverLeft);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    props.history.push(path.SIGN_IN);
    props.client.resetStore();
  };

  const pageTitle = () => {
    const pathname = props.location.pathname;
    switch (true) {
      case pathname === path.PROFILE:
        return "Settings > Account";
      case pathname === path.Billing:
        return "Settings > Billing";
      case pathname === path.COMPANY:
        return "Settings > Company";
      case pathname === path.EMPLOYEES:
        return "Resources > Employees";
      case pathname === path.TEAMS:
        return "Resources > Teams";
      case pathname === path.EQUIPMENTS:
        return "Resources > Equipments";
      case pathname.startsWith(path.CANDIDATES):
        return "Resources > Candidates";
      case pathname.startsWith(path.JOB_POSTS):
        return "Resources > Job Posts";
      case pathname === path.CALENDAR:
        return "Calendar > Dates";
      case pathname === path.CALENDAR_OVERVIEW:
        return "Calendar > Overview";
      case pathname === path.PROJECTS:
        return "Business > Projects";
      case pathname === path.PROSPECTS:
        return "Business > Prospects";
      case pathname.startsWith(path.LEADS):
        return "Business > Leads";
      case pathname === path.CLIENTS_AND_CONTACTS:
        return "Business > Clients And Contacts";
      case pathname === path.INVOICES:
        return "Business > Invoices";
      case pathname === path.TIMESHEET:
        return "Timesheet";
      case pathname === path.REPORTS_DAILY:
        return "Reports > Daily";
      case pathname === path.TEXTS:
        return "Documents > Texts";
      case pathname === path.DIAGRAMS:
        return "Documents > Diagrams";
      case pathname === path.CREATE_DIAGRAM:
        return "Documents > Create diagram";
      case pathname === path.DAILY_WORK:
        return "Daily work";
      case pathname === path.APPROVED_REPORTS:
        return "Reports > Approved reports";
      case pathname === path.WEEKLY_WORK:
        return "Weekly work";
      case pathname === path.PAYMENTS:
        return "Payments";
      case pathname === path.FINANCE_OVERVIEW:
        return "Finance > Overview";
      case pathname === path.FINANCE_INCOME_AND_EXPENSES:
        return "Finance > Income And Expenses";
      case pathname === path.NOTIFICATIONS:
        return "Communication > Notifications";
      case pathname === path.POLLS:
        return "Communication > Polls";
      case pathname === path.REVIEWS:
        return "Communication > Reviews";
      case pathname === path.FINANCE_SALARY:
        return "Finance > Salaries";
      case pathname === path.INBOX:
        return "Inbox";
      case pathname === path.MAILBOX:
        return "Mailbox";
      case /^\/space\/\d+$/.test(pathname):
        return "Space";
      case pathname === path.DOCUMENTS:
        return "Documents";
      case pathname === path.INVOICE_OVERVIEW:
        return "Invoice Overview";
      case pathname === path.EMAIL_SYNC:
        return "Email sync";
      default:
        if (pathname.indexOf("/project/") !== -1) {
          return `Detail project / ${props.location.state.projectName}`;
        } else {
          return "";
        }
    }
  };

  const clickNavItem = () => {
    handleDrawerLeft();
  };

  const onClosePopupMenu = () => {
    toggleSelect((prevState) => !prevState);
  };

  const user = props.user.me;
  const avatar = !!user.avatar && `${BACKEND_URL}/media/${user.avatar}`;
  const userName = `${user.firstName} ${user.lastName}`;

  const avatarElementDiv = avatar ? (
    <img src={avatar} className={classes.avatar} alt="" />
  ) : (
    <Avatar className={classes.coreAvatar} />
  );

  return (
    <AppBar position="static" classes={{ root: classes.appBar }}>
      <div
        className={classes.wrap}
        style={{ marginLeft: props.fullMenu ? "221px" : "91px" }}
      >
        {width <= 1199 && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerLeft}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography className={classes.title}>{i18n.t(pageTitle())}</Typography>
        {width <= 1199 ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerRight}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <div className="d-flex align-items-center">
            <div className="position-relative">
              <div onClick={onClosePopupMenu} className={classes.avatarStyles}>
                <Typography className={classes.userName}>{userName}</Typography>
                <span className="pointer">{avatarElementDiv}</span>
              </div>
              {select && (
                <div className={classes.secondarySelect} ref={innerRef}>
                  <div className="text-center pt-3">
                    {avatarElementDiv}
                    <p className="mt-2 mb-0 font-weight-bold">{userName}</p>
                    <p className="mb-0 font-weight-normal">
                      {user.jobTitle?.name}
                    </p>
                  </div>
                  <hr className="devider" />
                  <div className="mb-3">
                    <Link
                      className="dropdown-item pointer"
                      onClick={onClosePopupMenu}
                      to={path.PROFILE}
                    >
                      Profile
                    </Link>
                    <div
                      className="dropdown-item pointer"
                      onClick={handleLogout}
                    >
                      Log out
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Select
              variant="outlined"
              id="select"
              value={props.language}
              onChange={props.handleLanguageChange}
              input={<BootstrapInput />}
            >
              <MenuItem value={"en"}>EN</MenuItem>
              <MenuItem value={"ru"}>RU</MenuItem>
            </Select>
          </div>
        )}
      </div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={driverRight}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Links handleLogout={handleLogout} />
      </Drawer>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={driverLeft}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <SideBarLinks click={clickNavItem} />
      </Drawer>
    </AppBar>
  );
};

export default withApollo(withRouter(NavBar));
