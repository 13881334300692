import { Box } from "@material-ui/core";
import React, { useState } from "react";
import EditSpaceForm from "components/Forms/EditSpaceForm";
import SpaceImageChangeModal from "components/Modals/SpaceImageChangeModal";
const SpaceDetailsTab = ({ space, user, handleSubmitForm }) => {
  const [isEditState, setEditState] = useState(false);
  const [isChangeImage, setChangeImage] = useState(false);
  const handleChangeModalImage = () => {
    setChangeImage((prevState) => !prevState);
  };

  const editorAccess = space?.createdBy?.id !== user?.id;
  return (
    <Box>
      <EditSpaceForm
        setEditState={setEditState}
        space={space}
        isEditState={isEditState}
        handleSubmitForm={handleSubmitForm}
        editorAccess={editorAccess}
        handleChangeModalImage={handleChangeModalImage}
      />
      <SpaceImageChangeModal
        isOpen={isChangeImage}
        onClose={handleChangeModalImage}
        handleSubmit={handleSubmitForm}
        space={space}
      />
    </Box>
  );
};

export default SpaceDetailsTab;
