import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useRouteRedirect = () => {
  const location = useLocation();
  useEffect(() => {
    if (
      window.location.hostname === "tuc24.com" ||
      window.location.hostname === "dashboard.tuc24.com"
    ) {
      if (
        window.location.hostname === "tuc24.com" &&
        !["/"].includes(location?.pathname)
      ) {
        window.location.replace(location?.pathname);
      }
      if (
        window.location.hostname === "dashboard.tuc24.com" &&
        ["/"].includes(location?.pathname)
      ) {
        window.location.replace(location?.pathname);
      }
    }
  }, [location]);
};
export default useRouteRedirect;
