import Modal from "./Modal";
import React from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  cancel: {
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginRight: "10px",
    background: "#6c757d",
    cursor: "pointer",
    borderRadius: 4,
    height: "100%",
    "&:hover": {
      background: "#646b71",
    },
  },
  archive: {
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginRight: "10px",
    background: "#594bf6",
    cursor: "pointer",
    borderRadius: 4,
    height: "100%",
    "&:hover": {
      background: "#3f51b5",
    },
  },
  delete: {
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    background: "#f44336",
    marginRight: "10px",
    cursor: "pointer",
    borderRadius: 4,
    height: "100%",
    "&:hover": {
      background: "#f32c1e",
    },
  },
}));
const DeleteSpaceModal = ({
  isOpen,
  onClose,
  name,
  submitDelete,
  submitArchive,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      title={`Delete "${name}" space?`}
    >
      <Box
        maxWidth={600}
        style={{
          padding: "25px",
          fontSize: "20px",
        }}
      >
        <Box>
          Are you sure you want to delete this partition? All its contents will
          be permanently deleted. Data recovery will not be possible. <br />
          <br />
          We recommend that you archive the partition if you want to be able to
          recover it in the future.
        </Box>
        <Box
          style={{ display: "flex", justifyContent: "end", marginTop: "15px" }}
        >
          <Button
            className={classes.cancel}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
          <Button
            className={classes.archive}
            onClick={() => {
              onClose();
              submitArchive();
            }}
          >
            archive
          </Button>
          <Button
            className={classes.delete}
            onClick={() => {
              onClose();
              submitDelete();
            }}
          >
            delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteSpaceModal;
