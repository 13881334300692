import React, { useEffect } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import _ from 'lodash';

import { verifyToken } from 'api/mutations';
import { User } from 'api/queries';
import { SIGN_IN } from '../constants/routes';

export const withAuth = ProtectedRoute =>
    _.flowRight(
        graphql(verifyToken, { name: 'authorization' }),
        graphql(User, { name: 'user' })
    )(
        withRouter(props => {
            useEffect(() => {
                checkAuth();
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, []);
            const { authorization, history, location, user } = props;
            const checkAuth = async () => {
                try {
                    const data =
                        window.localStorage.getItem('token') ||
                        window.sessionStorage.getItem('token');
                    const token = JSON.parse(data).token;
                    await authorization({
                        variables: {
                            token,
                        },
                    });
                } catch (error) {
                    window.localStorage.removeItem('token');
                    window.sessionStorage.removeItem('token');
                    history.push(`${SIGN_IN}?destination=${location.pathname}`);
                }
            };
            if (!user.loading) {
                return <ProtectedRoute {...props} />;
            }
            return null;
        })
    );
