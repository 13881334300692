import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
  },
}));

const PasswordField = (props) => {
  const [visibility, setVisibility] = useState(false);
  const classes = useStyles();

  return (
    <TextField
      type={visibility ? "text" : "password"}
      {...props}
      InputProps={{
        endAdornment: visibility ? (
          <Visibility
            className={classes.icon}
            onClick={() => {
              setVisibility(!visibility);
            }}
          />
        ) : (
          <VisibilityOff
            className={classes.icon}
            onClick={() => {
              setVisibility(!visibility);
            }}
          />
        ),
      }}
    />
  );
};

export default PasswordField;
