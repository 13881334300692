import { Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const ButtonSwitch = ({
  style,
  className,
  switchChangeHandler,
  options,
  currentOptionValue,
  strokeWidth,
  defaultColor,
  color,
}) => {
  const useStyles = makeStyles(() => ({
    switch: {
      whiteSpace: "nowrap",
      marginRight: "20px",
      "& .button": {
        border: "1px solid",
        width: "fit-content",
        height: "40px",
        borderRadius: 0,
        "& path": {
          strokeWidth: strokeWidth || 1,
        },
      },
      "& .button:nth-child(1)": {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
      },
      "& .button:nth-last-child(1)": {
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
      },
      "& .unActiveButton": {
        borderColor: defaultColor || "#E0E0E0",
        color: defaultColor || "black",
        "& path": {
          stroke: defaultColor || "black",
        },
      },
      "& .activeButton": {
        borderColor: color || "#594BF6",
        color: color || "#594BF6",
        "& path": {
          stroke: color || "#594BF6",
        },
      },
    },
    switchElement: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      marginLeft: "6px",
      textTransform: "none",
    },
  }));

  const classes = useStyles();

  return (
    <div style={style} className={className}>
      <div className={classes.switch}>
        {options.map((el) => (
          <Tooltip
            title={el?.tooltip || ""}
            placement={el?.tooltipPlacement || "top"}
            arrow>
            <Button
              key={el.value}
              variant="outlined"
              className={
                currentOptionValue === el.value
                  ? "activeButton button"
                  : "unActiveButton button"
              }
              onClick={switchChangeHandler(el.value)}>
              <div className={classes.switchElement}>
                {el.icon}

                {el?.title && <div className={classes.title}>{el?.title}</div>}
              </div>
            </Button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default ButtonSwitch;
