import { Box, Button } from "@material-ui/core";
import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const ArchiveSpaceTab = ({ space, submitArchiveSpace }) => {
  return (
    <Box>
      <Box
        style={{
          marginTop: "35px",
        }}
      >
        <Box
          style={{
            backgroundColor: "#E9F2FF",
            fontSize: "22px",
            padding: "15px",
            display: "flex",
          }}
        >
          <Box style={{ marginRight: "10px" }}>
            <InfoOutlinedIcon
              style={{
                color: "#0C66E3",
              }}
            />
          </Box>
          <Box flex={1}>
            The archived space cannot be accessed using basic site navigation.
            Archiving helps hide unnecessary information so that it's easier to
            find relevant and important content.
            <br />
            <br />
            If the archive partition is needed again, it can be found and
            restored at any time
          </Box>
        </Box>
        {space?.isArchived ? (
          <Button
            style={{
              marginTop: "25px",
              color: "white",
              backgroundColor: "#0C66E3",
              fontWeight: "600",
            }}
            onClick={submitArchiveSpace}
          >
            Unarchive this space
          </Button>
        ) : (
          <Button
            style={{
              marginTop: "25px",
              color: "white",
              backgroundColor: "#0C66E3",
              fontWeight: "600",
            }}
            onClick={submitArchiveSpace}
          >
            Archive this space
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ArchiveSpaceTab;
