import React, { Fragment } from "react";
import { useQuery } from "react-apollo";
import { User } from "../../../api/queries/index";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    margin: 0,
    color: "grey",
  },
  btnLogout: {
    color: "#594BF6",
  },
  company: {
    width: "100%",
    padding: "20px 8px",
    textAlign: "center",
    fontSize: "22px",
  },
}));

const Links = ({ handleLogout }) => {
  const { loading, error, data } = useQuery(User);
  const classes = useStyles();

  if (loading) return null;
  if (error) return null;

  const user = data.me;
  user.isOwner = user.company?.owner?.id === user.id;
  return (
    <Fragment>
      <div className={classes.company}>
        <a
          href={user.company ? user.company.siteUrl : "#"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {user.company ? user.company.name : "no company"}
        </a>
      </div>
      <hr className={classes.divider} />
      <Button onClick={handleLogout} className={classes.btnLogout}>
        Log Out
      </Button>
    </Fragment>
  );
};

export default Links;
