import Modal from "./Modal";
import React from "react";
import EditActivityForm from "components/Forms/EditActivityForm";

const EditActivityModal = ({
  isOpen,
  onClose,
  onSubmit,
  managers,
  manager,
  maxHeight,
  activity,
  type,
}) => {
  return (
    <Modal open={isOpen} handleClose={onClose} title={`Edit ${type} activity`}>
      <EditActivityForm
        submitForm={onSubmit}
        closeModal={onClose}
        managers={managers}
        manager={manager}
        maxHeight={maxHeight}
        activity={activity}
        ModalButtons={true}
      />
    </Modal>
  );
};

export default EditActivityModal;
