import React, { useEffect, useState } from "react";
import moment from "moment";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";

import { BACKEND_URL } from "constants/index";

export const loadData = (key) => {
  const data = JSON.parse(window.localStorage.getItem(key));
  if (data) {
    const timeDiff = Math.abs(data.timestamp - new Date().getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays > 1) {
      window.localStorage.removeItem("token");
      window.sessionStorage.removeItem("token");
      return "";
    }
    return data.token;
  }
  return "";
};

export const saveData = (key, value, rememberMe) => {
  const timestamp = +new Date();
  const data = JSON.stringify({ token: value, timestamp });
  rememberMe
    ? window.localStorage.setItem(key, data)
    : window.sessionStorage.setItem(key, data);
};

export const getCurrentDate = (date) => {
  let today = date;
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return (today = `${yyyy}-${month}-${day}`);
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getImageOfBase64 = async (e) => {
  try {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if (file.size <= 1048576) {
      const image = await getBase64(file);
      return image;
    }
    return false;
  } catch (error) {}
};

export const getWeekDays = (weekStart) => {
  const days = [weekStart];

  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }

  return days;
};

export const getMonths = (mode, date) => {
  const type = mode === "week" ? "isoWeek" : "months";
  const start = moment(date).startOf(type);
  const end = moment(date).endOf(type);
  const days = [];
  let day = start;

  do {
    days.push(moment(day._d).format("YYYY-MM-DD"));
    day = day.add(1, "d");
  } while (day <= end);

  return days;
};

export const getYears = (date) => {
  const years = [];

  for (let i = 0; i < 12; i++) {
    years.push(moment(date).month(i).format("YYYY-MM-DD"));
  }

  return years;
};

export const getDates = (mode, date) => {
  if (mode === "week") {
    return getWeekDays(date);
  } else if (mode === "month") {
    return getMonths(mode, date);
  } else {
    return getYears(date);
  }
};

export const getWeekRange = (date) => ({
  from: moment(date).startOf("isoWeek").toDate(),
  to: moment(date).endOf("isoWeek").toDate(),
});

export const getMinMaxYears = (start, end) => {
  const min = Math.min(...start);
  const max = Math.max(...end);
  const years = [];

  for (let year = min; year <= max; year++) {
    years.push(year);
  }

  return years;
};

export const getWorkDuration = (time) => {
  const momentTime = moment(time, "HH:mm:ss");
  const minutes = (100 / 60) * momentTime.minutes();

  return `${momentTime.format("HH")}.${minutes
    .toString()
    .split(".")[0]
    .padStart(2, "0")}`;
};

export const strConvertToSS = (str) => {
  if (!str) {
    return null;
  }
  let time_parts = str.split(":");
  let hh = Number(time_parts[0]) * (60 * 60);
  let mm = Number(time_parts[1]) * 60;
  let SS = hh + mm;
  return SS;
};

export const ssConvertToStr = (sec_num) => {
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export const stringTimeToSeconds = (stringTime) =>
  +`${stringTime
    .split(":")
    .reduce(
      (accumulator, currentValue, index) =>
        index === 0
          ? accumulator + currentValue * 3600
          : index === 1
            ? accumulator + currentValue * 60
            : accumulator + +currentValue,
      0,
    )}.01`;

export const secondsToDefaultTime = (time) => {
  const hours = `${Math.floor(time / 3600)}`;
  const minutes = `${Math.floor((time % 3600) / 60)}`;
  const seconds = `${Math.floor(time % 60)}`;

  return `${hours.length === 1 ? "0" + hours : hours}:${
    minutes.length === 1 ? "0" + minutes : minutes
  }:${seconds.length === 1 ? "0" + seconds : seconds}`;
};

export const generateWeekDays = (currentDate) => {
  let startWeek = moment(currentDate).weekday(1).format("YYYY-MM-DD");
  const days = [startWeek];

  for (let i = 1; i < 7; i += 1) {
    days.push(
      moment(startWeek, "YYYY-MM-DD").add(i, "days").format("YYYY-MM-DD"),
    );
  }

  return days;
};

export const generateMonthDays = (currentDate) => {
  let startMonth = moment(currentDate).startOf("month").format("YYYY-MM-DD");
  let maxDaysInMonth = moment(currentDate, "YYYY-MM-DD").daysInMonth();
  const daysInMonth = [startMonth];

  for (let i = 1; i < maxDaysInMonth; i += 1) {
    daysInMonth.push(
      moment(startMonth, "YYYY-MM-DD").add(i, "days").format("YYYY-MM-DD"),
    );
  }
  return daysInMonth;
};

export const shortTime = (str) => {
  if (typeof str !== "string" || !str.includes(":")) {
    return "";
  }

  let time_parts = str.split(":");

  if (time_parts.length < 2 || isNaN(time_parts[0]) || isNaN(time_parts[1])) {
    return "";
  }

  return `${time_parts[0]}:${time_parts[1]}`;
};

export const generateYearsOptions = (startYear, endYear) => {
  let options = [];
  for (let i = startYear; i <= endYear; i += 1) {
    options.push({ value: i, text: `${i}` });
  }
  return options;
};

export const totaHoursOfWeek = (monthArr, statistics) => {
  let weekNumberSundays = [];
  let weeksDaysAndValue = {};
  monthArr.forEach((d) => {
    if (moment(d, "YYYY-MM-DD").format("dddd") === "Sunday") {
      weekNumberSundays.push(d);
    }
  });
  weekNumberSundays.forEach((data) => {
    let currentDate = moment(data, "YYYY-MM-DD");
    let weekStart = currentDate.clone().startOf("week");
    var days = [];
    for (let i = 6; i >= 0; i--) {
      days.push(moment(weekStart).subtract(i, "days").format("YYYY-MM-DD"));
    }
    weeksDaysAndValue[data] = { days, value: 0 };
  });
  statistics.forEach((stat) => {
    let currentDate = stat.date;
    let workedTimeSS = strConvertToSS(stat.tracked_time);
    let manualTimeSS = strConvertToSS(stat.manual_time);
    weekNumberSundays.forEach((sanDay) => {
      if (weeksDaysAndValue[sanDay].days.includes(currentDate)) {
        weeksDaysAndValue[sanDay].value =
          weeksDaysAndValue[sanDay].value + workedTimeSS + manualTimeSS;
      }
    });
  });
  return weeksDaysAndValue;
};

export const iconUrl = (url) =>
  url.includes("base64") ? url : `${BACKEND_URL}/media/${url}`;

export const withPromise = (f, delay) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(f);
    }, delay);
  });

export const getMilliseconds = (time) => ((time % 60000) / 1000).toFixed(0);

export const blobToFile = (theBlob, fileName, id) => {
  theBlob.lastModified = Date.now();
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  theBlob.id = id;
  return theBlob;
};

export const useQueryParams = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

export const useClickOutside = (ref, callback) => {
  React.useEffect(() => {
    window.addEventListener("mousedown", handleClick);
    return () => {
      window.removeEventListener("mousedown", handleClick);
    };
  });

  const handleClick = React.useCallback((e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  });
};

export const saveFile = (file, name) => {
  saveAs(`${BACKEND_URL}/media/${file}`, name);
};

export const getInternalToken = () => {
  const data = localStorage.getItem("token") || sessionStorage.getItem("token");
  const token = (data && JSON.parse(data).token) || "";
  return token;
};

export const useDebounce = (value, delay) => {
  const [debauncedValue, setValue] = useState(value);

  useEffect(() => {
    const handle = setTimeout(() => {
      setValue(value);
    }, delay);

    return () => {
      clearTimeout(handle);
    };
  }, [value, delay]);

  return debauncedValue;
};

export const toggleValue = (changeValue, value, toInclude) => {
  if (toInclude) {
    changeValue((prev) => prev.filter((a) => a !== value));
  } else {
    changeValue((prev) =>
      prev.includes(value) ? prev.filter((a) => a !== value) : [...prev, value],
    );
  }
};

export const formatName = (item) => {
  const noNameStr = `No name ${item?.id || ""}`;
  return item
    ? item?.firstName && item?.lastName
      ? `${item.firstName} ${item.lastName}`
      : item?.email || item?.firstName || item?.lastName || noNameStr
    : noNameStr;
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const fileToBase64 = async (fileUrl) => {
  const response = await fetch(fileUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
