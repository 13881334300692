import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HttpLink } from "apollo-link-http";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "react-apollo";
import { setContext } from "apollo-link-context";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { onError } from "apollo-link-error";
import swal from "sweetalert";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import i18n from "./i18n";
import { getInternalToken } from "utils";

const theme = createTheme({
  typography: {
    fontFamily: `"Lato", sans-serif, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    -apple-system, BlinkMacSystemFont`,
  },
});

const cache = new InMemoryCache({
  dataIdFromObject: (o) => `${o.__typename}-${o.id}`,
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${getInternalToken()}`,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const noPerm = "You do not have permission to perform this action";
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      if (path?.toString() === "me" && message === noPerm) return message;
      swal("Oh noes!", message, "error");
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      return message;
    });
  if (networkError) {
    console.log("networkError", networkError);
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: cache.restore(window.__APOLLO_STATE__ || {}),
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </ApolloProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
