import { Field, Formik } from "formik";
import { Box, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import React from "react";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  field: { width: "258px", marginBottom: "14px" },
  label: {
    color: "#333",
  },
  saveButton: {
    width: "75px",
    textTransform: "uppercase",
    borderColor: "#594BF6",
    backgroundColor: "#594BF6",
    color: "white",
    "&:hover": {
      backgroundColor: "#4C3FE1",
    },
  },
  cancelButton: {
    margin: "0 14px 0 20px",
    width: "75px",
    textTransform: "uppercase",
    backgroundColor: "#6C757D",
    borderColor: "#6C757D",
    color: "white",
    "&:hover": {
      backgroundColor: "#686e75",
    },
  },
}));
const CreateOrEditShortcuts = (props) => {
  const classes = useStyles();
  return (
    <Formik
      onSubmit={(values) => {
        if (props?.values) {
          props?.handleUpdateShortCuts(values, props?.values?.id);
        } else {
          props.handleCreateShortCuts(values);
        }
        props.handleCloseShortCuts();
      }}
      initialValues={
        props?.values || {
          name: null,
          link: null,
        }
      }
    >
      {({ values, setFieldValue, errors, touched, submitForm }) => (
        <Box
          style={{
            width: "100%",
            padding: "20px 16px",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <Box style={{ width: "auto", marginBottom: "14px" }}>
            <InputLabel id="link" className={classes.label}>
              Enter link
            </InputLabel>
            <Field
              name="link"
              id="link"
              type="text"
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              value={values.link}
              onChange={(e) => setFieldValue("link", e.target.value)}
              component={TextField}
            />
          </Box>
          <Box className={classes.field}>
            <InputLabel id="name" className={classes.label}>
              Enter name
            </InputLabel>
            <Field
              name="name"
              id="name"
              type="text"
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              value={values.name}
              onChange={(e) => setFieldValue("name", e.target.value)}
              component={TextField}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className={classes.cancelButton}
              onClick={props?.handleCloseShortCuts}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={submitForm}
              className={classes.saveButton}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default CreateOrEditShortcuts;
