import { Box, Card } from "@material-ui/core";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import ActivityIcon from "components/Activity/ActivityIcon";
import { ACTIVITIES_NAMES, HOURS } from "../../constants";
import moment from "moment";
import React, { memo, useEffect, useRef, useState } from "react";
import clsx from "clsx";

const useStyles = makeStyles({
  wrapper: {
    borderTop: "1px solid #D9D9D9",
    borderLeft: "1px solid #D9D9D9",
    borderRadius: 0,
    padding: "10px 10px",
    boxShadow: "none",
    padding: "0",
  },
  datePicker: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "14px 15px",
    fontSize: "16px",
    borderBottom: "1px solid #D9D9D9",
    color: "#979797",
    lineHeight: "19.2px",
  },
  timeScheduleWrapper: {
    color: "#979797",
    display: "flex",
    position: "relative",
    padding: "0 3px",
  },
  hoursColumn: {
    height: "100%",
    whiteSpace: "nowrap",
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontSize: "12px",
    borderRight: "1px solid #D9D9D9;",
    paddingRight: "3px",
  },
  hour: {},
  scheduleColumn: { width: "100%", marginTop: "7px" },
  scheduleRow: {
    borderTop: "1px solid #D9D9D9;",
    display: "grid",
    gridTemplateRows: "1fr ".repeat(60),
  },
  currentTime: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    zIndex: 999,
    right: 0,
    width: "100%",
    height: "15px",
  },
  currentTimeLine: {
    background: "red",
    width: "100%",
    height: "1px",
  },
  currentTimeTypography: {
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "flex-end",
    width: "50px",
    paddingRight: "2px",
    color: "red",
    fontSize: "9px",
  },
  staticActivitiesWrapper: {
    display: "flex",
    borderBottom: "1px solid #D9D9D9;",
  },
  staticActivitiesMargin: {
    minWidth: "42px",
    maxWidth: "42px",
    borderRight: "1px solid #D9D9D9;",
  },
  staticActivities: {
    padding: "9px 35px 9px 14px",
    width: "100%",
  },
  activityItem: {
    paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    color: "white",
    fontSize: "12px",
    lineHeight: "14.4px",
    width: "100%",
    height: "24px",
    backgroundColor: "#979797",
    borderRadius: "5px",
  },
  newActivityItem: {
    border: "1px dashed rgb(47, 128, 237)",
    backgroundColor: "#f0faff",
    color: "#2F80ED",
  },
  doneActivityItem: {
    backgroundColor: "#43995c",
  },
  missedActivityItem: {
    backgroundColor: "#b34748",
  },
});

const HorizontalTimeBar = memo((props) => {
  const classes = useStyles();
  const timeBarRef = useRef(null);
  const timeBarWrapperRef = useRef(null);
  const staticBarRef = useRef(null);
  const [staticBarHeight, setStaticBarHeight] = useState(0);
  const [timeBarHeight, setTimeBarHeight] = useState(0);

  const [currentTime, setCurrentTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedDateFormated, setSelectedDateFormated] = useState(
    moment().format("dddd, MMMM Do"),
  );
  const [timeLinePosition, setTimeLinePosition] = useState(0);
  const [timeLineTypographyVisible, setTimeLineTypographyVisible] =
    useState(false);

  const [staticActivities, setStaticActivities] = useState([]);
  const [temporaryActivities, setTemporaryActivities] = useState([]);
  const [newActivity, setNewActivity] = useState(null);

  const getTimeLinePosition = () => {
    if (!timeBarRef.current) return;
    const timeBarHeight =
      timeBarRef.current?.getBoundingClientRect()?.height - 18;
    setTimeBarHeight(timeBarHeight);
    const currentTime = moment();
    const minutesPassed = currentTime.diff(moment().startOf("day"), "minutes");
    const minutesCountInOneDay = 1440;

    const timeBarHeightCoefficient = timeBarHeight / minutesCountInOneDay;
    setCurrentTime(currentTime);
    setTimeLinePosition(minutesPassed * timeBarHeightCoefficient);
  };

  const checkIsActivityCurrent = (startDateStr, endDateStr, currentDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const currentDate = new Date(
      currentDateStr || selectedDate.format("YYYY-MM-DD"),
    );

    return startDate <= currentDate && endDate >= currentDate;
  };

  const checkIsActivityStatic = (startTime, endTime, value) => {
    return startTime === value && endTime === value;
  };

  const checkNewActivity = (activity) => {
    const isActivityCurrent = checkIsActivityCurrent(
      activity?.startDate,
      activity?.endDate,
    );
    const isActivityStatic = checkIsActivityStatic(
      activity?.startTime?.value,
      activity?.endTime?.value,
      "00:00",
    );
    return isActivityCurrent && isActivityStatic;
  };

  const checkIsActivityInRange = (
    activity,
    startTimeNumber,
    endTimeNumber,
    currentHour,
    currentDate,
  ) => {
    const currentDateStr = currentDate || selectedDate.format("YYYY-MM-DD");
    return (
      (startTimeNumber <= currentHour && endTimeNumber >= currentHour) ||
      (activity.endDate !== currentDateStr && startTimeNumber <= currentHour) ||
      (activity.startDate !== currentDateStr && endTimeNumber >= currentHour)
    );
  };

  const distributeActivities = () => {
    let activities = props.activities || [];
    let staticActivities = [];
    let temporaryActivities = [];

    const currentDateStr = selectedDate.format("YYYY-MM-DD");

    activities = activities?.filter((activity) =>
      checkIsActivityCurrent(
        activity?.startDate,
        activity?.endDate,
        currentDateStr,
      ),
    );

    activities = activities.map((activity) =>
      moment(
        `${activity.endDate} ${activity.endTime}`,
        "YYYY-MM-DD HH:mm:ss",
      ).isBefore(currentTime)
        ? { ...activity, missed: true }
        : activity,
    );

    for (const activity of activities) {
      if (
        activity.startTime === "00:00:00" &&
        activity.endTime === "00:00:00"
      ) {
        staticActivities.push(activity);
      } else {
        temporaryActivities.push(activity);
      }
    }

    setTemporaryActivities(temporaryActivities);
    setStaticActivities(staticActivities);
  };

  useEffect(() => {
    if (!timeBarRef.current) return;
    getTimeLinePosition();
    setStaticBarHeight(staticBarRef?.current?.getBoundingClientRect()?.height);
    const intervalId = setInterval(() => {
      getTimeLinePosition();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    distributeActivities();
  }, [props.activities, selectedDateFormated]);

  useEffect(() => {
    setStaticBarHeight(staticBarRef?.current?.getBoundingClientRect()?.height);
  }, [selectedDateFormated, staticActivities]);

  useEffect(() => {
    if (!props.values.startDate) return;
    const startDate = moment(props.values.startDate);
    setSelectedDate(startDate);
    setSelectedDateFormated(startDate.format("dddd, MMMM Do"));

    setNewActivity(props.values);

    if (!timeBarWrapperRef.current && !props.values.startTime) return;

    if (
      checkIsActivityStatic(
        props.values?.startTime?.value,
        props.values?.endTime?.value,
        "00:00",
      )
    ) {
      return;
    }

    const startTimeNumber = parseInt(
      props.values.startTime.value.split(":")[0],
      10,
    );
    timeBarWrapperRef.current.scrollTop =
      (timeBarHeight / 24) * startTimeNumber;
  }, [props.values]);

  const timeLineTypographyVisibleToggle = (value) => (e) => {
    setTimeLineTypographyVisible(value);
  };

  const selectedDateToggle = (value) => (e) => {
    const newSelectedDate = value
      ? selectedDate.add(1, "day")
      : selectedDate.subtract(1, "day");
    setSelectedDate(newSelectedDate);
    setSelectedDateFormated(newSelectedDate.format("dddd, MMMM Do"));
  };

  const getTemporaryActivity = (hour, activities) => {
    const currentHour =
      hour === "12 AM" ? 24 : +moment(hour, "h A").format("HH");
    const currentDate = selectedDate.format("YYYY-MM-DD");

    let activity = activities.find((activity) => {
      const startTimeNumber = parseInt(activity.startTime.split(":")[0], 10);
      const endTimeNumber = parseInt(activity.endTime.split(":")[0], 10);

      return checkIsActivityInRange(
        activity,
        startTimeNumber,
        endTimeNumber,
        currentHour,
        currentDate,
      );
    });

    let isNewActivity = false;

    if (
      !activity &&
      (!newActivity ||
        checkIsActivityStatic(
          newActivity?.startTime?.value,
          newActivity?.endTime?.value,
          "00:00",
        ) ||
        !checkIsActivityCurrent(newActivity?.startDate, newActivity?.endDate))
    )
      return null;

    if (!activity) {
      activity = {
        ...newActivity,
        startTime: newActivity.startTime.value,
        endTime: newActivity.endTime.value,
      };
      isNewActivity = true;

      const startTimeNumber = parseInt(activity.startTime.split(":")[0], 10);
      const endTimeNumber = parseInt(activity.endTime.split(":")[0], 10);
      if (
        !checkIsActivityInRange(
          activity,
          startTimeNumber,
          endTimeNumber,
          currentHour,
          currentDate,
        )
      ) {
        return;
      }
    }

    const endHour = parseInt(activity.endTime.split(":")[0], 10);
    const startHour = parseInt(activity.startTime.split(":")[0], 10);

    const isCurrentHourLast = endHour === currentHour;
    const isCurrentHourFirst = startHour === currentHour;
    const isCurrentDateLast = activity.endDate === currentDate;
    const isCurrentDateFirst = activity.startDate === currentDate;

    const startMinutes =
      !isCurrentDateFirst || !isCurrentHourFirst
        ? 1
        : parseInt(activity.startTime.split(":")[1], 10);

    const endMinutes =
      !isCurrentHourLast || !isCurrentDateLast
        ? 60
        : parseInt(activity.endTime.split(":")[1], 10);

    const borderTop = isCurrentHourFirst && isCurrentDateFirst ? "5px" : 0;
    const borderBottom = isCurrentHourLast && isCurrentDateLast ? "5px" : 0;

    return (
      <ActivityItem
        isCreating={isNewActivity}
        hideContent={
          !isCurrentDateFirst ? currentHour !== 0 : !isCurrentHourFirst
        }
        style={{
          gridArea: `${startMinutes || 1} / 1 / span ${endMinutes} / span 1`,
          height: "100%",
          borderRadius: `${borderTop} ${borderTop} ${borderBottom} ${borderBottom}`,
          ...(!isCurrentHourFirst || !isCurrentDateFirst
            ? { borderTop: "0px" }
            : {}),
          ...(!isCurrentHourLast || !isCurrentDateLast
            ? { borderBottom: "0px" }
            : {}),
        }}
        activity={activity}
      />
    );
  };

  const ActivityItem = ({ activity, style, hideContent, isCreating }) => {
    if (!activity) return null;
    return (
      <div
        style={style}
        className={clsx(
          isCreating
            ? classes.newActivityItem
            : activity?.done
              ? classes.doneActivityItem
              : activity?.missed
                ? classes.missedActivityItem
                : null,
          classes.activityItem,
        )}
      >
        {!hideContent && (
          <>
            <ActivityIcon
              style={{ marginRight: "10px" }}
              fontSize="small"
              activityType={activity?.activityType}
            />
            {activity?.title || ACTIVITIES_NAMES[activity?.activityType]}
          </>
        )}
      </div>
    );
  };

  return (
    <Card style={{ height: props.height + "px" }} className={classes.wrapper}>
      <Box ref={staticBarRef}>
        <Box className={classes.datePicker}>
          <Box style={{ marginRight: "6px" }}>{selectedDateFormated}</Box>
          <Box className="text-nowrap">
            <ArrowBackIos
              onClick={selectedDateToggle(false)}
              style={{ cursor: "pointer" }}
              fontSize="small"
            />
            <ArrowForwardIos
              onClick={selectedDateToggle(true)}
              style={{ cursor: "pointer" }}
              fontSize="small"
            />
          </Box>
        </Box>
        {(checkNewActivity(newActivity) || staticActivities.length > 0) && (
          <Box className={classes.staticActivitiesWrapper}>
            <Box className={classes.staticActivitiesMargin}></Box>
            <Box className={classes.staticActivities}>
              {staticActivities.map((activity, index) => (
                <ActivityItem
                  style={{ marginTop: index ? "4.5px" : "0px" }}
                  activity={activity}
                  key={activity.id}
                />
              ))}
              {checkNewActivity(newActivity) && (
                <ActivityItem
                  activity={newActivity}
                  isCreating
                  style={{
                    marginTop: staticActivities.length > 0 ? "4.5px" : "0px",
                  }}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box
        ref={timeBarWrapperRef}
        style={{
          height: props.height - staticBarHeight + "px",
          overflow: props.isOpen ? "auto" : "hidden",
        }}
      >
        <Box ref={timeBarRef} className={classes.timeScheduleWrapper}>
          <Box
            onMouseMove={timeLineTypographyVisibleToggle(true)}
            onMouseEnter={timeLineTypographyVisibleToggle(true)}
            onMouseLeave={timeLineTypographyVisibleToggle(false)}
            className={classes.currentTime}
            style={{
              top: `${timeLinePosition + 1}px`,
            }}
          >
            <div
              className={classes.currentTimeTypography}
              style={{ opacity: timeLineTypographyVisible ? 1 : 0 }}
            >
              {currentTime?.format("h:mm A")}
            </div>
            <div className={classes.currentTimeLine} />
          </Box>
          <Box className={classes.hoursColumn}>
            {HOURS.map((hour, index) => (
              <div
                key={index}
                className={classes.hour}
                style={{
                  height: index === HOURS.length - 1 ? "fit-content" : "45px",
                }}
              >
                {hour}
              </div>
            ))}
          </Box>
          <Box className={classes.scheduleColumn}>
            {HOURS.map((hour, index) => (
              <div
                key={index}
                className={classes.scheduleRow}
                style={{
                  height: index === HOURS.length - 1 ? "fit-content" : "45px",
                }}
              >
                {getTemporaryActivity(hour, temporaryActivities)}
              </div>
            ))}
          </Box>
        </Box>
      </Box>
    </Card>
  );
});

export default HorizontalTimeBar;
