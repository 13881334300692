import React from "react";
import Modal from "./Modal";
import ManageEmailTemplateForm from "../Forms/ManageEmailTemplateForm";

const ModalManageEmailTemplate = ({ isOpen, onClose, onSubmit, type }) => {
  return (
    <Modal open={isOpen} handleClose={onClose} title="Manage email template">
      <ManageEmailTemplateForm
        type={type}
        submitForm={onSubmit}
        closeModal={onClose}
        isOpen={isOpen}
      />
    </Modal>
  );
};

export default ModalManageEmailTemplate;
