import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useApolloClient } from "react-apollo";
import Detail from "./Detail";
import Header from "./Header";
import {
  allManagers,
  getContactPersons,
  getLeadInfo,
  getLeadLabels,
} from "api/queries";
import {
  createContactPerson,
  createLabelLead,
  updateLead,
} from "api/mutations";
import ActionsTab from "./AcitonsTab";
import { formatName } from "utils";

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "0 20px",
    backgroundColor: "rgba(0,0,0,0)",
  },
}));

const LeadPage = () => {
  const classes = useStyles();

  const apolloClient = useApolloClient();
  const { id: leadId } = useParams();
  const [stage, setStage] = useState(1);
  const [lead, setLead] = useState(null);
  const [isArchive, setIsArchive] = useState(null);

  const [contacts, setContacts] = useState([]);
  const [labels, setLabels] = useState([]);
  const [owners, setOwners] = useState([]);
  const [owner, setOwner] = useState(null);

  const detailRef = useRef(null);
  const [actionsTabsMaxHeight, setActionsTabsMaxHeight] = useState(100);

  const updateLeadHandle = async (rows) => {
    await apolloClient.mutate({
      mutation: updateLead,
      variables: {
        leadId,
        ...rows,
        archived: "archived" in rows ? rows.archived : isArchive,
      },
    });
  };

  const changeStage = (e) => {
    setStage(e);
    updateLeadHandle({ listId: e });
  };

  const changeOwnerHandler = async (e) => {
    setOwner(e.manager);
    await updateLeadHandle({ ownerId: e.manager.value });
    fetchLeadInfo();
  };

  const changeIsArchivedHandler = (e) => () => {
    setIsArchive(e);
    updateLeadHandle({ archived: e });
  };

  const fetchContacts = async () => {
    const response = await apolloClient.query({
      query: getContactPersons,
      fetchPolicy: "no-cache",
    });
    setContacts(response.data.contactPersons);
  };

  const fetchLabels = async () => {
    const response = await apolloClient.query({
      query: getLeadLabels,
      fetchPolicy: "no-cache",
    });
    setLabels(response.data.leadSelector.labels);
  };

  const fetchLeadInfo = async () => {
    const response = await apolloClient.query({
      query: getLeadInfo,
      fetchPolicy: "no-cache",
      variables: {
        leadId,
      },
    });
    const data = response.data.lead;
    setLead(data);
    setStage(data.item.list.id);
    setIsArchive(data.archived);
    setOwner(
      data.owner && {
        value: data.owner.id,
        text: formatName(data.owner),
      }
    );
  };

  const fetchOwners = async () => {
    const response = await apolloClient.query({
      query: allManagers,
    });
    const data = response.data.allManagers
      .filter((el) => el.firstName || el.lastName)
      .map((el) => ({
        value: el.id,
        text: formatName(el),
      }));
    setOwners(data);
  };

  const createContact = async (value) => {
    const name = value?.split(" ");

    const response = await apolloClient.mutate({
      mutation: createContactPerson,
      variables: {
        firstName: name[0] || "",
        lastName: name[1] || "",
      },
    });
    fetchContacts();
    return response.data.createContactPerson.contact;
  };

  const createLabel = async (value) => {
    const response = await apolloClient.mutate({
      mutation: createLabelLead,
      variables: {
        name: value,
      },
    });
    fetchLabels();
    return response.data.createLabelLead.label;
  };

  const onSubmitDetail = async (data) => {
    if (data?.contactPersons && lead?.contactPersons) {
      const leadContacts = lead.contactPersons.map((a) => a.id);
      const contactPersons = data.contactPersons.filter((a) => !!a);
      const newContacts = contactPersons.map((a) => a?.value);
      data.removeContacts = leadContacts.filter(
        (number) => !newContacts.includes(number)
      );
      data.newContacts = newContacts.filter(
        (number) => !leadContacts.includes(number)
      );
      if (data?.contactPersons?.length) {
        data.primaryContactId = contactPersons[0]?.value;
      }
    }

    if (data?.label) data.labelId = data.label?.value;
    if (data?.source) data.source = data?.source.value;
    if (data?.industry) data.industry = data?.industry.value;
    if (data?.value) data.value = parseFloat(data.value);
    if (data?.currency) data.currency = data.currency.value;
    if (typeof data?.value === "number") data.value = parseFloat(data.value);
    if (typeof data?.value === "string") data.value = parseFloat(0);

    await updateLeadHandle(data);
    fetchLeadInfo();
  };

  useEffect(() => {
    fetchLeadInfo();
    fetchOwners();
    fetchContacts();
    fetchLabels();
  }, [leadId]);

  useEffect(() => {
    const maxHeight = detailRef.current?.getBoundingClientRect()?.height;
    if (!maxHeight) return;
    setActionsTabsMaxHeight(maxHeight);
  }, [detailRef.current]);

  if (!lead) return "Loading...";

  return (
    <Container fluid className={classes.wrapper}>
      <Header
        leadName={formatName(lead?.primaryContact)}
        owners={owners}
        leadId={leadId}
        createLead={false}
        manager
        select
        owner={owner}
        ownerChangeHandler={changeOwnerHandler}
        stager
        stage={stage}
        changeStage={changeStage}
        archive
        button
        isArchive={isArchive}
        lead={lead}
        changeIsArchivedHandler={changeIsArchivedHandler}
      />
      <Row style={{ minHeight: "633px" }}>
        <Col style={{ padding: 0 }} md={4}>
          <Detail
            ref={detailRef}
            lead={lead}
            contacts={contacts}
            labels={labels}
            onSubmit={onSubmitDetail}
            createContact={createContact}
            createLabel={createLabel}
          />
        </Col>
        <Col style={{ padding: 0 }} md={8}>
          <ActionsTab
            maxHeight={actionsTabsMaxHeight}
            leadId={leadId}
            lead={lead}
            owner={owner}
            owners={owners}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LeadPage;
