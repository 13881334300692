import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBE-5cUtlWWdXoEYna6KGRM_lGFTT30G8s",
  authDomain: "fir-tuc24.firebaseapp.com",
  projectId: "fir-tuc24",
  storageBucket: "fir-tuc24.appspot.com",
  messagingSenderId: "658984409517",
  appId: "1:658984409517:web:80384b54bc02be6969e924",
};

export const firebaseApp = initializeApp(firebaseConfig);
