import React, { useState } from "react";
import { Box } from "@material-ui/core";
import SpaceDetailsTab from "./SpaceSettingsTabs/SpaceDetailsTab";
import ArchiveSpaceTab from "./SpaceSettingsTabs/ArchiveSpaceTab";
import DeleteSpaceTab from "./SpaceSettingsTabs/DeleteSpaceTab";
import { makeStyles } from "@material-ui/styles";
import { deleteSpace, updateSpace } from "api/mutations";
import { useApolloClient } from "react-apollo";
import * as path from "constants/routes";
import { useHistory } from "react-router";
import { fileToBase64 } from "utils";
import { BACKEND_URL } from "constants/index";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    display: "flex",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "3px",
      backgroundColor: "#828282",
    },
  },
  buttons: {
    padding: "10px",
    fontSize: "22px",
    position: "relative",
    cursor: "pointer",
    transition: "color 0.5s ease",
    fontFamily: "Lato",
    fontWeight: "400",
    lineHeight: "19.2px",
    height: "40px",
  },
  selectedButton: {
    color: "#136AE4",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "3px",
      backgroundColor: "#136AE4",
      transition: "width 0.5s ease",
    },
  },
}));
const SpaceSettings = ({ space, user, refetch }) => {
  const [selectedSettingTab, setSelectedSettingTab] = useState(0);
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const history = useHistory();
  const submitArchiveSpace = async () => {
    await apolloClient.mutate({
      mutation: updateSpace,
      variables: {
        spaceId: space?.id,
        isArchived: !space?.isArchived,
      },
    });
    refetch();
  };
  const submitDeleteSpace = () => {
    apolloClient.mutate({
      mutation: deleteSpace,
      variables: {
        spaceId: space?.id,
      },
    });
    history.push(path.DOCUMENTS);
  };
  const handleSubmitForm = async (values) => {
    let logo;
    if (values?.logo) {
      if (values?.logo?.id) {
        logo = await fileToBase64(`${BACKEND_URL}/media/${values?.logo?.logo}`);
      } else {
        logo = await fileToBase64(values?.logo?.logo);
      }
      values.logo = logo;
    }
    if (values?.isArchived) {
      values.isArchived = values?.isArchived?.value;
    }
    if (values?.typePrivate || values?.typeAccessSettings) {
      values.accessType = values.typePrivate ? 1 : 2;
      values.accessUsers = values?.accessUsers?.map((el) => Number(el?.value));
    }
    await apolloClient.mutate({
      mutation: updateSpace,
      variables: {
        ...values,
        spaceId: space?.id,
      },
    });
    refetch();
  };
  const settingsTabs = [
    {
      id: 0,
      name: "Space details",
      component: (
        <SpaceDetailsTab
          user={user}
          space={space}
          handleSubmitForm={handleSubmitForm}
        />
      ),
    },
    {
      id: 1,
      name: "Archive space",
      component: (
        <ArchiveSpaceTab
          submitArchiveSpace={submitArchiveSpace}
          space={space}
        />
      ),
    },
    {
      id: 2,
      name: "Delete space",
      component: (
        <DeleteSpaceTab
          submitArchiveSpace={submitArchiveSpace}
          submitDeleteSpace={submitDeleteSpace}
          space={space}
        />
      ),
    },
  ];

  return (
    <Box>
      <Box
        style={{
          marginTop: "8px",
        }}
      >
        SPACE SETTINGS
        <Box>
          <Box display={"flex"} className={classes.container}>
            {settingsTabs.map((item) => (
              <Box
                onClick={() => setSelectedSettingTab(item.id)}
                className={
                  item.id === selectedSettingTab
                    ? `${classes.buttons} ${classes.selectedButton}`
                    : classes.buttons
                }
              >
                {item.name}
              </Box>
            ))}
          </Box>
          <Box>{settingsTabs[selectedSettingTab]?.component}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SpaceSettings;
