import React from "react";
import MaterialButton from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    buttonHeight: {
        height: "40px"
    }
}))

const Button = (props) => {
    const classes = useStyles();

    return <MaterialButton className={classes.buttonHeight} {...props} />
}

export default Button