import React, { useState, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  error: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #EB5757 !important",
    },
  },
}));

const SelectAutocomplete = ({
  value,
  setValue,
  options,
  placeholder,
  ...props
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangeInput = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onChangeInput}
      getOptionLabel={(option) => option.text || option}
      // getOptionSelected={(option) => option.id || option}
      id={`SelectAutocomplete-${placeholder}`}
      options={options}
      renderOption={(option) => (
        <Typography style={props?.labelStyle || {}}>{option.text}</Typography>
      )}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            variant={props?.variant ? props?.variant : "outlined"}
            size="small"
            error={props?.error}
            className={props.error ? classes.error : ""}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              ...props?.InputProps,
              style: {
                ...props?.inputStyle,
              },
            }}
          />
        </>
      )}
      style={{ minWidth: 150, ...props.style }}
      {...props}
    />
  );
};

export default memo(SelectAutocomplete);
