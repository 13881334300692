import Modal from "./Modal";
import React from "react";
import CreateActivityForm from "../Forms/CreateActivityForm";
import * as Yup from "yup";
import { formatName } from "../../utils";
import Box from "@material-ui/core/Box";
import { Formik } from "formik";
import moment from "moment/moment";

const CreateActivityModal = ({
  isOpen,
  onClose,
  onSubmit,
  managers,
  manager,
  type,
}) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Task name is required"),
    manager: Yup.object().required("Manager is required").nullable(),
  });
  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      title={`Shedule ${type} activity.`}
    >
      <Formik
        initialValues={{
          title: "Call",
          activityType: 1,
          startDate: moment().format("YYYY-MM-DD"),
          startTime: { value: "00:00", text: "h.mm PM" },
          endDate: moment().format("YYYY-MM-DD"),
          endTime: { value: "00:00", text: "h.mm PM" },
          note: "",
          manager: manager,
          done: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onClose();
          onSubmit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, submitForm, setFieldValue, errors, touched }) => (
          <Box style={{ display: "flex" }}>
            <Box
              style={{
                width: "100%",
                minWidth: "655px",
                margin: "10px",
              }}
            >
              {" "}
              <CreateActivityForm
                ModalButtons={true}
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                managers={managers}
                resetForm={onClose}
                submitForm={submitForm}
                border={"none"}
              />
            </Box>
          </Box>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateActivityModal;
