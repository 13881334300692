import React from "react";
import Modal from "./Modal";
import UpdateSpaceForm from "../Forms/UpdateSpaceForm";
const SpaceImageChangeModal = ({ isOpen, onClose, handleSubmit, space }) => {
  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      title="Editing a space name and icon"
    >
      {" "}
      <UpdateSpaceForm
        onClose={onClose}
        handleSubmit={handleSubmit}
        space={space}
      />
    </Modal>
  );
};
export default SpaceImageChangeModal;
