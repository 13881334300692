import { styled } from "@material-ui/core";
import React from "react";

import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// https://jpuri.github.io/react-draft-wysiwyg/#/demo
import "./style.css";

const TextEditor = ({ changeEditor, editorState, ...props }) => {
  return (
    <>
      <Editor
        editorState={editorState}
        {...props}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        editorStyle={
          props.editorStyle ?? {
            border: "1px solid #f1f1f1",
            padding: "0px 10px",
            minHeight: props.minHeight ?? "200px",
            overflow: "hidden",
          }
        }
        onEditorStateChange={changeEditor}
      />
      {/* <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
    </>
  );
};

export default TextEditor;
