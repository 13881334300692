import React from "react";
import { Formik } from "formik";
import { EditorState } from "draft-js";
import CreateEmailForm from "components/Forms/CreateEmailForm";
import * as Yup from "yup";

const Email = ({ onSubmit, emails, type }) => {
  const submitHandler = (values, resetForm) => {
    onSubmit(values);
    resetForm();
  };

  const validationSchema = Yup.object().shape({
    letter: Yup.mixed().required("Letter is required"),
    emails: Yup.array()
      .of(Yup.object().required("Email is required"))
      .min(1, "At least one email is required"),
    subject: Yup.string().required("Task name is required"),
  });

  return (
    <Formik
      initialValues={{
        letter: EditorState.createEmpty(),
        emails: [],
        subject: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => submitHandler(values, resetForm)}>
      {({ values, setFieldValue, resetForm, errors, touched }) => (
        <CreateEmailForm
          type={type}
          values={values}
          errors={errors}
          touched={touched}
          onSubmit={onSubmit}
          setFieldValue={setFieldValue}
          emails={emails}
          resetForm={resetForm}
        />
      )}
    </Formik>
  );
};

export default Email;
