import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { URL_INSTALL_TRACKER } from '../../constants/index';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    footer: {
        bottom: 0,
        backgroundColor: '#594bf6',
        position: 'absolute',
        width: '100%',
        paddingLeft: '270px',
        textAlign: 'center',
        [theme.breakpoints.down(1200)]: {
            paddingLeft: 0,
        },
    },
    redirectButton: {
        color: '#ffffff',
        borderColor: '#ffffff',
        '&:hover': {
            borderColor: '#ffffff',
            backgroundColor: '#4C3FE1',
        },
    },
    wrappButton: {
        padding: '20px',
    },
    copyright: {
        color: '#a7a6ab',
        padding: '0 16px 16px',
    },
}));

const Footer = () => {
    const classes = useStyles();
    const click = () => {
        window.open(URL_INSTALL_TRACKER, 'TUC24');
    };
    return (
        <Box component='footer' className={classes.footer}>
            <Box className={classes.wrappButton}>
                <Button
                    onClick={click}
                    variant='outlined'
                    color='primary'
                    classes={{ outlinedPrimary: classes.redirectButton }}
                >
                    Tracker App
                </Button>
            </Box>
            <Box className={classes.copyright}>
                <Typography>
                    &copy; {new Date().getFullYear()} Copyright:{' '}
                    <span style={{ color: '#ffffff' }}>tuc24.com</span>
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer;
