import React from "react";
import HeaderWithoutInstall from "../../components/NavBar/HeaderWithoutInstall";
import FooterWithoutInstall from "../../components/Footer/FooterWithoutInstall";
import InvitationSignUpForm from "../../components/Forms/InvitationSignUpForm/InvitationSignUpForm";
import "./stylesInvitationSignUp.css";

const InvitationSignUp = (props) => {
  return (
    <div className="invitation-sign-up-wrapper">
      <HeaderWithoutInstall />
      <div className="invitation-sign-up-main-content">
        <InvitationSignUpForm />
      </div>
      <div className="invitation-sign-up-footer">
        <FooterWithoutInstall />
      </div>
    </div>
  );
};

export default InvitationSignUp;
