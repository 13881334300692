import React, { useRef } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { FileDrop } from 'react-file-drop';
import './styles.css';

export const useStyles = makeStyles(theme => ({
    inputFile: {
        display: 'none',
    },
    uploadFile: {
        width: '100%',
        height: '130px',
        borderRadius: '5px',
        border: '1px dashed #BDBDBD',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '15px',
    },
    uploadIcon: {
        color: '#4284F5',
        width: '48px',
        height: '32px',
    },
    uploadTitle: {
        fontSize: '18px',
        fontWeight: 500,
        color: '#333333',
    },
    uploadSubtitle: {
        fontSize: '12px',
        fontWeight: 400,
        color: '#828282',
    },
    fileDrop: {
        width: '100%',
        minHeight: '130px',
        height: '100%',
        borderRadius: '5px',
        border: '1px dashed #BDBDBD',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '15px',
        cursor: 'pointer',
    },
}));

const DragAndDropFile = ({ name, setFieldValue, value, handler, accept }) => {
    const fileInputRef = useRef(null);
    const onFileInputChange = event => {
        const { files } = event.target;
        // do something with your files...
        handler(value, name, setFieldValue, files);
    };
    const onTargetClick = () => {
        fileInputRef.current.click();
    };
    const classes = useStyles();

    return (
        <>
            <input
                onChange={onFileInputChange}
                ref={fileInputRef}
                type='file'
                className={classes.inputFile}
                accept={accept}
            />
            <div className={classes.fileDrop}>
                <FileDrop
                    onDrop={(files, event) => {
                        handler(value, name, setFieldValue, files);
                    }}
                    onTargetClick={onTargetClick}
                >
                    <CloudUploadIcon className={classes.uploadIcon} />
                    <Typography component='h6' className={classes.uploadTitle}>
                        Drag and drop your files here
                    </Typography>
                    <Typography component='h6' className={classes.uploadSubtitle}>
                        jpg, png, pdf, txt
                    </Typography>
                </FileDrop>
            </div>
        </>
    );
};

export default DragAndDropFile;
