import React, { useEffect, useState } from "react";
import { Box, createTheme, TextField, Tooltip } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { BACKEND_URL } from "constants/index";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Collapse from "@material-ui/core/Collapse";
import AddIcon from "@material-ui/icons/Add";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import AppsIcon from "@material-ui/icons/Apps";
import LinkIcon from "@material-ui/icons/Link";
import { getBase64 } from "utils";
import { useApolloClient } from "react-apollo";
import {
  copyContentSpace,
  createContentFileSpace,
  createShortCutsSpace,
  createWhiteBoard,
  deleteContentFile,
  deleteShortcut,
  deleteWhiteboard,
  updateContentFileSpace,
  updateShortcut,
  updateWhiteBoard,
} from "api/mutations";
import CreateOrEditShortcuts from "components/Forms/CreateOrEditShortcuts";
import CreateOrEditContent from "components/Forms/CreateOrEditContent";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { Field, Formik } from "formik";
import { useHistory } from "react-router";
import * as path from "constants/routes";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ReactComponent as Whiteboard } from "assets/Whiteboard.svg";
import { ReactComponent as File } from "assets/Upload_file.svg";
import { ReactComponent as Page } from "assets/Page.svg";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import { FileCopy, FileCopyOutlined } from "@material-ui/icons";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
const theme = createTheme(theme, {
  palette: {
    primary: {
      main: "#2F80ED",
    },
  },
});
const useStyles = makeStyles(() => ({
  wrapper: { width: "100%" },
  selected: {
    backgroundColor: "#E9F2FF",
    color: "#136AE4",
  },
  wrapperButtons: {
    width: "100%",
    display: "flex",
    minWidth: "70px",
    justifyItems: "start",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "8px",
  },
  button: {
    width: "100%",
    justifyContent: "start",
  },
  buttons: {
    width: "100%",
    color: "rgb(0 0 0 / 87%)",
    padding: "0px 8px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "border-box",
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "500",
    lineHeight: "1.75",
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: " uppercase",
    justifyContent: "start",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  field: { width: "258px", marginBottom: "14px" },
  label: {
    color: "#333",
  },
  saveButton: {
    width: "75px",
    textTransform: "uppercase",
    borderColor: "#594BF6",
    backgroundColor: "#594BF6",
    color: "white",
    "&:hover": {
      backgroundColor: "#4C3FE1",
    },
  },
  cancelButton: {
    margin: "0 14px 0 20px",
    width: "75px",
    textTransform: "uppercase",
    backgroundColor: "#6C757D",
    borderColor: "#6C757D",
    color: "white",
    "&:hover": {
      backgroundColor: "#686e75",
    },
  },
  contentBox: {
    display: "flex",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: "15px",
    width: "100%",
  },
  itemBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
  },
  addIcon: {
    width: "24px",
    height: "24px",
    fontWeight: 400,
    marginRight: "5px",
    cursor: "pointer",
    border: "1px solid transparent",
    transition: "background-color 0.3s ease, border-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#e5e5e5",
    },
  },
}));
const SpaceSideBar = ({
  tabs,
  tabValue,
  setTabValue,
  space,
  id,
  setContentData,
  setEditState,
  refetch,
  contentData,
  isOpenSideBar,
}) => {
  const classes = useStyles();
  const [isOpenShortCuts, seIsOpenShortCuts] = useState(false);
  const [isOpenContent, seIsOpenContent] = useState(false);
  const [anchorElShortCuts, setAnchorElShortCuts] = useState(null);
  const [anchorElContent, setAnchorElContent] = useState(null);
  const [anchorElShortCutsP, setAnchorElShortCutsP] = useState(null);
  const [editValues, setEditValuesShortCuts] = useState(null);
  const apolloClient = useApolloClient();
  const [itemIndex, setItemIndex] = useState(null);
  const [focusItem, setFocusItem] = useState(null);
  const [rename, setRename] = useState(false);
  const [anchorElEContent, setAnchorElEContent] = useState(null);
  const history = useHistory();

  const handledDragAndDropFiles = async (
    prevState,
    name,
    setFieldValue,
    newFiles,
  ) => {
    const fileToBase64 = await getBase64(newFiles[0]);
    setFieldValue("file", { fileToBase64, name: newFiles[0].name });
  };

  const handleClickContent = (event) => {
    setAnchorElContent(event.currentTarget);
  };
  const handleCloseContent = () => {
    setAnchorElContent(null);
  };
  const handleClickEContent = (event, item) => {
    setFocusItem(item);
    setAnchorElEContent(event.currentTarget);
  };
  const handleCloseEContent = () => {
    if (!rename) {
      setFocusItem(null);
    }
    setAnchorElEContent(null);
  };
  const handleCloseShortCuts = () => {
    setAnchorElShortCuts(null);

    setEditValuesShortCuts(null);
  };
  const handleCloseShortCutsP = () => {
    setAnchorElShortCutsP(null);
  };
  const handleClickShortCutsP = (event) => {
    setAnchorElShortCutsP(event.currentTarget);
  };
  const handleClickShortCuts = (event) => {
    setAnchorElShortCuts(event.currentTarget);
  };
  const handleCreateShortCuts = async (values) => {
    const response = await apolloClient.mutate({
      mutation: createShortCutsSpace,
      variables: {
        ...values,
        spaceId: id,
      },
    });
    refetch();
  };
  const handleFileChange = async (event) => {
    const fileToBase64 = await getBase64(event.target.files[0]);
    const values = {
      fileToBase64: fileToBase64,
      name: event.target.files[0].name,
    };
    handleCreateFile(values);
  };
  const handleCreateFile = async (values) => {
    const response = await apolloClient.mutate({
      mutation: createContentFileSpace,
      variables: {
        fileToBase64: values?.fileToBase64,
        name: values?.name,
        spaceId: id,
      },
    });
    handleCloseContent();
    refetch();
    setTabValue(4);
    setContentData(response?.data?.createContentFileSpace?.newFile);
  };
  const handleCopyFile = async () => {
    const response = await apolloClient.mutate({
      mutation: copyContentSpace,
      variables: {
        id: focusItem?.id,
        type: focusItem?.__typename,
      },
    });
    setFocusItem(null);
    setAnchorElEContent(null);
    refetch();
  };
  const handleFileSelection = () => {
    document.getElementById("file-upload").click();
  };
  const handleDeleteShortCut = async (item) => {
    setAnchorElShortCutsP(null);
    const response = await apolloClient.mutate({
      mutation: deleteShortcut,
      variables: {
        shortcutId: item?.id,
      },
    });
    refetch();
  };
  const handleUpdateShortCuts = async (values, id) => {
    const response = await apolloClient.mutate({
      mutation: updateShortcut,
      variables: {
        ...values,
        shortcutId: id,
      },
    });
    setEditValuesShortCuts(null);
    refetch();
  };
  const handleUpdateContentFile = async (values) => {
    setItemIndex(null);
    handleCloseEContent();
    if (rename) {
      setRename(false);
    }
    if (focusItem?.__typename === "WhiteboardType") {
      const response = await apolloClient.mutate({
        mutation: updateWhiteBoard,
        variables: {
          whiteboardId: focusItem?.id,
          name: values?.filename,
        },
      });
    } else {
      const response = await apolloClient.mutate({
        mutation: updateContentFileSpace,
        variables: {
          fileId: focusItem.id,
          filename: values?.filename,
        },
      });
    }

    setFocusItem(null);
    setAnchorElEContent(null);
    refetch().then((res) => {
      let item;
      const fileId = contentData?.id;
      if (contentData.__typename === "WhiteboardType") {
        item = res?.data?.spaceInfo?.whiteboards?.find(
          (item) => item.id === fileId,
        );
      } else {
        item = res?.data?.spaceInfo?.files?.find((item) => item.id === fileId);
      }
      setContentData(item);
    });
  };

  const handleChangeStarred = async (item) => {
    if (focusItem?.__typename === "WhiteboardType") {
      const response = await apolloClient.mutate({
        mutation: updateWhiteBoard,
        variables: {
          whiteboardId: item?.id,
          isStarred: !item?.isStarred,
        },
      });
    } else {
      const response = await apolloClient.mutate({
        mutation: updateContentFileSpace,
        variables: {
          fileId: item?.id,
          isStarred: !item?.isStarred,
        },
      });
    }
    setFocusItem(null);
    setAnchorElEContent(null);
    refetch().then((res) => {
      let item;
      const fileId = contentData?.id;
      if (contentData.__typename === "WhiteboardType") {
        item = res?.data?.spaceInfo?.whiteboards?.find(
          (item) => item.id === fileId,
        );
      } else {
        item = res?.data?.spaceInfo?.files?.find((item) => item.id === fileId);
      }
      setContentData(item);
    });
  };
  const handleDeleteFile = async () => {
    if (focusItem?.__typename === "WhiteboardType") {
      const response = await apolloClient.mutate({
        mutation: deleteWhiteboard,
        variables: {
          whiteboardId: focusItem?.id,
        },
      });
    } else {
      const response = await apolloClient.mutate({
        mutation: deleteContentFile,
        variables: {
          fileId: focusItem?.id,
        },
      });
    }
    setFocusItem(null);
    setAnchorElEContent(null);
    refetch();
    setTabValue(0);
  };

  const handleCreateWhiteBoard = async () => {
    const response = await apolloClient.mutate({
      mutation: createWhiteBoard,
      variables: {
        spaceId: id,
      },
    });

    handleCloseContent();

    refetch();
    setTabValue(4);
    setContentData(response?.data?.createWhiteboard?.whiteboard);
  };
  return (
    <Box className={classes.wrapper}>
      <ThemeProvider theme={theme}>
        <Collapse in={isOpenSideBar} timeout="auto">
          <Box className={classes.wrapperButtons}>
            <Button
              key={tabs[0]?.id}
              className={
                tabValue === tabs[0]?.id
                  ? `  ${classes.selected} ${classes.button}`
                  : classes.button
              }
              style={{
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "19.2px",
                textAlign: "center",
                height: "40px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  width: "100%",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontWeight: "400",
                  height: "40px",
                }}
              >
                <ArrowBackIosIcon
                  style={{
                    width: "24px",
                    height: "24x",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(path.DOCUMENTS);
                  }}
                />
                <Box
                  onClick={() => {
                    setTabValue(tabs[0]?.id);
                  }}
                >
                  <img
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "5px",
                    }}
                    src={`${BACKEND_URL}/media/${tabs[0].space?.logo}`}
                    alt={""}
                  />
                  {tabs[0]?.space?.name}
                </Box>
              </Box>
            </Button>
            <Button
              key={tabs[1]?.id}
              onClick={() => {
                setTabValue(tabs[1]?.id);
              }}
              className={
                tabValue === tabs[1]?.id
                  ? `  ${classes.selected} ${classes.button}`
                  : classes.button
              }
              style={{
                alignItems: "center",
                display: "flex",
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "19.2px",
                textAlign: "center",
                height: "40px",
              }}
            >
              <AppsIcon
                style={{ width: "20px", height: "20px", marginRight: "5px" }}
              />{" "}
              {tabs[1]?.name}
            </Button>
            <Button
              key={tabs[2]?.id}
              onClick={() => {
                setTabValue(tabs[2]?.id);
              }}
              className={
                tabValue === tabs[2]?.id
                  ? `  ${classes.selected} ${classes.button}`
                  : classes.button
              }
              style={{
                alignItems: "center",
                display: "flex",
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "19.2px",
                textAlign: "center",
                height: "40px",
              }}
            >
              <SettingsOutlinedIcon
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
              />{" "}
              {tabs[2]?.name}
            </Button>
            <Box style={{ width: "100%" }}>
              <Box
                className={classes.buttons}
                style={{
                  display: "inline-flex",
                  height: "43px",
                  alignItems: "center",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "19.2px",
                  textAlign: "center",
                }}
              >
                <Box
                  style={{
                    display: "inline-flex",
                    cursor: "pointer",
                    fontWeight: 400,
                    alignItems: "center",
                    width: "calc(100% - 24px)",
                  }}
                  onClick={() => {
                    seIsOpenShortCuts((prevState) => !prevState);
                  }}
                >
                  <Box
                    style={{
                      width: "20px",
                      height: "20px",
                      border: "1px solid transparent",
                      borderColor: "#d0d0d0",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                      justifyContent: "center",
                    }}
                  >
                    {isOpenShortCuts ? (
                      <ArrowForwardIosIcon
                        style={{
                          transform: "rotate(90deg)",
                          width: "10px",
                          height: "10px",
                        }}
                      />
                    ) : (
                      <ArrowForwardIosIcon
                        style={{
                          borderRadius: "5px",
                          width: "10px",
                          height: "10px",
                        }}
                      />
                    )}
                  </Box>
                  <Box> {tabs[3]?.name}</Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontFamily: "Lato",
                    fontWeight: 400,
                    width: "24px",
                  }}
                >
                  <Tooltip title={"Create shortcut"} placement="top" arrow>
                    <AddIcon
                      className={classes.addIcon}
                      onClick={(event) => {
                        handleClickShortCuts(event);
                      }}
                    />
                  </Tooltip>
                  <Popover
                    open={Boolean(anchorElShortCuts)}
                    anchorEl={anchorElShortCuts}
                    onClose={handleCloseShortCuts}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography
                      sx={{ p: 2 }}
                      style={{ display: "flex", flexDirection: "col" }}
                    >
                      {" "}
                      <CreateOrEditShortcuts
                        handleCreateShortCuts={handleCreateShortCuts}
                        handleCloseShortCuts={handleCloseShortCuts}
                        values={editValues}
                        handleUpdateShortCuts={handleUpdateShortCuts}
                      />
                    </Typography>
                  </Popover>
                </Box>
              </Box>
              <Collapse in={isOpenShortCuts} timeout="auto">
                <Box
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "clip",
                    marginLeft: "15px",
                    marginTop: "10px",
                  }}
                >
                  {space?.shortcuts?.length > 0
                    ? space?.shortcuts.map((item) => (
                        <Box
                          className={classes.buttons}
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            fontFamily: "Lato",
                            fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: "19.2px",
                            textAlign: "center",
                          }}
                        >
                          <a
                            href={item?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            •
                            <LinkIcon
                              style={{ marginLeft: "10px", marginRight: "5px" }}
                            />
                            {item?.name}
                          </a>

                          <MoreHorizIcon
                            onClick={(event) => {
                              setEditValuesShortCuts(item);
                              handleClickShortCutsP(event);
                            }}
                            style={{ marginRight: "12px", cursor: "pointer" }}
                          />
                          <Popover
                            open={Boolean(anchorElShortCutsP)}
                            anchorEl={anchorElShortCutsP}
                            onClose={handleCloseShortCutsP}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography
                              sx={{ p: 2 }}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  handleCloseShortCutsP();
                                  setAnchorElShortCuts(anchorElShortCutsP);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => handleDeleteShortCut(editValues)}
                              >
                                Delete
                              </Button>
                            </Typography>
                          </Popover>
                        </Box>
                      ))
                    : null}
                </Box>
              </Collapse>
            </Box>
            <Box
              style={{
                width: "100%",
              }}
            >
              <Box
                className={classes.buttons}
                style={{
                  display: "inline-flex",
                  height: "43px",
                  width: "100%",
                  alignItems: "center",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "19.2px",
                  textAlign: "center",
                }}
              >
                <Box
                  onClick={() => {
                    seIsOpenContent((prevState) => !prevState);
                  }}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    cursor: "pointer",
                    fontFamily: "Lato",
                    fontWeight: 400,
                    width: "calc(100% - 24px)",
                  }}
                >
                  <Box
                    style={{
                      width: "20px",
                      height: "20px",
                      border: "1px solid transparent",
                      borderColor: "#d0d0d0",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                      justifyContent: "center",
                    }}
                  >
                    {isOpenContent ? (
                      <ArrowForwardIosIcon
                        style={{
                          transform: "rotate(90deg)",
                          width: "10px",
                          height: "10px",
                        }}
                      />
                    ) : (
                      <ArrowForwardIosIcon
                        style={{
                          borderRadius: "5px",
                          width: "10px",
                          height: "10px",
                        }}
                      />
                    )}
                  </Box>
                  <Box>{tabs[4]?.name}</Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "24px",
                  }}
                >
                  <Tooltip title={"Create file"} placement="top" arrow>
                    <AddIcon
                      className={classes.addIcon}
                      onClick={(event) => {
                        handleClickContent(event);
                      }}
                    />
                  </Tooltip>
                  <Popover
                    open={Boolean(anchorElContent)}
                    anchorEl={anchorElContent}
                    onClose={handleCloseContent}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography
                      sx={{ p: 2 }}
                      style={{
                        padding: "12px",
                        gap: "10px",
                        width: "200px",
                      }}
                    >
                      <Box
                        style={{
                          width: "100%",
                        }}
                      >
                        <Button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            textTransform: "none",
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Lato",
                            justifyContent: "flex-start",
                          }}
                          onClick={handleCreateFile}
                        >
                          {" "}
                          <Page
                            style={{
                              width: "18px",
                              height: "18px",
                              color: "#37AA59",
                              marginRight: "10px",
                            }}
                          />
                          Page
                        </Button>

                        <Button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            textTransform: "none",
                            fontWeight: 400,
                            width: "100%",
                            fontSize: "16px",
                            fontFamily: "Lato",
                            justifyContent: "flex-start",
                          }}
                          onClick={() => {
                            handleCreateWhiteBoard();
                          }}
                        >
                          {" "}
                          <Whiteboard
                            style={{
                              width: "18px",
                              height: "18px",
                              color: "#2D3748",
                              marginRight: "10px",
                            }}
                          />{" "}
                          Whiteboard
                        </Button>

                        <Button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            textTransform: "none",
                            fontWeight: 400,
                            fontSize: "16px",
                            width: "100%",
                            fontFamily: "Lato",
                            justifyContent: "flex-start",
                          }}
                          onClick={handleFileSelection}
                        >
                          {" "}
                          <File
                            style={{
                              width: "18px",
                              height: "18px",
                              color: "#2F80ED",
                              marginRight: "10px",
                            }}
                          />{" "}
                          Upload file
                        </Button>
                        <input
                          accept="*"
                          className={classes.input}
                          id="file-upload"
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </Box>
                    </Typography>
                  </Popover>
                </Box>
              </Box>
              <Collapse in={isOpenContent} timeout="auto">
                <Box
                  style={{
                    marginTop: "10px",
                  }}
                >
                  {space?.files?.length > 0
                    ? space?.files.map((item, index) => (
                        <>
                          <Box
                            key={index}
                            onMouseEnter={(event) => {
                              if (!rename) {
                                setItemIndex(index);
                              }
                            }}
                            className={classes.itemBox}
                            style={{
                              height: "30px",
                              fontFamily: "Lato",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "19.2px",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              className={classes.contentBox}
                              onClick={() => {
                                setEditState(false);
                                setTabValue(4);
                                setContentData(item);
                              }}
                            >
                              •{" "}
                              <Box
                                style={{
                                  marginLeft: "5px",
                                }}
                              >
                                {rename &&
                                index === itemIndex &&
                                focusItem !== null ? (
                                  <Formik
                                    initialValues={{
                                      filename:
                                        focusItem?.filename ||
                                        focusItem?.name ||
                                        "",
                                    }}
                                    onSubmit={(values) => {
                                      handleUpdateContentFile(values);
                                    }}
                                    enableReinitialize={true}
                                  >
                                    {({
                                      values,
                                      setFieldValue,
                                      submitForm,
                                    }) => (
                                      <Field
                                        name="filename"
                                        id="filename"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        style={{
                                          width: "100%",
                                          heigth: "20px",
                                        }}
                                        value={values.filename}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "filename",
                                            e.target.value,
                                          )
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            submitForm();
                                          }
                                        }}
                                        component={TextField}
                                      />
                                    )}
                                  </Formik>
                                ) : (
                                  item?.filename || item?.name
                                )}
                              </Box>
                            </Box>
                            {index === itemIndex && rename === false ? (
                              <MoreHorizIcon
                                onClick={(event) => {
                                  handleClickEContent(event, item);
                                }}
                                className={classes.addIcon}
                              />
                            ) : null}
                          </Box>
                        </>
                      ))
                    : null}
                  <Popover
                    open={Boolean(anchorElEContent)}
                    anchorEl={anchorElEContent}
                    onClose={handleCloseEContent}
                  >
                    <Typography
                      sx={{ p: 2 }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "6px",
                        gap: "6px",
                        width: "200px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleClickEContent(anchorElEContent, focusItem);
                          setRename(true);
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          textTransform: "none",
                          fontWeight: 400,
                          width: "100%",
                          fontSize: "16px",
                          fontFamily: "Lato",
                          justifyContent: "flex-start",
                        }}
                      >
                        <EditOutlinedIcon
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "10px",
                          }}
                        />{" "}
                        Rename
                      </Button>
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          textTransform: "none",
                          fontWeight: 400,
                          width: "100%",
                          fontSize: "16px",
                          fontFamily: "Lato",
                          justifyContent: "flex-start",
                        }}
                        onClick={() => {
                          handleChangeStarred(focusItem);
                        }}
                      >
                        {" "}
                        {focusItem?.isStarred ? (
                          <StarIcon
                            style={{
                              width: "18px",
                              height: "18px",
                              color: "rgb(229, 105, 16)",
                              marginRight: "10px",
                            }}
                          />
                        ) : (
                          <StarOutlineIcon
                            style={{
                              width: "18px",
                              height: "18px",
                              marginRight: "10px",
                            }}
                          />
                        )}
                        Starred
                      </Button>
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          textTransform: "none",
                          fontWeight: 400,
                          width: "100%",
                          fontSize: "16px",
                          fontFamily: "Lato",
                          justifyContent: "flex-start",
                        }}
                        onClick={() => {
                          const values = {
                            name: focusItem?.filename,
                            link: `${BACKEND_URL}/media/${focusItem?.file}`,
                          };
                          handleCreateShortCuts(values);

                          setFocusItem(null);
                          setAnchorElEContent(null);
                        }}
                      >
                        <LinkIcon
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "10px",
                          }}
                        />{" "}
                        Create shortcut
                      </Button>
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          textTransform: "none",
                          fontWeight: 400,
                          width: "100%",
                          fontSize: "16px",
                          fontFamily: "Lato",
                          justifyContent: "flex-start",
                        }}
                        onClick={handleCopyFile}
                      >
                        <FileCopyOutlined
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "10px",
                          }}
                        />{" "}
                        Copy
                      </Button>
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          textTransform: "none",
                          fontWeight: 400,
                          width: "100%",
                          fontSize: "16px",
                          fontFamily: "Lato",
                          justifyContent: "flex-start",
                        }}
                        onClick={handleDeleteFile}
                      >
                        <DeleteForeverIcon
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "10px",
                          }}
                        />{" "}
                        Delete
                      </Button>
                    </Typography>
                  </Popover>
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Collapse>
      </ThemeProvider>
    </Box>
  );
};

export default SpaceSideBar;
