import React, { useEffect, useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { withRouter } from "react-router";
import * as path from "../../../constants/routes";
import { registerByInvitation } from "../../../api/mutations/index";
import { Formik, Form, Field } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import moment from "moment";
import { InvetationSignUpValidation } from "./validation";
import "./stylesInvitationSignUpForm.css";
import { Button } from "reactstrap";

const InvitationSignUpForm = (props) => {
  const [invitationToken, setInvitationToken] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState("");
  const { registerByInvitation } = props;

  useEffect(() => {
    let paramsString = props.location.search;
    let searchParams = new URLSearchParams(paramsString);
    let invitToken = searchParams.get("token");
    let company = searchParams.get("company_name");
    setCompanyName(company);
    setInvitationToken(invitToken);
  }, [props]);

  const submit = (values) => {
    const { firstName, lastName, password, password2 } = values;
    const dateJoined = moment().utc().format("YYYY-MM-DD");
    registerByInvitation({
      variables: {
        firstName: firstName,
        lastName: lastName,
        password1: password,
        password2: password2,
        dateJoined: dateJoined,
        token: invitationToken,
      },
    })
      .then((response) => {
        console.log("response", response);
        if (response.data.registerByInvitation.success) {
          props.history.push(path.SIGN_IN);
        } else {
          let error = "";
          response.data.registerByInvitation.errors.forEach((er) => {
            error = error + " " + er;
          });
          setError(`Error! ${error}`);
        }
      })
      .catch((error) => {
        console.log("error-------", error);
        setError(`${error}`);
      });
  };
  return (
    <div className="invitationSignUpFormWrap">
      <div className="invitationSignUp">
        <h4>
          Join to <b>{companyName}</b> company
        </h4>
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: "",
            lastName: "",
            password: "",
            password2: "",
          }}
          validationSchema={InvetationSignUpValidation}
          onSubmit={(values) => submit(values)}
        >
          {() => (
            <Form className="invitationSignUpForm">
              <Field
                id="firstName"
                name="firstName"
                type="text"
                component={ReactstrapInput}
                label="First name"
              />
              <Field
                id="lastName"
                name="lastName"
                type="text"
                component={ReactstrapInput}
                label="Last name"
              />
              <Field
                id="password"
                name="password"
                type="password"
                component={ReactstrapInput}
                label="Password"
              />
              <Field
                id="password2"
                name="password2"
                type="password"
                component={ReactstrapInput}
                label="Confirm password"
              />
              <Button color="primary" size="lg" block type="submit">
                Continue
              </Button>
              <div style={{ color: "red" }}>{error ? error : null}</div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default compose(
  graphql(registerByInvitation, { name: "registerByInvitation" })
)(withRouter(InvitationSignUpForm));
