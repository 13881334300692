import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";

import { LoginForm } from "components/Forms/LoginForm";

import FooterWithoutInstall from "components/Footer/FooterWithoutInstall";
import HeaderWithoutInstall from "components/NavBar/HeaderWithoutInstall";

import { LOGIN_MUTATION } from "api/mutations";
import { useQueryParams, getInternalToken, saveData } from "utils";
import * as path from "constants/routes";

const useStyles = makeStyles(() => ({
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    height: "100%",
  },
  mainContent: {
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 116px)",
  },
  footer: {
    flex: "0 0 auto",
  },
}));

const LoginPage = (props) => {
  const classes = useStyles();
  const destinationPath = useQueryParams().get("destination");
  const [login, { loading }] = useMutation(LOGIN_MUTATION);

  const token = getInternalToken();

  // Immediately redirect user to the dashboard
  useEffect(() => {
    if (token) {
      props.history.push(path.DAILY_WORK);
    }
  }, [token, props.history]);

  const [state] = useState({ error: "" });
  const [rememberMe, setRememberMe] = useState(true);

  const handleSubmit = (values, { setErrors }) => {
    login({ variables: { ...values } }).then((response) => {
      if (!response.data.login.error) {
        const token = response.data.login.token;
        saveData("token", token, rememberMe);
        props.history.push(destinationPath || path.DAILY_WORK);
      } else {
        let errors = {};
        response.data.login.error.validationErrors.forEach((error) => {
          if (error["field"] === "__all__") {
            errors["username"] = error["messages"].join(" ");
            errors["password"] = error["messages"].join(" ");
          } else {
            errors[error] = error["messages"];
          }
        });

        setErrors(errors);
      }
    });
  };

  const changeRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <div className={classes.loginWrapper}>
      <HeaderWithoutInstall login />
      <div className={classes.mainContent}>
        <LoginForm
          login={handleSubmit}
          error={state.error}
          rememberMe={rememberMe}
          changeRememberMe={changeRememberMe}
          isProcessing={loading}
        />
      </div>
      <div className={classes.footer}>
        <FooterWithoutInstall />
      </div>
    </div>
  );
};

export default LoginPage;
