import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import { formatName } from "utils";
import moment from "moment/moment";
import SearchBox from "components/SearchBox";
import Button from "@material-ui/core/Button";
import i18n from "i18n";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import ReorderOutlinedIcon from "@material-ui/icons/ReorderOutlined";
import ViewAgendaOutlinedIcon from "@material-ui/icons/ViewAgendaOutlined";
import StarIcon from "@material-ui/icons/Star";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles(() => ({
  wrapperCard: {
    cursor: "pointer",
    width: "100%",
    marginTop: "15px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, min(280px))",
    gap: "15px",
  },
  contentCard: {
    borderRadius: "15px",
    border: "1px #D9D9D9 solid",
    display: "flex",
    flexDirection: "column",
    width: "280px",
    marginTop: "10px",
    padding: "15px",
    boxShadow: "5px 5px 2px 0px rgba(0, 0, 0, 0.15)",
  },
  titleСard: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: "400",
    lineHeight: "22.2px",
    height: "40px",
  },
  createdСard: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "22.2px",
    height: "22px",
    color: "#828282",
  },
  button: {
    display: "flex",
    textTransform: "inherit",
    borderRadius: "25px",
    marginRight: "5px",
    backgroundColor: "#F1F2F4",
    padding: "5px 12px",
  },
  selected: {
    backgroundColor: "#E9F2FF",
    color: "#136AE4",
    border: "1px #136AE4 solid",
  },
  selectedTab: {
    backgroundColor: "#E9F2FF",
    color: "#136AE4",
  },
  contentList: {
    width: "100%",
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "auto 270px 30px",
    border: "1px #D9D9D9 solid",
    marginBottom: "10px",
    padding: "15px",
    alignItems: "center",
    borderRadius: "10px",
  },
  createdList: {
    display: "grid",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "19.2px",
    textAlign: "center",
    height: "40px",
  },
  wrapperContent: {
    cursor: "pointer",
    marginTop: "15px",
  },
  contentLine: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "auto 225px 225px 30px",
    border: "1px #D9D9D9 solid",
    padding: "5px",
    alignItems: "center",
    paddingRight: "10px",
    paddingLeft: "10px",
    borderRadius: "5px",
    marginBottom: "5px",
  },
}));

const AllContent = ({ space, user, handleOpenFile }) => {
  const classes = useStyles();
  const [selectedButtonId, setSelectedButtonId] = useState(1);
  const [selectedTab, setSelectedTab] = useState(1);
  const [filteredFiles, setFilteredFiles] = useState(space?.files);

  const [search, setSearch] = useState("");
  const renderContent = () => {
    switch (selectedTab) {
      case 1:
        return (
          <Box className={classes.wrapperCard}>
            {filteredFiles?.map((item) => (
              <Box
                className={classes.contentCard}
                onClick={() => handleOpenFile(item)}
              >
                <DescriptionIcon color={"primary"} />
                <Box className={classes.titleСard}>
                  <Box
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      height: "22px",
                    }}
                  >
                    {" "}
                    {item?.filename || item?.name}
                  </Box>{" "}
                </Box>
                <Box className={classes.createdСard}>
                  Owner:{formatName(item?.createdBy)}
                </Box>
                <Box
                  className={classes.createdCard}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#828282",
                    lineHeight: "30.2px",
                  }}
                >
                  <AccessTimeIcon /> Updated at :
                  {moment(item?.updatedAt).format("LL")}
                </Box>
              </Box>
            ))}{" "}
          </Box>
        );
      case 2:
        return filteredFiles?.map((item) => (
          <Box
            className={classes.contentList}
            onClick={() => handleOpenFile(item)}
          >
            <Box display={"flex"}>
              <DescriptionIcon
                color={"primary"}
                style={{ height: "60px", width: "60px" }}
              />
              <Box className={classes.createdList}>
                <Box
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {" "}
                  {item?.filename}
                </Box>{" "}
                <Box style={{ color: "#333C43" }}>
                  Owner:{formatName(item?.createdBy)}
                </Box>
              </Box>
            </Box>
            <Box>
              <Box style={{ color: "#333C43" }} display={"flex"}>
                <AccessTimeIcon style={{ height: "30px", width: "30px" }} />
                Updated at : {moment(item?.updatedAt).format("LL")}
              </Box>
            </Box>
            <Box>
              {" "}
              {item?.isStarred ? (
                <StarIcon style={{ color: "#E56910" }} />
              ) : null}
            </Box>
          </Box>
        ));
      case 3:
        return filteredFiles?.map((item) => (
          <Box
            className={classes.contentLine}
            onClick={() => handleOpenFile(item)}
          >
            <Box display={"flex"}>
              <DescriptionIcon
                color={"primary"}
                style={{ height: "30px", width: "30px" }}
              />
              <Box className={classes.createdList}>
                {" "}
                <Box
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {" "}
                  {item?.filename || item?.name}
                </Box>
              </Box>
            </Box>
            <Box style={{ color: "#333C43", fontSize: "12pt" }}>
              Owner:{formatName(item?.createdBy)}
            </Box>
            <Box>
              <Box
                style={{
                  color: "#333C43",
                  fontSize: "12pt",
                  alignItems: "center",
                }}
                display={"flex"}
              >
                <AccessTimeIcon style={{ height: "15px", width: "15px" }} />
                Updated at : {moment(item?.updatedAt).format("LL")}
              </Box>
            </Box>
            <Box>
              {" "}
              {item?.isStarred ? (
                <StarIcon
                  style={{ height: "15px", width: "15px", color: "#E56910" }}
                />
              ) : null}
            </Box>
          </Box>
        ));
      default:
        return filteredFiles?.map((item) => (
          <Box
            className={classes.contentCard}
            onClick={() => handleOpenFile(item)}
          >
            <Box className={classes.title}>
              <DescriptionIcon color={"primary"} />{" "}
              <Box
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {" "}
                {item?.filename || item?.name}
              </Box>{" "}
            </Box>
            <Box className={classes.created}>
              Owner:{formatName(item?.createdBy)} <br />
              Created at : {moment(item?.createdAt).format("LL")}
            </Box>
            <Box className={classes.created}>
              Updated by :{formatName(item?.updatedBy)} <br />
              Updated at : {moment(item?.updatedAt).format("LL")}
            </Box>
          </Box>
        ));
    }
  };
  useEffect(() => {
    if (!space?.files) return [];
    const newfiles = space?.files?.filter(
      (item) =>
        item?.filename?.toLowerCase().includes(search.toLowerCase()) ||
        item?.name?.toLowerCase().includes(search.toLowerCase()),
    );
    setFilteredFiles(newfiles);
  }, [space?.files, search, setFilteredFiles]);
  const handleFilterAll = (event) => {
    setFilteredFiles(space?.files);
  };
  const handleFilterWorkedOn = (event) => {
    const newFilteredFiles = space?.files?.filter(
      (item) => item?.statusDraft === true,
    );
    setFilteredFiles(newFilteredFiles);
  };
  const handleFilterCreatedByMe = (event) => {
    const newFilteredFiles = space?.files?.filter(
      (item) => item?.createdBy?.id === user?.id,
    );
    setFilteredFiles(newFilteredFiles);
  };
  const filterButtons = [
    {
      id: 1,
      title: i18n.t("All"),
      handle: handleFilterAll,
    },
    {
      id: 2,
      title: i18n.t("Worked on"),
      handle: handleFilterWorkedOn,
    },
    {
      id: 3,
      title: i18n.t("Created by me"),
      handle: handleFilterCreatedByMe,
    },
  ];
  useEffect(() => {}, []);
  return (
    <Box>
      <Box>
        <Box>
          <Box
            style={{
              marginTop: "8px",
              fontWeight: "bold",
              fontFamily: "Lato",
              fontSize: "24px",
            }}
          >
            ALL CONTENT
            <Box
              display={"flex"}
              style={{
                height: "40px",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Box display={"flex"}>
                <SearchBox search={search} handleSearch={setSearch} />
                {filterButtons.map((button) => (
                  <Button
                    color="error"
                    className={
                      selectedButtonId === button.id
                        ? `${classes.button}  ${classes.selected}`
                        : classes.button
                    }
                    onClick={(event) => {
                      button.handle(event);
                      setSelectedButtonId(button.id);
                    }}
                    style={{
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "19.2px",
                      textAlign: "center",
                    }}
                  >
                    {button.title}
                  </Button>
                ))}
              </Box>
              <Box>
                <DashboardOutlinedIcon
                  onClick={() => setSelectedTab(1)}
                  style={{ cursor: "pointer" }}
                  className={
                    selectedTab === 1 ? `  ${classes.selectedTab}` : " "
                  }
                />
                <ViewAgendaOutlinedIcon
                  onClick={() => setSelectedTab(2)}
                  className={
                    selectedTab === 2 ? `  ${classes.selectedTab}` : " "
                  }
                  style={{ cursor: "pointer" }}
                />
                <ReorderOutlinedIcon
                  onClick={() => setSelectedTab(3)}
                  className={
                    selectedTab === 3 ? `   ${classes.selectedTab}` : ""
                  }
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.wrapperContent}>{renderContent()}</Box>
    </Box>
  );
};

export default AllContent;
