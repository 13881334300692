import React from "react";
import moment from "moment";
import {
  AccessTimeOutlined,
  AccountCircleOutlined,
  DescriptionOutlined,
  PeopleOutline,
  PhoneOutlined,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Form, Field } from "formik";
import {
  Card,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import DatePicker from "components/DatePicker/DatePicker";
import ButtonSwitch from "components/Buttons/ButtonSwitch";
import SelectAutocomplete from "components/Select/SelectAutocomplete";
import ButtonRoot from "components/Buttons/ButtonRoot";
import { formatName } from "utils";
import { timeOptions } from "constants/index";
import ModalActions from "components/Modals/ModalActions";

const useStyles = makeStyles(() => ({
  form: (props) => ({
    height: "100%",
    borderTop: props?.border ? props?.border : "1px solid #D9D9D9",
  }),
  formWrapper: {
    borderRadius: 0,
    padding: "10px 20px 0px 34px",
    boxShadow: "none",
    position: "relative",
  },
  taskNameField: {
    width: "100%",
    height: "60px",
  },
  selectActivityTypeField: {
    width: "100%",
  },
  timeFields: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
  },
  fieldIcon: {
    position: "absolute",
    left: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timeField: {
    height: "40px",
    margin: "0 10px",
  },
  noteField: {
    marginTop: "10px",
    height: "138px",
  },
  textarea: {
    width: "100%",
    resize: "none",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#828282",
    outline: "none",
    border: "1px solid #E0E0E0",
    borderRadius: "5px",
    padding: "10px",
    whiteSpace: "pre-wrap",
    marginBottom: "-6px",
  },
  footerButtons: {
    display: "flex",
    alignItems: "center",
    float: "right",
    padding: "5px 44px 0px",
  },
  saveButton: {
    width: "135px",
    textTransform: "uppercase",
  },
  cancelButton: {
    margin: "0 14px 0 20px",
    width: "135px",
    textTransform: "uppercase",
    borderColor: "#594BF6",
    color: "#594BF6",
  },
  helperText: {
    margin: 0,
    color: "#f44336",
  },
  managers: {
    height: "60px",
    width: "100%",
  },
}));

const CreateActivityForm = ({
  values,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
  managers,
  resetForm,
  border,
  activity,
  submitForm,
  ModalButtons,
}) => {
  const classes = useStyles({ border });

  const switchChangeHandler = (value) => (e) => {
    setFieldValue("activityType", value);
    setFieldValue("title", switchButtons[value - 1].title);
  };

  const handleNote = ({ target: { value } }) => {
    setFieldValue("note", value);
  };

  const switchButtons = [
    {
      icon: <PhoneOutlined />,
      title: "Call",
      value: 1,
    },
    {
      icon: <PeopleOutline />,
      title: "Meeting",
      value: 2,
    },
    {
      icon: <AccessTimeOutlined />,
      title: "Task",
      value: 3,
    },
  ];
  const filterTime = (time) => {
    const currentDate = moment();
    if (currentDate.isSame(moment(values.startDate), "day")) {
      let currentHours = currentDate.hours();
      let currentMinutes = Math.ceil(currentDate.minutes() / 15) * 15;
      if (currentMinutes === 60) {
        currentMinutes = 0;
        currentHours += 1;
      }

      const currentTime = `${currentHours
        .toString()
        .padStart(2, "0")}:${currentMinutes.toString().padStart(2, "0")}`;
      return timeOptions.filter((option) =>
        moment(option.value, "HH:mm").isSameOrAfter(
          moment(currentTime, "HH:mm"),
        ),
      );
    } else {
      return time;
    }
  };
  const filterTimeOption = (time) => {
    const filteredOptions = filterTime(time);
    if (
      values.startTime.value !== "00:00" ||
      values.startTime.text !== "h.mm PM"
    ) {
      return filteredOptions.filter((option) =>
        moment(option.value, "HH:mm").isAfter(
          moment(values.startTime.value, "HH:mm"),
        ),
      );
    }
    return filteredOptions;
  };

  return (
    <Form className={classes.form}>
      <Card className={classes.formWrapper}>
        <Field
          error={touched.title && errors.title}
          className={classes.taskNameField}
          placeholder="Task"
          variant="outlined"
          size="small"
          component={TextField}
          value={values.title}
          onChange={(e) => setFieldValue("title", e.target.value)}
          onFocus={() => {
            setFieldValue("title", " ");
          }}
          helperText={touched.title && errors.title}
          FormHelperTextProps={{
            classes: {
              root: classes.helperText,
            },
          }}
        />
        <ButtonSwitch
          className={classes.selectActivityTypeField}
          color={"#2F80ED"}
          strokeWidth={0.1}
          switchChangeHandler={switchChangeHandler}
          currentOptionValue={values.activityType}
          options={switchButtons}
        />
        <div className={classes.timeFields}>
          <div className={classes.fieldIcon}>
            <AccessTimeOutlined
              htmlColor="#0000008A"
              style={{ fontSize: "28px" }}
            />
          </div>
          <div className="d-flex" style={{ width: "100%" }}>
            <DatePicker
              styles={{ width: "50%" }}
              allDays={true}
              selectDate={values.startDate}
              setSelectDate={(d) => {
                let newDate = moment(d).format("YYYY-MM-DD");
                setFieldValue("startDate", newDate);
              }}
            />
            <SelectAutocomplete
              style={{ width: "50%" }}
              disableClearable
              className={classes.timeField}
              options={filterTime(timeOptions)}
              placeholder="Select Time"
              setValue={(value) => setFieldValue("startTime", value)}
              value={values.startTime}
              inputStyle={{ fontSize: "0.875rem", height: "40px" }}
              labelStyle={{ fontSize: "0.875rem" }}
            />
          </div>
          <strong>-</strong>
          <div className="d-flex" style={{ width: "100%" }}>
            <SelectAutocomplete
              style={{ width: "50%" }}
              disableClearable
              className={classes.timeField}
              options={filterTimeOption(timeOptions)}
              placeholder="Select Time"
              setValue={(value) => setFieldValue("endTime", value)}
              value={values.endTime}
              inputStyle={{ fontSize: "0.875rem", height: "40px" }}
              fontSize={"0.875rem"}
              labelStyle={{ fontSize: "0.875rem" }}
            />
            <DatePicker
              styles={{ width: "50%" }}
              startDate={new Date(values.startDate)}
              selectDate={values.endDate}
              setSelectDate={(d) => {
                let newDate = moment(d).format("YYYY-MM-DD");
                setFieldValue("endDate", newDate);
              }}
            />
          </div>
        </div>
        <div className={classes.noteField}>
          <div className={classes.fieldIcon}>
            <DescriptionOutlined
              htmlColor="#0000008A"
              style={{ fontSize: "28px" }}
            />
          </div>
          <textarea
            style={{
              border:
                touched.note && errors.note
                  ? "1px solid #f44336"
                  : "1px solid #E0E0E0",
            }}
            className={classes.textarea}
            id="workdone"
            name="workdone"
            rows="4"
            value={values.note}
            onChange={handleNote}
          ></textarea>
          <FormHelperText className={classes.helperText}>
            {touched.note && errors.note}
          </FormHelperText>
        </div>
        <div className="d-flex align-items-start">
          <div className={classes.fieldIcon}>
            <AccountCircleOutlined
              htmlColor="#0000008A"
              style={{ fontSize: "28px", marginTop: "5px" }}
            />
          </div>
          <div className={classes.managers}>
            <SelectAutocomplete
              error={touched.manager && errors.manager}
              placeholder={"Choose menager"}
              value={values.manager}
              setValue={(e) => setFieldValue("manager", e)}
              options={managers}
              style={{ width: "100%" }}
            />
            <FormHelperText className={classes.helperText}>
              {touched.manager && errors.manager}
            </FormHelperText>
          </div>
        </div>
        {activity ? (
          <>
            <div>
              <span>
                Created: {moment(activity.createdAt).format("lll")}{" "}
                {formatName(activity.createdBy)}
              </span>
            </div>
            {activity.updatedAt && activity.updatedBy ? (
              <div>
                <span>
                  Last modified: {moment(activity.updatedAt).format("lll")}{" "}
                  {formatName(activity.updatedBy)}
                </span>
              </div>
            ) : null}
          </>
        ) : null}
      </Card>
      <div
        className={classes.footerButtons}
        style={{ marginTop: activity ? 0 : "10px" }}
      >
        <FormControlLabel
          className="d-flex align-items-center m-0"
          control={
            <Checkbox
              value={values.done}
              onChange={(e, value) => setFieldValue("done", value)}
              style={{ color: "#594BF6" }}
            />
          }
          label="Mark as done"
        />
        {ModalButtons ? (
          <ModalActions
            style={{ borderTop: "none" }}
            onClose={resetForm}
            handleSubmit={submitForm}
          />
        ) : (
          <>
            <ButtonRoot
              className={classes.cancelButton}
              variant={"outlined"}
              text="Cancel"
              onClick={resetForm}
            />
            <ButtonRoot
              type="submit"
              disabled={isSubmitting}
              className={classes.saveButton}
              text="Save"
            />
          </>
        )}
      </div>
    </Form>
  );
};

export default CreateActivityForm;
