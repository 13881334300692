import { Formik } from "formik";
import { Box } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import DragAndDropFile from "../../DragAndDropFile/DragAndDropFile";
import Button from "@material-ui/core/Button";
import React from "react";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  field: { width: "258px", marginBottom: "14px" },
  label: {
    color: "#333",
  },
  saveButton: {
    width: "75px",
    textTransform: "uppercase",
    borderColor: "#594BF6",
    backgroundColor: "#594BF6",
    color: "white",
    "&:hover": {
      backgroundColor: "#4C3FE1",
    },
  },
  cancelButton: {
    margin: "0 14px 0 20px",
    width: "75px",
    textTransform: "uppercase",
    backgroundColor: "#6C757D",
    borderColor: "#6C757D",
    color: "white",
    "&:hover": {
      backgroundColor: "#686e75",
    },
  },
}));
const CreateOrEditContent = (props) => {
  const classes = useStyles();
  return (
    <Formik
      onSubmit={(values) => {
        props?.handleCreateFile(values);
        props?.handleCloseContent();
      }}
      initialValues={
        props?.values || {
          file: null,
        }
      }
    >
      {({ values, setFieldValue, errors, touched, submitForm }) => (
        <Box style={{ width: "100%", padding: "20px 16px" }}>
          <InputLabel id="file" className={classes.label}>
            Create empty file or upload file
          </InputLabel>
          <DragAndDropFile
            name="files"
            setFieldValue={setFieldValue}
            value={values.file}
            handler={props?.handledDragAndDropFiles}
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <InputLabel id="Attached_files" className={classes.label}>
              Attached file
            </InputLabel>
            <InputLabel className={classes.label}>
              {values.file && values.file?.name}
            </InputLabel>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type={"c"}
              className={classes.cancelButton}
              onClick={() => props?.handleCloseContent()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={submitForm}
              className={classes.saveButton}
            >
              Create
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default CreateOrEditContent;
