import React, { lazy, Suspense, useState } from "react";
import { Switch, Route } from "react-router";
import * as path from "./constants/routes";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp/SignUp";
import InvitationSignUp from "./pages/InvitationSignUp/invitationSignUp";
import MainFrame from "./components/Main";
import Loader from "./components/Loader";
import i18n from "./i18n";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword.js";
import ResetPassword from "./pages/ResetPassword/index";
import LeadPage from "pages/Leads/LeadPage";

import "./firebase";
import Space from "./pages/Space";
import useRouteRedirect from "hooks/useRouteRedirect";
import Home from "./pages/Home";
const Timesheet = lazy(() => import("./pages/Dashboard"));
const Employees = lazy(() => import("./pages/Employees/index"));
const CalendarPage = lazy(() => import("./pages/Calendar/Calendar"));
const CalendarOverview = lazy(
  () => import("./pages/CalendarOverview/CalendarOverview"),
);
const ProfilePage = lazy(() => import("./pages/Profile/Profile"));
const Equipments = lazy(() => import("./pages/Equipments/Equipment"));
const PageNotFound = lazy(() => import("./components/PageNotFound"));
const Projects = lazy(() => import("./pages/Projects"));
const DailyWork = lazy(() => import("./pages/DailyWork/dailyWork"));
const WeeklyWork = lazy(() => import("./pages/WeeklyWork/weeklyWork"));
const Reports = lazy(() => import("./pages/Reports/Reports"));
const Teams = lazy(() => import("./pages/Teams/Teams"));
const DetailTeam = lazy(() => import("./pages/Teams/DetailTeam/detailTeam"));
const Texts = lazy(() => import("./pages/Texts/Texts"));
const Payments = lazy(() => import("./pages/Payments/payments"));
const Billing = lazy(() => import("./pages/Billing/Billing"));
const Company = lazy(() => import("./pages/Company/Company"));
const FinanceOverview = lazy(() => import("./pages/Finance/FinanceOverview"));
const Salary = lazy(() => import("./pages/Finance/Salary"));
const FinanceIncomeAndExpenses = lazy(() => import("./pages/Finance/index"));
const Leads = lazy(() => import("./pages/Leads/index"));
const ClientsAndContacts = lazy(
  () => import("./pages/ClientsAndContacts/ClientsAndContacts"),
);
const Notifications = lazy(() => import("./pages/Notifications/Notifications"));
const Polls = lazy(() => import("./pages/Polls/Polls"));
const Diagrams = lazy(() => import("./pages/Diagrams/Diagrams"));
const CreateDiagramPage = lazy(
  () => import("./pages/CreateDiagramPage/CreateDiagramPage"),
);
const InfoDiagram = lazy(() => import("./pages/InfoDiagram/InfoDiagram"));
const EditDiagram = lazy(() => import("./pages/EditDiagram/EditDiagram"));
const Reviews = lazy(() => import("./pages/Reviews/Reviews"));
const Invoices = lazy(() => import("./pages/Invoices"));
const Candidates = lazy(() => import("pages/Candidates"));
const CandidatePage = lazy(
  () => import("pages/Candidates/CandidatePage/index"),
);
const JobPosts = lazy(() => import("pages/JobPosts/index"));
const JobPostPage = lazy(() => import("pages/JobPosts/JobPostPage/index"));
const Prospects = lazy(() => import("pages/Prospects/index"));
const ReportsOverview = lazy(() => import("pages/ReportsOverview"));
const Documents = lazy(() => import("pages/Documets"));
const EmailSync = lazy(() => import("pages/EmailSync/index"));
const Inbox = lazy(() => import("pages/Inbox/index"));
const ApprovedReports = lazy(() => import("pages/Approved"));
const Mailbox = lazy(() => import("pages/Mailbox/index"));
const InvoiceOverview = lazy(() => import("pages/InvoiceOverview/index"));

const App = () => {
  const [language, setLanguage] = useState("en");
  useRouteRedirect();
  i18n.changeLanguage(language);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(language);
  };

  const APP_ROUTES = {
    staticRoutes: [
      {
        id: "1",
        path: path.HOME,
        component: Home,
      },
      { id: "2", path: path.SIGN_IN, component: Login },
      { id: "3", path: path.SIGN_UP, component: SignUp },
      { id: "4", path: path.FORGOT_PASSWORD, component: ForgotPassword },
      {
        id: "5",
        path: path.RESET_PASSWORD,
        component: ResetPassword,
      },
    ],
    dynamicRoutes: [
      {
        id: "1",
        path: path.TIMESHEET,
        render: (props, user) => <Timesheet {...props} {...user} />,
      },
      {
        id: "2",
        path: path.EMPLOYEES,
        render: (props, user) => <Employees {...props} {...user} />,
      },
      {
        id: "3",
        path: path.CALENDAR,
        render: (props, user) => <CalendarPage {...props} {...user} />,
      },
      {
        id: "4",
        path: path.CALENDAR_OVERVIEW,
        render: (props, user) => <CalendarOverview {...props} {...user} />,
      },
      {
        id: "5",
        path: path.PROFILE,
        render: (props, user) => <ProfilePage {...props} {...user} />,
      },
      {
        id: "6",
        path: path.PROJECTS,
        render: (props, user) => <Projects {...props} {...user} />,
      },
      {
        id: "7",
        path: path.REPORTS_DAILY,
        render: (props, user) => <Reports {...props} {...user} />,
      },
      {
        id: "8",
        path: path.TEAMS,
        render: (props, user) => <Teams {...props} {...user} />,
      },
      {
        id: "9",
        path: path.TEXTS,
        render: (props, user) => <Texts {...props} {...user} />,
      },
      {
        id: "10",
        path: path.DAILY_WORK,
        render: (props, user) => <DailyWork {...props} {...user} />,
      },
      {
        id: "11",
        path: path.EQUIPMENTS,
        render: (props, user) => <Equipments {...props} {...user} />,
      },
      {
        id: "12",
        path: path.WEEKLY_WORK,
        render: (props, user) => <WeeklyWork {...props} {...user} />,
      },
      {
        id: "13",
        path: path.TEAM_DETAIL,
        render: (props, user) => <DetailTeam {...props} {...user} />,
      },
      {
        id: "14",
        path: path.PAYMENTS,
        render: (props, user) => <Payments {...props} {...user} />,
      },
      {
        id: "15",
        path: path.Billing,
        render: (props, user) => <Billing {...props} {...user} />,
      },
      {
        id: "16",
        path: path.COMPANY,
        render: (props, user) => <Company {...props} {...user} />,
      },
      {
        id: "17",
        path: path.FINANCE_OVERVIEW,
        render: (props, user) => <FinanceOverview {...props} {...user} />,
      },
      {
        id: "18",
        path: path.FINANCE_INCOME_AND_EXPENSES,
        render: (props, user) => (
          <FinanceIncomeAndExpenses {...props} {...user} />
        ),
      },
      {
        id: "19",
        path: path.LEADS,
        render: (props, user) => <Leads {...props} {...user} />,
      },
      {
        id: "20",
        path: path.CLIENTS_AND_CONTACTS,
        render: (props, user) => <ClientsAndContacts {...props} {...user} />,
      },
      {
        id: "21",
        path: path.NOTIFICATIONS,
        render: (props, user) => <Notifications {...props} {...user} />,
      },
      {
        id: "22",
        path: path.POLLS,
        render: (props, user) => <Polls {...props} {...user} />,
      },
      {
        id: "23",
        path: path.REVIEWS,
        render: (props, user) => <Reviews {...props} {...user} />,
      },
      {
        id: "24",
        path: path.DIAGRAMS,
        render: (props, user) => <Diagrams {...props} {...user} />,
      },
      {
        id: "25",
        path: path.CREATE_DIAGRAM,
        render: (props, user) => <CreateDiagramPage {...props} {...user} />,
      },
      {
        id: "26",
        path: path.DIAGRAM_INFO,
        render: (props, user) => <InfoDiagram {...props} {...user} />,
      },
      {
        id: "27",
        path: path.EDIT_DIAGRAM_ID,
        render: (props, user) => <EditDiagram {...props} {...user} />,
      },
      {
        id: "28",
        path: path.FINANCE_SALARY,
        render: (props, user) => <Salary {...props} {...user} />,
      },
      {
        id: "29",
        path: path.INVOICES,
        render: (props, user) => <Invoices {...props} {...user} />,
      },
      {
        id: "30",
        path: path.CANDIDATES,
        render: (props, user) => <Candidates {...props} {...user} />,
      },
      {
        id: "31",
        path: path.CANDIDATE_INFO,
        render: (props, user) => <CandidatePage {...props} {...user} />,
      },
      {
        id: "32",
        path: path.LEAD_INFO,
        render: (props, user) => <LeadPage {...props} {...user} />,
      },
      {
        id: "33",
        path: path.JOB_POSTS,
        render: (props, user) => <JobPosts {...props} {...user} />,
      },
      {
        id: "34",
        path: path.JOB_POST_INFO,
        render: (props, user) => <JobPostPage {...props} {...user} />,
      },
      {
        id: "35",
        path: path.PROSPECTS,
        render: (props, user) => <Prospects {...props} {...user} />,
      },
      {
        id: "36",
        path: path.REPORTS_OVERVIEW,
        render: (props, user) => <ReportsOverview {...props} {...user} />,
      },
      {
        id: "37",
        path: path.DOCUMENTS,
        render: (props, user) => <Documents {...props} {...user} />,
      },
      {
        id: "38",
        path: path.EMAIL_SYNC,
        render: (props, user) => <EmailSync {...props} {...user} />,
      },
      {
        id: "39",
        path: path.INBOX,
        render: (props, user) => <Inbox {...props} {...user} />,
      },
      {
        id: "40",
        path: path.SPACE,
        render: (props, user) => <Space {...props} {...user} />,
      },
      {
        id: "41",
        path: path.APPROVED_REPORTS,
        render: (props, user) => <ApprovedReports {...props} {...user} />,
      },
      {
        id: "42",
        path: path.MAILBOX,
        render: (props, user) => <Mailbox {...props} {...user} />,
      },
      {
        id: "43",
        path: path.INVOICE_OVERVIEW,
        render: (props, user) => <InvoiceOverview {...props} {...user} />,
      },
    ],
  };

  return (
    <Switch>
      <Route
        key={"invitation_signup"}
        path={path.INVITATION_SIGN_UP}
        component={InvitationSignUp}
      />
      {APP_ROUTES.staticRoutes.map((route) => (
        <Route
          key={route.id}
          exact
          path={route.path}
          component={route.component}
        />
      ))}
      <MainFrame
        handleLanguageChange={handleLanguageChange}
        language={language}
      >
        {(user) => (
          <Suspense fallback={<Loader />}>
            <Switch>
              {APP_ROUTES.dynamicRoutes.map((route) => (
                <Route
                  key={route.id}
                  exact
                  path={route.path}
                  render={(props) => route.render(props, user)}
                />
              ))}
              <Route render={(props) => <PageNotFound {...props} />} />
            </Switch>
          </Suspense>
        )}
      </MainFrame>
    </Switch>
  );
};

export default App;
