import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";

const RadioRoot = withStyles({
  root: {
    "&$checked": {
      color: "#594BF6",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default RadioRoot;
